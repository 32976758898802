<template>
  <div id="app">
    <navbar/>
    <AnimateLogo v-if="animLogo" @done="animLogo=false"/>
    <router-view v-else style="margin-top: 135px;"/>
    <scrollIndicator v-if="isScrolled"/>
    <FooterBar/>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import ScrollIndicator from "@/components/ScrollIndicator";
import FooterBar from "@/components/FooterBar";
import AnimateLogo from "@/components/AnimateLogo";

export default {
  components: {AnimateLogo, FooterBar, Navbar, ScrollIndicator},
  data() {
    return {
      isScrolled: false,
      animLogo: true,
    }
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll)
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll)
  },
  methods: {
    onScroll() {
      this.isScrolled = window.scrollY > 25
    }
  },
}
</script>

<style>
body {
  font-family: 'Dosis', sans-serif !important;
  font-size: 15px !important;
  font-weight: 300 !important;
}

b, strong {
  font-weight: bold !important;
}

#app {
  scroll-behavior: smooth;
}

section {
  background: white;
  padding: 0 0 45px;
  position: relative;
}

section.transparent {
  background: transparent;
}

.banner {
  position: absolute;
  bottom: 130px;
  left: 0;
  right: 0;
  padding: 20px;
  background: rgba(62, 61, 61, 0.6);
  color: white;
  text-align: center;
}

.banner a {
  color: white;
}

.banner a:hover {
  color: #edb059;
}

a, a:hover {
  color: #edb059;
}

.myFont {
  font-family: 'Amatic SC', cursive;
  font-size: 2rem;
}

.vh-center {
  position: absolute !important;
  bottom: 50%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 50%;
  transform: translate(50%, 50%);
}

.card {
  border: none !important;
}

.card-text {
  text-align: justify;
}

.card-body {
  height: 100%;
}
.dropdown.b-dropdown>.btn{color: #5b686d !important;}
</style>