<template>
  <div class="scroller">
    <b-btn @click="scrollToTop"
           v-motion-slide-right :delay="250">
      <b-icon-chevron-bar-up/>
    </b-btn>
  </div>
</template>

<script>
export default {
  name: "ScrollIndicator",
  methods: {
    scrollToTop() {
      window.scrollTo({top: 0, behavior: 'smooth'});

    }
  }
}
</script>

<style scoped>
.scroller{
  position: fixed;
  bottom: 6rem;
  right: 0;
  padding: .5rem;
}
</style>