import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import About from '@/views/About.vue'
import Foryou from '@/views/Foryou'
import Datenschutz from "@/views/Datenschutz";
import PageNotFound from "@/views/PageNotFound";
import i18n from "@/i18n.js";
import LanguageView from "@/views/LanguageView";


Vue.use(VueRouter)

const pageTitle = 'Susan Bewegt';

const routes = [
    {
        path: "/:local",
        component: LanguageView,
        beforeEnter(to, from, next) {
            const lang = to.params.local;
            if (!["en", "de"].includes(lang)) return next("de");
            if (i18n.locale !== lang) {
                i18n.locale = lang;
            }
            return next();
        },
        children: [
            {
                path: '',
                name: i18n.t('navbar.home'),
                component: Home,
                navbar: true,
                hidden: false,
                meta: {
                    title: pageTitle + ' | Fitness & Health Coach'
                },
            },
            {
                path: 'ueber-mich',
                name:  i18n.t('navbar.about me'),
                component: About,
                navbar: true,
                hidden: false,
                meta: {
                    title: i18n.t('navbar.about me') + ' | ' + pageTitle
                }
            },
            {
                path: 'fuer-euch',
                name: 'Für Euch',
                component: Foryou,
                navbar: true,
                hidden: false,
                meta: {
                    title: i18n.t('navbar.services') + ' | ' + pageTitle
                },
            },
            {
                path: 'datenschutz',
                name: 'Datenschutz & Impressum',
                component: Datenschutz,
                navbar: false,
                hidden: false,
                meta: {
                    title: i18n.t('navbar.about me') + ' | ' + pageTitle
                },
            },
            {
                path: '*',
                name: 'LocalisedPageNotFound',
                component: PageNotFound,
                navbar: false,
                hidden: true,
                meta: {
                    title: 'Seite nicht gefunden (404) | ' + pageTitle
                },
            },
        ]
    },
    {
        path: "/",
        redirect: "/de"
    },
    {
        path: '*',
        name: 'PageNotFound',
        component: PageNotFound,
        navbar: false,
        hidden: true,
        meta: {
            title: 'Seite nicht gefunden (404) | ' + pageTitle
        },
    },
]


const router = new VueRouter({
    scrollBehavior() {
        return {x: 0, y: 0};
    },
    routes,
    mode: "history"
})

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
    // This goes through the matched routes from last to first, finding the closest route with a title.
    // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
    // `/nested`'s will be chosen.
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    // If a route with a title was found, set the document (page) title to that value.
    if (nearestWithTitle) {
        document.title = nearestWithTitle.meta.title;
    } else if (previousNearestWithMeta) {
        document.title = previousNearestWithMeta.meta.title;
    }

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

    // Skip rendering meta tags if there are none.
    if (!nearestWithMeta) return next();

    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags.map(tagDef => {
        const tag = document.createElement('meta');

        Object.keys(tagDef).forEach(key => {
            tag.setAttribute(key, tagDef[key]);
        });

        // We use this to track which meta tags we create so we don't interfere with other ones.
        tag.setAttribute('data-vue-router-controlled', '');

        return tag;
    })
        // Add the meta tags to the document head.
        .forEach(tag => document.head.appendChild(tag));

    next();
});

export default router
