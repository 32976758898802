<template>
  <svg class="bewegt"
       xmlns:dc="http://purl.org/dc/elements/1.1/"
       xmlns:cc="http://creativecommons.org/ns#"
       xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
       xmlns:svg="http://www.w3.org/2000/svg"
       xmlns="http://www.w3.org/2000/svg"
       xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
       xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
       version="1.1"
       id="svg2"
       viewBox="0 0 2549.1199 639.7269"
       sodipodi:docname="bewegt.svg"
       inkscape:version="0.92.3 (2405546, 2018-03-11)">
    <metadata
        id="metadata8">
      <rdf:RDF>
        <cc:Work
            rdf:about="">
          <dc:format>image/svg+xml</dc:format>
          <dc:type
              rdf:resource="http://purl.org/dc/dcmitype/StillImage"/>
          <dc:title></dc:title>
        </cc:Work>
      </rdf:RDF>
    </metadata>
    <defs
        id="defs6">
      <pattern
          y="0"
          x="0"
          height="6"
          width="6"
          patternUnits="userSpaceOnUse"
          id="EMFhbasepattern"/>
    </defs>
    <sodipodi:namedview
        pagecolor="#ffffff"
        bordercolor="#666666"
        borderopacity="1"
        objecttolerance="10"
        gridtolerance="10"
        guidetolerance="10"
        inkscape:pageopacity="0"
        inkscape:pageshadow="2"
        inkscape:window-width="1366"
        inkscape:window-height="706"
        id="namedview4"
        showgrid="true"
        showborder="false"
        units="mm"
        inkscape:snap-bbox="true"
        showguides="false"
        inkscape:lockguides="true"
        inkscape:zoom="0.070710678"
        inkscape:cx="-703.41994"
        inkscape:cy="278.8556"
        inkscape:window-x="-8"
        inkscape:window-y="-8"
        inkscape:window-maximized="1"
        inkscape:current-layer="layer1"
        inkscape:snap-global="true"
        inkscape:bbox-nodes="true"
        inkscape:guide-bbox="true"
        inkscape:document-units="mm"
        inkscape:pagecheckerboard="true"
        fit-margin-top="0"
        fit-margin-left="0"
        fit-margin-right="0"
        fit-margin-bottom="0">
      <inkscape:grid
          type="xygrid"
          id="grid4614"
          originx="-3462.7875"
          originy="8952.1613"/>
      <sodipodi:guide
          position="1010,-325.00001"
          orientation="1,0"
          id="guide1010"
          inkscape:locked="true"/>
      <sodipodi:guide
          position="-3800,-2520.0001"
          orientation="0,1"
          id="guide1214"
          inkscape:locked="true"/>
      <sodipodi:guide
          position="-3800,-2760.0001"
          orientation="0,1"
          id="guide1218"
          inkscape:locked="true"/>
      <sodipodi:guide
          position="-3800,-3000.0001"
          orientation="0,1"
          id="guide1220"
          inkscape:locked="true"/>
      <sodipodi:guide
          position="-3800,-2520.0001"
          orientation="1,0"
          id="guide1222"
          inkscape:locked="true"/>
      <sodipodi:guide
          position="-3560,-2760.0001"
          orientation="1,0"
          id="guide1226"
          inkscape:locked="true"/>
      <sodipodi:guide
          position="-4040,-2520.0001"
          orientation="1,0"
          id="guide1228"
          inkscape:locked="true"/>
      <sodipodi:guide
          position="-1640,-2520.0001"
          orientation="1,0"
          id="guide1242"
          inkscape:locked="true"/>
      <sodipodi:guide
          position="-3520,-2720.0001"
          orientation="1,0"
          id="guide1252"
          inkscape:locked="true"/>
      <sodipodi:guide
          position="-4080,-2760.0001"
          orientation="1,0"
          id="guide1256"
          inkscape:locked="true"/>
      <sodipodi:guide
          position="-4320,-2760.0001"
          orientation="1,0"
          id="guide1264"
          inkscape:locked="true"/>
      <sodipodi:guide
          position="-4560,-2760.0001"
          orientation="1,0"
          id="guide1266"
          inkscape:locked="true"/>
      <sodipodi:guide
          position="-3280,-3000.0002"
          orientation="1,0"
          id="guide1272"
          inkscape:locked="true"/>
      <sodipodi:guide
          position="-3040,-3000.0002"
          orientation="1,0"
          id="guide1274"
          inkscape:locked="true"/>
      <sodipodi:guide
          position="-3000,-3000.0002"
          orientation="1,0"
          id="guide1276"
          inkscape:locked="true"/>
      <sodipodi:guide
          position="-2760,-3000.0002"
          orientation="1,0"
          id="guide1278"
          inkscape:locked="true"/>
      <sodipodi:guide
          position="-2520,-3000.0002"
          orientation="1,0"
          id="guide1280"
          inkscape:locked="true"/>
      <sodipodi:guide
          position="-2480,-3000.0002"
          orientation="1,0"
          id="guide1282"
          inkscape:locked="true"/>
      <sodipodi:guide
          position="-2240,-3000.0001"
          orientation="1,0"
          id="guide1284"
          inkscape:locked="true"/>
      <sodipodi:guide
          position="-2000,-3000.0002"
          orientation="1,0"
          id="guide1286"
          inkscape:locked="true"/>
      <sodipodi:guide
          position="-3559.9999,-2160.0001"
          orientation="0,1"
          id="guide1363"
          inkscape:locked="true"/>
      <sodipodi:guide
          position="-3760,-9920.0003"
          orientation="0,1"
          id="guide890"
          inkscape:locked="true"/>
      <sodipodi:guide
          position="-3920,-9260.0002"
          orientation="1,0"
          id="guide893"
          inkscape:locked="true"/>
      <sodipodi:guide
          position="2420,-4940.0001"
          orientation="1,0"
          id="guide939"
          inkscape:locked="true"/>
      <sodipodi:guide
          position="-1160,-5920.0002"
          orientation="0,1"
          id="guide1036"
          inkscape:locked="true"/>
      <sodipodi:guide
          position="2560,-7440.0002"
          orientation="0,1"
          id="guide1173"
          inkscape:locked="true"/>
    </sodipodi:namedview>
    <g
        inkscape:groupmode="layer"
        id="layer2"
        inkscape:label="HG"
        style="display:inline"
        sodipodi:insensitive="true"
        transform="translate(-3462.7877,-8419.3121)"/>
    <g
        inkscape:groupmode="layer"
        id="layer1"
        inkscape:label="Layer 1"
        transform="translate(-3462.7877,-8419.3121)">
      <g
          aria-label="S"
          transform="scale(-1,1)"
          style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:95.79408264px;line-height:1.25;font-family:'Century Gothic';-inkscape-font-specification:'Century Gothic';letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;stroke-width:4.49034739"
          id="text4533-0"/>
      <g
          id="g965-3-0-0-9-9"
          transform="matrix(0.64451178,0,0,0.64451178,1739.9066,1530.6312)"/>
      <path
          id="path1180-4-3-4-8-5"
          :style="pathStyle"
          d="m 5454.6201,8707.198 c 8.3174,-95.9954 -92.8576,-173.9315 -186.3957,-121.6923 -99.3914,55.5086 -79.3293,219.7088 51.3106,179.1087 50.9661,-15.8398 56.6365,-49.1322 81.7275,41.7424 27.2188,125.4921 62.0036,263.0717 -3.9527,240.8521 -93.2232,-31.4046 -8.1326,-198.2524 50.021,-257.5292 78.1485,-79.6592 124.0662,-110.6657 165.6263,-146.3079 41.3618,-35.4722 109.7483,-98.6442 97.75,-212.4155 -3.0731,-29.1404 -5.0193,201.2758 -7.1911,348.4482 -0.1468,9.937 -65.1973,-20.7528 -68.528,-28.392 -4.7116,-10.8009 4.5015,-14.6901 47.1661,-29.2056 102.9881,-31.7915 211.8812,-70.6455 320.1722,-89.8058 M 3522,8444.8801 c 0,208.6877 11.3215,131.8833 0,256.8196 -1.7465,30.3903 -22.3065,119.4359 -39.716,93.2962 -68.241,-102.4645 233.2968,-375.5007 292.8537,-213.1685 28.6938,94.0464 -119.8462,272.4667 -200.9249,181.268 -65.4947,-73.6676 132.1927,-121.8644 205.1557,-139.6203 41.627,-10.1315 208.599,-34.6473 180.4355,-96.8237 -9.0737,-20.0324 -54.2371,2.4681 -61.7856,23.1225 -8.0198,21.9405 14.056,49.5078 34.3273,61.0992 40.5424,23.1819 123.8834,43.6568 163.7384,41.1517 105.4827,-6.6305 86.4743,-81.6303 189.1577,-95.8854 99.9782,-13.8794 50.1787,207.486 134.4892,105.5255 62.4229,-75.49 205.7065,82.0252 216.6881,-50.7289 9.7538,-117.9221 239.4162,65.2579 252.4992,-18.1037 3.6897,-23.5114 -49.1303,-43.2333 -66.2271,-26.6765 -26.4553,25.6214 0.7858,83.875 37.975,103.7543 68.6974,36.7214 149.7739,13.1936 223.1564,-11.1063 67.609,-22.3879 118.4597,-46.6693 184.4018,-73.2981"
          inkscape:connector-curvature="0"
          sodipodi:nodetypes="csscssssssccccscssaasssssaassc"
          inkscape:export-xdpi="95"
          inkscape:export-ydpi="95"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: "Bewegt",
  props: {
    color: {
      type: String,
      required: false,
      default: '#000000',
    }
  },
  computed: {
    pathStyle() {
      return "fill:none;stroke:"+this.color+";stroke-width:18.89763832;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
    }
  }
}
</script>

<style scoped>
.bewegt {
  height: 30px;
  margin-top: 10px;
}
</style>