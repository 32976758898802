<template>
  <div class="circle">
    <div class="text">{{ text }}</div>
  </div>
</template>

<script>
export default {
  name: "CircleText",
  props: ["text"],
}
</script>

<style scoped>

.circle {
  height: 150px;
  width: 150px;
  box-shadow: 6px 6px 18px 0 #5b686d;
  color: white;
  position: relative;
  background: #6c757d;
  border: 1px solid gray;
  border-radius: 50%;
  bottom: -100px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
.text {
  font-family: 'Amatic SC', cursive;
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 50px;
  font-size: 2rem;
}
</style>