<template>
  <div>
    <!--  Video  -->
    <section style="height: calc(100vh - 130px)">
      <video-bg :sources="[require('@/assets/Final_1.mp4')]"
                :poster="require('@/assets/Calisthenics USZ Mai 2018 Foto Christoph Worsch (43).jpg')">
        <div class="banner">
          <img alt="Susan bewegt" height="50px;"
               src="@/assets/Susan_bewegt_nebeneinander_schwarz_Linie_transparent.svg">
          <b-container class="mt-2">
              <span class="mr-2" v-for="(item, i) in social_media" :key="i">
                  <component :is="item.icon" class="mr-1"/>
                  <a :href="item.url">{{ item.title }}</a>
                </span>
          </b-container>
        </div>
      </video-bg>
    </section>

    <!--  About me  -->
    <section>
      <CircleText :text="$t('home.about me')"/>
      <b-container>
        <b-card no-body>
          <b-row no-gutters>
            <b-col md="7">
              <b-card-body>
                <b-card-title class="myFont">{{ $t('home.hi') }}</b-card-title>
                <b-card-text>
                  <p>{{ $t('home.greet') }}
                    <bewegt :color="'black'"/>
                    .
                  </p>
                  <p> {{ $t('home.whoami')}}</p>

                  <p>{{ $t('home.idliketohearfromyou')}}</p>
                  <b-btn :to="'/' + $i18n.locale +'/ueber-mich'">{{ $t('home.more') }}</b-btn>
                </b-card-text>
              </b-card-body>
            </b-col>
            <b-col md="5">
              <b-card-img height="auto" width="auto"
                          :src="require('@/assets/Susan-Treppner-2020-Cross-Training-Foto-Christoph-Worsch-215-scaled-e1614355241752.jpg')"
                          alt="Image" class="rounded-0 justify-content-center"/>
            </b-col>
          </b-row>
        </b-card>
      </b-container>
    </section>

    <!--  Quote  -->
    <section class="transparent" style="height: 600px">
      <BackgroundImage :img="image">
        <b-container style="height: 600px">
          <quote :text="$t('home.twainquote')" author="Mark Twain" class="vh-center"/>
        </b-container>
      </BackgroundImage>
    </section>

    <!--  For you  -->
    <section>
      <circle-text :text="$t('home.foryou')"/>
      <b-container>
        <b-card-group class="mb-4 mt-5" deck>
          <b-card v-motion-pop-visible :delay="100"
                  overlay
                  :img-src="require('@/assets/personaltraining.jpg')"
                  img-alt="Card Image"
                  text-variant="white"
                  style="text-align: center"
          >
            <b-card-body>
              <b-btn :to="'/' + $i18n.locale + '/fuer-euch'" variant="light" class="vh-center big-btn">{{ $t('home.personaltraining') }}</b-btn>
            </b-card-body>
          </b-card>
          <b-card v-motion-pop-visible :delay="100"
                  overlay
                  :img-src="require('@/assets/kleingruppentraining.jpg')"
                  img-alt="Card Image"
                  text-variant="white"
                  style="text-align: center"
          >
            <b-card-body>
              <b-btn :to="'/' + $i18n.locale + '/fuer-euch'" variant="light" class="vh-center big-btn">{{ $t('home.kleingruppentraining') }}</b-btn>
            </b-card-body>
          </b-card>
        </b-card-group>
        <b-card-group deck>
          <b-card v-motion-pop-visible :delay="100"
                  overlay
                  :img-src="require('@/assets/onlinetraining.jpg')"
                  img-alt="Card Image"
                  text-variant="white"
                  style="text-align: center"
          >
            <b-card-body>
              <b-btn :to="'/' + $i18n.locale + '/fuer-euch'" variant="light" class="vh-center big-btn">{{ $t('home.onlinetraining') }}</b-btn>
            </b-card-body>
          </b-card>
          <b-card v-motion-pop-visible :delay="100"
                  overlay
                  :img-src="require('@/assets/vereinstraining.jpg')"
                  img-alt="Card Image"
                  text-variant="white"
                  style="text-align: center"
          >
            <b-card-body>
              <b-btn :to="'/' + $i18n.locale + '/fuer-euch'" variant="light" class="vh-center big-btn">{{ $t('home.vereinstraining') }}</b-btn>
            </b-card-body>
          </b-card>
        </b-card-group>
      </b-container>
    </section>

    <!--  Kontakt  -->
    <section>
      <Kontakt/>
    </section>
  </div>
</template>

<script>
import CircleText from "@/components/CircleText";
import Quote from "@/components/Quote";
import {BIconInstagram, BIconGlobe, BIconYoutube} from 'bootstrap-vue'
import Kontakt from "@/components/Kontakt";
import Bewegt from "@/components/Bewegt";
import BackgroundImage from "@/components/BackgroundImage";

export default {
  name: 'Home',
  components: {BackgroundImage, Bewegt, Kontakt, Quote, CircleText,},
  data() {
    return {
      image: require('@/assets/bb1.jpg'),
      social_media: [
        {
          title: 'susan_treppner',
          url: 'https://www.instagram.com/susan_treppner/',
          icon: BIconInstagram,
        },
        {
          title: 'YouTube',
          url: 'https://www.youtube.com/watch?v=5f4B0OGjd2Q',
          icon: BIconYoutube,
        },
        {
          title: 'susanbewegt.de',
          url: 'http://susanbewegt.de',
          icon: BIconGlobe,
        }
      ],
      anim: true,
    }
  }
}
</script>

<style scoped>
.big-btn {
  color: #5b686d;
  font-size: 1.3rem;
  padding: 0.6rem;
  border: 2px solid gray;
}
</style>
