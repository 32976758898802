<template>
  <div>
    <b-btn @click.prevent="switchLanguage(lang === 'de' ? 'en':'de')"><img height="16" width="24" :alt="lang" :src="locals[lang].icon"></b-btn>
  </div>
</template>

<script>
export default {
  name: "LanguageSwitcher",
  data() {
    return {
      locals: {
        de: {
          name: 'Deutsch',
          path: 'de',
          icon: require('@/assets/gb.png'),
        },
        en: {
          name: 'English',
          path: 'en',
          icon: require('@/assets/de.png'),
        },
      },
    }
  },
  methods: {
    switchLanguage(lang) {
      this.$i18n.locale = lang;
    }
    ,
  },
  computed: {
    lang() {
      return this.$i18n.locale;
    }
  }
}
</script>
<style scoped>
.btn, .btn:focus, .btn:active{
  padding: 0;
  margin:0;
  height:auto!important;
  width: auto!important;
  background:transparent !important;
  border:none !important;
  outline: none;
  box-shadow: none;

}
</style>