<template>
  <div :style="image" class="background">
    <slot/>
  </div>
</template>

<script>
export default {
  name: "BackgroundImage",
  props: {
    img: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      image: {backgroundImage: `url(${this.img})`},
    }
  }
}
</script>

<style scoped>
.background {
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: 0 115px;
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
}

@media (max-width: 992px) {
  .background {
    background-position: 0 80px;
  }
}

@media (max-width: 576px) {
  .background {
    background-position: 50% 80px;
    /*background-size: contain;*/
  }
}
</style>