<template>
  <b-container>
    <h1>Impressum</h1>
    <p>Informationspflicht laut § 5 TMG.</p>
    <p>{{ name }}<br/>
      {{ address }},<br/>
      {{  city }},<br/>
      {{ country }}</p>
    <p><strong>Steuernummer:</strong> {{ uid }}</p>
    <div v-if="tel!==''"><strong>Tel.:</strong> {{ tel }}<br/></div>
    <div v-if="fax!==''"><strong>Fax:</strong> {{ fax }}<br/></div>
    <p>
      <strong>E-Mail:</strong> <a :href="'mailto'+email">{{ email }}</a></p>
    <p><strong>Berufsbezeichnung:</strong> {{ titel }}</p>
    <p>Quelle: Erstellt mit dem <a title="Impressum Generator Deutschland"
                                   href="https://www.adsimple.de/impressum-generator/" target="_blank"
                                   rel="follow noopener">Impressum Generator</a> von AdSimple in Kooperation mit <a
        title="" href="https://www.slashtechnik.de" target="_blank" rel="follow noopener">slashtechnik.de</a></p>
    <h2>EU-Streitschlichtung</h2>
    <p>Gemäß Verordnung über Online-Streitbeilegung in Verbraucherangelegenheiten (ODR-Verordnung) möchten wir Sie über
      die Online-Streitbeilegungsplattform (OS-Plattform) informieren.<br/>
      Verbraucher haben die Möglichkeit, Beschwerden an die Online Streitbeilegungsplattform der Europäischen Kommission
      unter <a class="adsimple-321244596"
               href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&amp;lng=DE" target="_blank"
               rel="noopener">http://ec.europa.eu/odr?tid=321244596</a> zu richten. Die dafür notwendigen Kontaktdaten
      finden Sie oberhalb in unserem Impressum.</p>
    <p>Wir möchten Sie jedoch darauf hinweisen, dass wir nicht bereit oder verpflichtet sind, an
      Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
    <h2>Haftung für Inhalte dieser Website</h2>
    <p>Wir entwickeln die Inhalte dieser Webseite ständig weiter und bemühen uns korrekte und aktuelle Informationen
      bereitzustellen. Laut Telemediengesetz <a class="adsimple-321244596"
                                                href="https://www.gesetze-im-internet.de/tmg/__7.html?tid=321244596"
                                                target="_blank" rel="noopener">(TMG) §7 (1)</a> sind wir als
      Diensteanbieter für eigene Informationen, die wir zur Nutzung bereitstellen, nach den allgemeinen Gesetzen
      verantwortlich. Leider können wir keine Haftung für die Korrektheit aller Inhalte auf dieser Webseite übernehmen,
      speziell für jene die seitens Dritter bereitgestellt wurden. Als Diensteanbieter im Sinne der §§ 8 bis 10 sind wir
      nicht verpflichtet, die von ihnen übermittelten oder gespeicherten Informationen zu überwachen oder nach Umständen
      zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.</p>
    <p>Unsere Verpflichtungen zur Entfernung von Informationen oder zur Sperrung der Nutzung von Informationen nach den
      allgemeinen Gesetzen aufgrund von gerichtlichen oder behördlichen Anordnungen bleiben auch im Falle unserer
      Nichtverantwortlichkeit nach den §§ 8 bis 10 unberührt.</p>
    <p>Sollten Ihnen problematische oder rechtswidrige Inhalte auffallen, bitte wir Sie uns umgehend zu kontaktieren,
      damit wir die rechtswidrigen Inhalte entfernen können. Sie finden die Kontaktdaten im Impressum.</p>
    <h2>Haftung für Links auf dieser Website</h2>
    <p>Unsere Webseite enthält Links zu anderen Webseiten für deren Inhalt wir nicht verantwortlich sind. Haftung für
      verlinkte Websites besteht für uns nicht, da wir keine Kenntnis rechtswidriger Tätigkeiten hatten und haben, uns
      solche Rechtswidrigkeiten auch bisher nicht aufgefallen sind und wir Links sofort entfernen würden, wenn uns
      Rechtswidrigkeiten bekannt werden.</p>
    <p>Wenn Ihnen rechtswidrige Links auf unserer Website auffallen, bitte wir Sie uns zu kontaktieren. Sie finden die
      Kontaktdaten im Impressum.</p>
    <h2>Urheberrechtshinweis</h2>
    <p>Alle Inhalte dieser Webseite (Bilder, Fotos, Texte, Videos) unterliegen dem Urheberrecht der Bundesrepublik
      Deutschland. Bitte fragen Sie uns bevor Sie die Inhalte dieser Website verbreiten, vervielfältigen oder verwerten
      wie zum Beispiel auf anderen Websites erneut veröffentlichen. Falls notwendig, werden wir die unerlaubte Nutzung
      von Teilen der Inhalte unserer Seite rechtlich verfolgen.</p>
    <p>Sollten Sie auf dieser Webseite Inhalte finden, die das Urheberrecht verletzen, bitten wir Sie uns zu
      kontaktieren.</p>
    <h2>Bildernachweis</h2>
    <p>Die Bilder, Fotos und Grafiken auf dieser Webseite sind urheberrechtlich geschützt.</p>
    <p>Die Bilderrechte liegen bei den folgenden Fotografen und Unternehmen:</p>
    <ul>
      <li v-for="(creator,i) in rights" :key="i">{{creator.name}}, <a :href="creator.url"> {{creator.url}}</a> </li>
    </ul>
    <h1>Datenschutzerklärung</h1>
    <h2>Datenschutz</h2>
    <p>Wir haben diese Datenschutzerklärung (Fassung 26.12.2020-321244596) verfasst, um Ihnen gemäß der Vorgaben der <a
        class="adsimple-321244596"
        href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679&amp;tid=321244596" target="_blank"
        rel="noopener">Datenschutz-Grundverordnung (EU) 2016/679</a> zu erklären, welche Informationen wir sammeln, wie
      wir Daten verwenden und welche Entscheidungsmöglichkeiten Sie als Besucher dieser Webseite haben.</p>
    <p>Leider liegt es in der Natur der Sache, dass diese Erklärungen sehr technisch klingen, wir haben uns bei der
      Erstellung jedoch bemüht die wichtigsten Dinge so einfach und klar wie möglich zu beschreiben.</p>
    <h2>Automatische Datenspeicherung</h2>
    <p>Wenn Sie heutzutage Webseiten besuchen, werden gewisse Informationen automatisch erstellt und gespeichert, so
      auch auf dieser Webseite.</p>
    <p>Wenn Sie unsere Webseite so wie jetzt gerade besuchen, speichert unser Webserver (Computer auf dem diese Webseite
      gespeichert ist) automatisch Daten wie</p>
    <ul>
      <li>die Adresse (URL) der aufgerufenen Webseite</li>
      <li>Browser und Browserversion</li>
      <li>das verwendete Betriebssystem</li>
      <li>die Adresse (URL) der zuvor besuchten Seite (Referrer URL)</li>
      <li>den Hostname und die IP-Adresse des Geräts von welchem aus zugegriffen wird</li>
      <li>Datum und Uhrzeit</li>
    </ul>
    <p>in Dateien (Webserver-Logfiles).</p>
    <p>In der Regel werden Webserver-Logfiles zwei Wochen gespeichert und danach automatisch gelöscht. Wir geben diese
      Daten nicht weiter, können jedoch nicht ausschließen, dass diese Daten beim Vorliegen von rechtswidrigem Verhalten
      eingesehen werden.</p>
    <h2>Cookies</h2>
    <p>Unsere Website verwendet HTTP-Cookies um nutzerspezifische Daten zu speichern.<br/>
      Im Folgenden erklären wir, was Cookies sind und warum Sie genutzt werden, damit Sie die folgende
      Datenschutzerklärung besser verstehen.</p>
    <h3>Was genau sind Cookies?</h3>
    <p>Immer wenn Sie durch das Internet surfen, verwenden Sie einen Browser. Bekannte Browser sind beispielsweise
      Chrome, Safari, Firefox, Internet Explorer und Microsoft Edge. Die meisten Webseiten speichern kleine Text-Dateien
      in Ihrem Browser. Diese Dateien nennt man Cookies.</p>
    <p>Eines ist nicht von der Hand zu weisen: Cookies sind echt nützliche Helferlein. Fast alle Webseiten verwenden
      Cookies. Genauer gesprochen sind es HTTP-Cookies, da es auch noch andere Cookies für andere Anwendungsbereiche
      gibt. HTTP-Cookies sind kleine Dateien, die von unserer Website auf Ihrem Computer gespeichert werden. Diese
      Cookie-Dateien werden automatisch im Cookie-Ordner, quasi dem “Hirn” Ihres Browsers, untergebracht. Ein Cookie
      besteht aus einem Namen und einem Wert. Bei der Definition eines Cookies müssen zusätzlich ein oder mehrere
      Attribute angegeben werden.</p>
    <p>Cookies speichern gewisse Nutzerdaten von Ihnen, wie beispielsweise Sprache oder persönliche Seiteneinstellungen.
      Wenn Sie unsere Seite wieder aufrufen, übermittelt Ihr Browser die „userbezogenen“ Informationen an unsere Seite
      zurück. Dank der Cookies weiß unsere Website, wer Sie sind und bietet Ihnen Ihre gewohnte Standardeinstellung. In
      einigen Browsern hat jedes Cookie eine eigene Datei, in anderen wie beispielsweise Firefox sind alle Cookies in
      einer einzigen Datei gespeichert.</p>
    <p>Es gibt sowohl Erstanbieter Cookies als auch Drittanbieter-Cookies. Erstanbieter-Cookies werden direkt von
      unserer Seite erstellt, Drittanbieter-Cookies werden von Partner-Webseiten (z.B. Google Analytics) erstellt. Jedes
      Cookie ist individuell zu bewerten, da jedes Cookie andere Daten speichert. Auch die Ablaufzeit eines Cookies
      variiert von ein paar Minuten bis hin zu ein paar Jahren. Cookies sind keine Software-Programme und enthalten
      keine Viren, Trojaner oder andere „Schädlinge“. Cookies können auch nicht auf Informationen Ihres PCs
      zugreifen.</p>
    <p>So können zum Beispiel Cookie-Daten aussehen:</p>
    <ul>
      <li>Name: _ga</li>
      <li>Ablaufzeit: 2 Jahre</li>
      <li>Verwendung: Unterscheidung der Webseitenbesucher</li>
      <li>Beispielhafter Wert: GA1.2.1326744211.152321244596</li>
    </ul>
    <p>Ein Browser sollte folgende Mindestgrößen unterstützen:</p>
    <ul>
      <li>Ein Cookie soll mindestens 4096 Bytes enthalten können</li>
      <li>Pro Domain sollen mindestens 50 Cookies gespeichert werden können</li>
      <li>Insgesamt sollen mindestens 3000 Cookies gespeichert werden können</li>
    </ul>
    <h3>Welche Arten von Cookies gibt es?</h3>
    <p>Die Frage welche Cookies wir im Speziellen verwenden, hängt von den verwendeten Diensten ab und wird in der
      folgenden Abschnitten der Datenschutzerklärung geklärt. An dieser Stelle möchten wir kurz auf die verschiedenen
      Arten von HTTP-Cookies eingehen.</p>
    <p>Man kann 4 Arten von Cookies unterscheiden:</p>
    <p><strong>Unbedingt notwendige Cookies<br/>
    </strong>Diese Cookies sind nötig, um grundlegende Funktionen der Website sicherzustellen. Zum Beispiel braucht es
      diese Cookies, wenn ein User ein Produkt in den Warenkorb legt, dann auf anderen Seiten weitersurft und später
      erst zur Kasse geht. Durch diese Cookies wird der Warenkorb nicht gelöscht, selbst wenn der User sein
      Browserfenster schließt.</p>
    <p><strong>Funktionelle Cookies<br/>
    </strong>Diese Cookies sammeln Infos über das Userverhalten und ob der User etwaige Fehlermeldungen bekommt. Zudem
      werden mithilfe dieser Cookies auch die Ladezeit und das Verhalten der Website bei verschiedenen Browsern
      gemessen.</p>
    <p><strong>Zielorientierte Cookies<br/>
    </strong>Diese Cookies sorgen für eine bessere Nutzerfreundlichkeit. Beispielsweise werden eingegebene Standorte,
      Schriftgrößen oder Formulardaten gespeichert.</p>
    <p><strong>Werbe-Cookies<br/>
    </strong>Diese Cookies werden auch Targeting-Cookies genannt. Sie dienen dazu dem User individuell angepasste
      Werbung zu liefern. Das kann sehr praktisch, aber auch sehr nervig sein.</p>
    <p>Üblicherweise werden Sie beim erstmaligen Besuch einer Webseite gefragt, welche dieser Cookiearten Sie zulassen
      möchten. Und natürlich wird diese Entscheidung auch in einem Cookie gespeichert.</p>
    <h3>Wie kann ich Cookies löschen?</h3>
    <p>Wie und ob Sie Cookies verwenden wollen, entscheiden Sie selbst. Unabhängig von welchem Service oder welcher
      Website die Cookies stammen, haben Sie immer die Möglichkeit Cookies zu löschen, nur teilweise zuzulassen oder zu
      deaktivieren. Zum Beispiel können Sie Cookies von Drittanbietern blockieren, aber alle anderen Cookies
      zulassen.</p>
    <p>Wenn Sie feststellen möchten, welche Cookies in Ihrem Browser gespeichert wurden, wenn Sie Cookie-Einstellungen
      ändern oder löschen wollen, können Sie dies in Ihren Browser-Einstellungen finden:</p>
    <p><a class="adsimple-321244596" href="https://support.google.com/chrome/answer/95647?tid=321244596" target="_blank"
          rel="noopener">Chrome: Cookies in Chrome löschen, aktivieren und verwalten</a></p>
    <p><a class="adsimple-321244596" href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=321244596"
          target="_blank" rel="noopener">Safari: Verwalten von Cookies und Websitedaten mit Safari</a></p>
    <p><a class="adsimple-321244596"
          href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=321244596"
          target="_blank" rel="noopener">Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem
      Computer abgelegt haben</a></p>
    <p><a class="adsimple-321244596"
          href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=321244596"
          target="_blank" rel="noopener">Internet Explorer: Löschen und Verwalten von Cookies</a></p>
    <p><a class="adsimple-321244596"
          href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=321244596" target="_blank"
          rel="noopener">Microsoft Edge: Löschen und Verwalten von Cookies</a></p>
    <p>Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser so einrichten, dass er Sie immer
      informiert, wenn ein Cookie gesetzt werden soll. So können Sie bei jedem einzelnen Cookie entscheiden, ob Sie das
      Cookie erlauben oder nicht. Die Vorgangsweise ist je nach Browser verschieden. Am besten ist es Sie suchen die
      Anleitung in Google mit dem Suchbegriff “Cookies löschen Chrome” oder “Cookies deaktivieren Chrome” im Falle eines
      Chrome Browsers oder tauschen das Wort “Chrome” gegen den Namen Ihres Browsers, z.B. Edge, Firefox, Safari
      aus.</p>
    <h3>Wie sieht es mit meinem Datenschutz aus?</h3>
    <p>Seit 2009 gibt es die sogenannten „Cookie-Richtlinien“. Darin ist festgehalten, dass das Speichern von Cookies
      eine Einwilligung von Ihnen verlangt. Innerhalb der EU-Länder gibt es allerdings noch sehr unterschiedliche
      Reaktionen auf diese Richtlinien. In Deutschland wurden die Cookie-Richtlinien nicht als nationales Recht
      umgesetzt. Stattdessen erfolgte die Umsetzung dieser Richtlinie weitgehend in § 15 Abs.3 des Telemediengesetzes
      (TMG).</p>
    <p>Wenn Sie mehr über Cookies wissen möchten und technischen Dokumentationen nicht scheuen, empfehlen wir <a
        class="adsimple-321244596" href="https://tools.ietf.org/html/rfc6265" target="_blank" rel="nofollow noopener">https://tools.ietf.org/html/rfc6265</a>,
      dem Request for Comments der Internet Engineering Task Force (IETF) namens “HTTP State Management Mechanism”.</p>
    <h2>Speicherung persönlicher Daten</h2>
    <p>Persönliche Daten, die Sie uns auf dieser Website elektronisch übermitteln, wie zum Beispiel Name,
      E-Mail-Adresse, Adresse oder andere persönlichen Angaben im Rahmen der Übermittlung eines Formulars oder
      Kommentaren im Blog, werden von uns gemeinsam mit dem Zeitpunkt und der IP-Adresse nur zum jeweils angegebenen
      Zweck verwendet, sicher verwahrt und nicht an Dritte weitergegeben.</p>
    <p>Wir nutzen Ihre persönlichen Daten somit nur für die Kommunikation mit jenen Besuchern, die Kontakt ausdrücklich
      wünschen und für die Abwicklung der auf dieser Webseite angebotenen Dienstleistungen und Produkte. Wir geben Ihre
      persönlichen Daten ohne Zustimmung nicht weiter, können jedoch nicht ausschließen, dass diese Daten beim Vorliegen
      von rechtswidrigem Verhalten eingesehen werden.</p>
    <p>Wenn Sie uns persönliche Daten per E-Mail schicken – somit abseits dieser Webseite – können wir keine sichere
      Übertragung und den Schutz Ihrer Daten garantieren. Wir empfehlen Ihnen, vertrauliche Daten niemals
      unverschlüsselt per E-Mail zu übermitteln.</p>
    <p>Die Rechtsgrundlage besteht nach <a class="adsimple-321244596"
                                           href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&amp;from=DE&amp;tid=321244596"
                                           target="_blank" rel="noopener">Artikel 6 Absatz 1 a DSGVO</a> (Rechtmäßigkeit
      der Verarbeitung) darin, dass Sie uns die Einwilligung zur Verarbeitung der von Ihnen eingegebenen Daten geben.
      Sie können diesen Einwilligung jederzeit widerrufen – eine formlose E-Mail reicht aus, Sie finden unsere
      Kontaktdaten im Impressum.</p>
    <h2>Rechte laut Datenschutzgrundverordnung</h2>
    <p>Ihnen stehen laut den Bestimmungen der DSGVO grundsätzlich die folgende Rechte zu:</p>
    <ul>
      <li>Recht auf Berichtigung (Artikel 16 DSGVO)</li>
      <li>Recht auf Löschung („Recht auf Vergessenwerden“) (Artikel 17 DSGVO)</li>
      <li>Recht auf Einschränkung der Verarbeitung (Artikel 18 DSGVO)</li>
      <li>Recht auf Benachrichtigung – Mitteilungspflicht im Zusammenhang mit der
        Berichtigung oder Löschung personenbezogener Daten oder der Einschränkung der Verarbeitung (Artikel 19 DSGVO)
      </li>
      <li>Recht auf Datenübertragbarkeit (Artikel 20 DSGVO)</li>
      <li>Widerspruchsrecht (Artikel 21 DSGVO)</li>
      <li>Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung —
        einschließlich Profiling — beruhenden Entscheidung unterworfen zu werden (Artikel 22 DSGVO)
      </li>
    </ul>
    <p>Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre
      datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt worden sind, können Sie sich an die <a
          class="321244596" href="https://www.bfdi.bund.de" target="_blank" rel="noopener">Bundesbeauftragte für den
        Datenschutz und die Informationsfreiheit (BfDI)</a> wenden.</p>
    <h2>Auswertung des Besucherverhaltens</h2>
    <p>In der folgenden Datenschutzerklärung informieren wir Sie darüber, ob und wie wir Daten Ihres Besuchs dieser
      Website auswerten. Die Auswertung der gesammelten Daten erfolgt in der Regel anonym und wir können von Ihrem
      Verhalten auf dieser Website nicht auf Ihre Person schließen.</p>
    <p>Mehr über Möglichkeiten dieser Auswertung der Besuchsdaten zu widersprechen erfahren Sie in der folgenden
      Datenschutzerklärung.</p>
    <h2>TLS-Verschlüsselung mit https</h2>
    <p>Wir verwenden https um Daten abhörsicher im Internet zu übertragen (Datenschutz durch Technikgestaltung <a
        class="adsimple-321244596"
        href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&amp;from=DE&amp;tid=321244596"
        target="_blank" rel="noopener">Artikel 25 Absatz 1 DSGVO</a>). Durch den Einsatz von TLS (Transport Layer
      Security), einem Verschlüsselungsprotokoll zur sicheren Datenübertragung im Internet können wir den Schutz
      vertraulicher Daten sicherstellen. Sie erkennen die Benutzung dieser Absicherung der Datenübertragung am kleinen
      Schloßsymbol links oben im Browser und der Verwendung des Schemas https (anstatt http) als Teil unserer
      Internetadresse.</p>
    <h2>Google Maps Datenschutzerklärung</h2>
    <p>Wir benützen auf unserer Website Google Maps der Firma Google Inc. Für den europäischen Raum ist das Unternehmen
      Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) für alle Google-Dienste verantwortlich. Mit
      Google Maps können wir Ihnen Standorte besser zeigen und damit unser Service an Ihre Bedürfnisse anpassen. Durch
      die Verwendung von Google Maps werden Daten an Google übertragen und auf den Google-Servern gespeichert. Hier
      wollen wir nun genauer darauf eingehen, was Google Maps ist, warum wir diesen Google-Dienst in Anspruch nehmen,
      welche Daten gespeichert werden und wie Sie dies unterbinden können.</p>
    <h3>Was ist Google Maps?</h3>
    <p>Google Maps ist ein Internet-Kartendienst der Firma Google. Mit Google Maps können Sie online über einen PC, ein
      Tablet oder eine App genaue Standorte von Städten, Sehenswürdigkeiten, Unterkünften oder Unternehmen suchen. Wenn
      Unternehmen auf Google My Business vertreten sind, werden neben dem Standort noch weitere Informationen über die
      Firma angezeigt. Um die Anfahrtsmöglichkeit anzuzeigen, können Kartenausschnitte eines Standorts mittels HTML-Code
      in eine Website eingebunden werden. Google Maps zeigt die Erdoberfläche als Straßenkarte oder als Luft- bzw.
      Satellitenbild. Dank der Street View Bilder und den hochwertigen Satellitenbildern sind sehr genaue Darstellungen
      möglich.</p>
    <h3>Warum verwenden wir Google Maps auf unserer Webseite?</h3>
    <p>All unsere Bemühungen auf dieser Seite verfolgen das Ziel, Ihnen eine nützliche und sinnvolle Zeit auf unserer
      Webseite zu bieten. Durch die Einbindung von Google Maps können wir Ihnen die wichtigsten Informationen zu
      diversen Standorten liefern. Sie sehen auf einen Blick wo wir unseren Firmensitz haben. Die Wegbeschreibung zeigt
      Ihnen immer den besten bzw. schnellsten Weg zu uns. Sie können den Anfahrtsweg für Routen mit dem Auto, mit
      öffentlichen Verkehrsmitteln, zu Fuß oder mit dem Fahrrad abrufen. Für uns ist die Bereitstellung von Google Maps
      Teil unseres Kundenservice.</p>
    <h3>Welche Daten werden von Google Maps gespeichert?</h3>
    <p>Damit Google Maps ihren Dienst vollständig anbieten kann, muss das Unternehmen Daten von Ihnen aufnehmen und
      speichern. Dazu zählen unter anderem die eingegebenen Suchbegriffe, Ihre IP-Adresse und auch die Breiten- bzw.
      Längenkoordinaten. Benutzen Sie die Routenplaner-Funktion wird auch die eingegebene Startadresse gespeichert.
      Diese Datenspeicherung passiert allerdings auf den Webseiten von Google Maps. Wir können Sie darüber nur
      informieren, aber keinen Einfluss nehmen. Da wir Google Maps in unsere Webseite eingebunden haben, setzt Google
      mindestens ein Cookie (Name: NID) in Ihrem Browser. Dieses Cookie speichert Daten über Ihr Userverhalten. Google
      nutzt diese Daten in erster Linie, um eigene Dienste zu optimieren und individuelle, personalisierte Werbung für
      Sie bereitzustellen.</p>
    <p>Folgendes Cookie wird aufgrund der Einbindung von Google Maps in Ihrem Browser gesetzt:</p>
    <p><strong>Name:</strong> NID<br/>
      <strong>Wert:</strong> 188=h26c1Ktha7fCQTx8rXgLyATyITJ321244596-5<br/>
      <strong>Verwendungszweck:</strong> NID wird von Google verwendet, um Werbeanzeigen an
      Ihre Google-Suche anzupassen. Mit Hilfe des Cookies „erinnert“ sich Google an Ihre am häufigsten eingegebenen
      Suchanfragen oder Ihre frühere Interaktion mit Anzeigen. So bekommen Sie immer maßgeschneiderte Werbeanzeigen. Das
      Cookie enthält eine einzigartige ID, die Google benutzt, um Ihre persönlichen Einstellungen für Werbezwecke zu
      sammeln.<br/>
      <strong>Ablaufdatum:</strong> nach 6 Monaten</p>
    <p><strong>Anmerkung:</strong> Wir können bei den Angaben der gespeicherten Daten keine
      Vollständigkeit gewährleisten. Speziell bei der Verwendung von Cookies sind Veränderungen nie auszuschließen. Um
      das Cookie NID zu identifizieren, wurde eine eigene Testseite angelegt, wo ausschließlich Google Maps eingebunden
      war.</p>
    <h3>Wie lange und wo werden die Daten gespeichert?</h3>
    <p>Die Google-Server stehen in Rechenzentren auf der ganzen Welt. Die meisten Server befinden sich allerdings in
      Amerika. Aus diesem Grund werden Ihre Daten auch vermehrt in den USA gespeichert. Hier können Sie genau nachlesen
      wo sich die Google-Rechenzentren befinden: <a class="adsimple-321244596"
                                                    href="https://www.google.com/about/datacenters/inside/locations/?hl=de"
                                                    target="_blank" rel="noopener noreferrer">https://www.google.com/about/datacenters/inside/locations/?hl=de</a>
    </p>
    <p>Die Daten verteilt Google auf verschiedenen Datenträgern. Dadurch sind die Daten schneller abrufbar und werden
      vor etwaigen Manipulationsversuchen besser geschützt. Jedes Rechenzentrum hat auch spezielle Notfallprogramme.
      Wenn es zum Beispiel Probleme bei der Google-Hardware gibt oder eine Naturkatastrophe die Server lahm legt,
      bleiben die Daten ziemlich sicher trotzdem geschützt.</p>
    <p>Manche Daten speichert Google für einen festgelegten Zeitraum. Bei anderen Daten bietet Google lediglich die
      Möglichkeit, diese manuell zu löschen. Weiters anonymisiert das Unternehmen auch Informationen (wie zum Beispiel
      Werbedaten) in Serverprotokollen, indem es einen Teil der IP-Adresse und Cookie-Informationen nach 9 bzw.18
      Monaten löscht.</p>
    <h3>Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
    <p>Mit der 2019 eingeführten automatischen Löschfunktion von Standort- und Aktivitätsdaten werden Informationen zur
      Standortbestimmung und Web-/App-Aktivität – abhängig von Ihrer Entscheidung – entweder 3 oder 18 Monate
      gespeichert und dann gelöscht. Zudem kann man diese Daten über das Google-Konto auch jederzeit manuell aus dem
      Verlauf löschen. Wenn Sie Ihre Standorterfassung vollständig verhindern wollen, müssen Sie im Google-Konto die
      Rubrik „Web- und App-Aktivität“ pausieren. Klicken Sie „Daten und Personalisierung“ und dann auf die Option
      „Aktivitätseinstellung“. Hier können Sie die Aktivitäten ein- oder ausschalten.</p>
    <p>In Ihrem Browser können Sie weiters auch einzelne Cookies deaktivieren, löschen oder verwalten. Je nach dem
      welchen Browser Sie verwenden, funktioniert dies immer etwas anders. Die folgenden Anleitungen zeigen, wie Sie
      Cookies in Ihrem Browser verwalten:</p>
    <p><a class="adsimple-321244596" href="https://support.google.com/chrome/answer/95647?tid=321244596" target="_blank"
          rel="noopener noreferrer">Chrome: Cookies in Chrome löschen, aktivieren und verwalten</a></p>
    <p><a class="adsimple-321244596" href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=321244596"
          target="_blank" rel="noopener noreferrer">Safari: Verwalten von Cookies und Websitedaten mit Safari</a></p>
    <p><a class="adsimple-321244596"
          href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=321244596"
          target="_blank" rel="noopener noreferrer">Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf
      Ihrem Computer abgelegt haben</a></p>
    <p><a class="adsimple-321244596"
          href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=321244596"
          target="_blank" rel="noopener noreferrer">Internet Explorer: Löschen und Verwalten von Cookies</a></p>
    <p><a class="adsimple-321244596"
          href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=321244596" target="_blank"
          rel="noopener noreferrer">Microsoft Edge: Löschen und Verwalten von Cookies</a></p>
    <p>Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser so einrichten, dass er Sie immer
      informiert, wenn ein Cookie gesetzt werden soll. So können Sie bei jedem einzelnen Cookie entscheiden, ob Sie es
      erlauben oder nicht.</p>
    <p>Google ist aktiver Teilnehmer beim EU-U.S. Privacy Shield Framework, wodurch der korrekte und sichere
      Datentransfer persönlicher Daten geregelt wird. Mehr Informationen dazu finden Sie auf <a
          class="adsimple-321244596" href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI"
          target="_blank" rel="follow noopener noreferrer">https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI</a>.
      Wenn Sie mehr über die Datenverarbeitung von Google erfahren wollen, empfehlen wir Ihnen die hauseigene
      Datenschutzerklärung des Unternehmens unter <a class="adsimple-321244596"
                                                     href="https://policies.google.com/privacy?hl=de" target="_blank"
                                                     rel="noopener noreferrer">https://policies.google.com/privacy?hl=de</a>.
    </p>
    <h2>Google Fonts Datenschutzerklärung</h2>
    <p>Auf unserer Website verwenden wir Google Fonts. Das sind die “Google-Schriften” der Firma Google Inc. Für den
      europäischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) für
      alle Google-Dienste verantwortlich.</p>
    <p>Für die Verwendung von Google-Schriftarten müssen Sie sich nicht anmelden bzw. ein Passwort hinterlegen. Weiters
      werden auch keine Cookies in Ihrem Browser gespeichert. Die Dateien (CSS, Schriftarten/Fonts) werden über die
      Google-Domains fonts.googleapis.com und fonts.gstatic.com angefordert. Laut Google sind die Anfragen nach CSS und
      Schriften vollkommen getrennt von allen anderen Google-Diensten. Wenn Sie ein Google-Konto haben, brauchen Sie
      keine Sorge haben, dass Ihre Google-Kontodaten, während der Verwendung von Google Fonts, an Google übermittelt
      werden. Google erfasst die Nutzung von CSS (Cascading Style Sheets) und der verwendeten Schriftarten und speichert
      diese Daten sicher. Wie die Datenspeicherung genau aussieht, werden wir uns noch im Detail ansehen.</p>
    <h3>Was sind Google Fonts?</h3>
    <p>Google Fonts (früher Google Web Fonts) ist ein Verzeichnis mit über 800 Schriftarten, die <a
        class="adsimple-321244596" href="https://de.wikipedia.org/wiki/Google_LLC?tid=321244596">Google</a> seinen
      Nutzern kostenlos zu Verfügung stellt.</p>
    <p>Viele dieser Schriftarten sind unter der SIL Open Font License veröffentlicht, während andere unter der
      Apache-Lizenz veröffentlicht wurden. Beides sind freie Software-Lizenzen.</p>
    <h3>Warum verwenden wir Google Fonts auf unserer Webseite?</h3>
    <p>Mit Google Fonts können wir auf unserer eigenen Webseite Schriften nutzen, doch müssen sie nicht auf unseren
      eigenen Server hochladen. Google Fonts ist ein wichtiger Baustein, um die Qualität unserer Webseite hoch zu
      halten. Alle Google-Schriften sind automatisch für das Web optimiert und dies spart Datenvolumen und ist speziell
      für die Verwendung mit mobilen Endgeräten ein großer Vorteil. Wenn Sie unsere Seite besuchen, sorgt die niedrige
      Dateigröße für eine schnelle Ladezeit. Des Weiteren sind Google Fonts sichere Web Fonts. Unterschiedliche
      Bildsynthese-Systeme (Rendering) in verschiedenen Browsern, Betriebssystemen und mobilen Endgeräten können zu
      Fehlern führen. Solche Fehler können teilweise Texte bzw. ganze Webseiten optisch verzerren. Dank des schnellen
      Content Delivery Networks (CDN) gibt es mit Google Fonts keine plattformübergreifenden Probleme. Google Fonts
      unterstützt alle gängigen Browser (Google Chrome, Mozilla Firefox, Apple Safari, Opera) und funktioniert
      zuverlässig auf den meisten modernen mobilen Betriebssystemen, einschließlich Android 2.2+ und iOS 4.2+ (iPhone,
      iPad, iPod). Wir verwenden die Google Fonts also, damit wir unser gesamtes Online-Service so schön und einheitlich
      wie möglich darstellen können.</p>
    <h3>Welche Daten werden von Google gespeichert?</h3>
    <p>Wenn Sie unsere Webseite besuchen, werden die Schriften über einen Google-Server nachgeladen. Durch diesen
      externen Aufruf werden Daten an die Google-Server übermittelt. So erkennt Google auch, dass Sie bzw. Ihre
      IP-Adresse unsere Webseite besuchen. Die Google Fonts API wurde entwickelt, um Verwendung, Speicherung und
      Erfassung von Endnutzerdaten auf das zu reduzieren, was für eine ordentliche Bereitstellung von Schriften nötig
      ist. API steht übrigens für „Application Programming Interface“ und dient unter anderem als Datenübermittler im
      Softwarebereich.</p>
    <p>Google Fonts speichert CSS- und Schrift-Anfragen sicher bei Google und ist somit geschützt. Durch die gesammelten
      Nutzungszahlen kann Google feststellen, wie gut die einzelnen Schriften ankommen. Die Ergebnisse veröffentlicht
      Google auf internen Analyseseiten, wie beispielsweise Google Analytics. Zudem verwendet Google auch Daten des
      eigenen Web-Crawlers, um festzustellen, welche Webseiten Google-Schriften verwenden. Diese Daten werden in der
      BigQuery-Datenbank von Google Fonts veröffentlicht. Unternehmer und Entwickler nützen das Google-Webservice
      BigQuery, um große Datenmengen untersuchen und bewegen zu können.</p>
    <p>Zu bedenken gilt allerdings noch, dass durch jede Google Font Anfrage auch Informationen wie Spracheinstellungen,
      IP-Adresse, Version des Browsers, Bildschirmauflösung des Browsers und Name des Browsers automatisch an die
      Google-Server übertragen werden. Ob diese Daten auch gespeichert werden, ist nicht klar feststellbar bzw. wird von
      Google nicht eindeutig kommuniziert.</p>
    <h3>Wie lange und wo werden die Daten gespeichert?</h3>
    <p>Anfragen für CSS-Assets speichert Google einen Tag lang auf seinen Servern, die hauptsächlich außerhalb der EU
      angesiedelt sind. Das ermöglicht uns, mithilfe eines Google-Stylesheets die Schriftarten zu nutzen. Ein Stylesheet
      ist eine Formatvorlage, über die man einfach und schnell z.B. das Design bzw. die Schriftart einer Webseite ändern
      kann.</p>
    <p>Die Font-Dateien werden bei Google ein Jahr gespeichert. Google verfolgt damit das Ziel, die Ladezeit von
      Webseiten grundsätzlich zu verbessern. Wenn Millionen von Webseiten auf die gleichen Schriften verweisen, werden
      sie nach dem ersten Besuch zwischengespeichert und erscheinen sofort auf allen anderen später besuchten Webseiten
      wieder. Manchmal aktualisiert Google Schriftdateien, um die Dateigröße zu reduzieren, die Abdeckung von Sprache zu
      erhöhen und das Design zu verbessern.</p>
    <h3>Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
    <p>Jene Daten, die Google für einen Tag bzw. ein Jahr speichert können nicht einfach gelöscht werden. Die Daten
      werden beim Seitenaufruf automatisch an Google übermittelt. Um diese Daten vorzeitig löschen zu können, müssen Sie
      den Google-Support auf <a>https://support.google.com/?hl=de&amp;tid=321244596</a>
      kontaktieren. Datenspeicherung verhindern Sie in diesem Fall nur, wenn Sie unsere Seite nicht besuchen.</p>
    <p>Anders als andere Web-Schriften erlaubt uns Google uneingeschränkten Zugriff auf alle Schriftarten. Wir können
      also unlimitiert auf ein Meer an Schriftarten zugreifen und so das Optimum für unsere Webseite rausholen. Mehr zu
      Google Fonts und weiteren Fragen finden Sie auf <a class="adsimple-321244596"
                                                         href="https://developers.google.com/fonts/faq?tid=321244596">https://developers.google.com/fonts/faq?tid=321244596</a>.
      Dort geht zwar Google auf datenschutzrelevante Angelegenheiten ein, doch wirklich detaillierte Informationen über
      Datenspeicherung sind nicht enthalten. Es ist relativ schwierig, von Google wirklich präzise Informationen über
      gespeicherten Daten zu bekommen.</p>
    <p>Welche Daten grundsätzlich von Google erfasst werden und wofür diese Daten verwendet werden, können Sie auch auf
      <a>https://www.google.com/intl/de/policies/privacy/</a>
      nachlesen.</p>
    <h2>Google Fonts Lokal Datenschutzerklärung</h2>
    <p>Auf unserer Website nutzen wir Google Fonts der Firma Google Inc. Für den europäischen Raum ist das Unternehmen
      Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) verantwortlich. Wir haben die
      Google-Schriftarten lokal, d.h. auf unserem Webserver – nicht auf den Servern von Google – eingebunden. Dadurch
      gibt es keine Verbindung zu Google-Servern und somit auch keine Datenübertragung oder Speicherung.</p>
    <h3>Was sind Google Fonts?</h3>
    <p>Früher nannte man Google Fonts auch Google Web Fonts. Dabei handelt es sich um ein interaktives Verzeichnis mit
      über 800 Schriftarten, die <a class="adsimple-321244596"
                                    href="https://de.wikipedia.org/wiki/Google_LLC?tid=321244596">Google</a> kostenlos
      bereitstellt. Mit Google Fonts könnte man Schriften nutzen, ohne sie auf den eigenen Server hochzuladen. Doch um
      diesbezüglich jede Informationsübertragung zu Google-Servern zu unterbinden, haben wir die Schriftarten auf
      unseren Server heruntergeladen. Auf diese Weise handeln wir datenschutzkonform und senden keine Daten an Google
      Fonts weiter.</p>
    <p>Anders als andere Web-Schriften erlaubt uns Google uneingeschränkten Zugriff auf alle Schriftarten. Wir können
      also unlimitiert auf ein Meer an Schriftarten zugreifen und so das Optimum für unsere Webseite rausholen. Mehr zu
      Google Fonts und weiteren Fragen finden Sie auf <a class="adsimple-321244596"
                                                         href="https://developers.google.com/fonts/faq?tid=321244596">https://developers.google.com/fonts/faq?tid=321244596</a>.
    </p>
    <h2>OpenStreetMap Datenschutzerklärung</h2>
    <p>Wir haben auf unserer Website Kartenausschnitte des Online-Kartentools „OpenStreetMap“ eingebunden. Dabei handelt
      es sich um ein sogenanntes Open-Source-Mapping, welches wir über eine API (Schnittstelle) abrufen können.
      Angeboten wird diese Funktion von OpenStreetMap Foundation, St John’s Innovation Centre, Cowley Road, Cambridge,
      CB4 0WS, United Kingdom. Durch die Verwendung dieser Kartenfunktion wird Ihre IP-Adresse an OpenStreetMap
      weitergeleitet. In dieser Datenschutzerklärung erfahren Sie warum wir Funktionen des Tools OpenStreetMap
      verwenden, wo welche Daten gespeichert werden und wie Sie diese Datenspeicherung verhindern können.</p>
    <h3>Was ist OpenStreetMap?</h3>
    <p>Das Projekt OpenStreetMap wurde 2004 ins Leben gerufen. Ziel des Projekts ist und war es, eine freie Weltkarte zu
      erschaffen. User sammeln weltweit Daten etwa über Gebäude, Wälder, Flüsse und Straßen. So entstand über die Jahre
      eine umfangreiche, von Usern selbst erstellte digitale Weltkarte. Selbstverständlich ist die Karte, nicht
      vollständig, aber in den meisten Regionen mit sehr vielen Daten ausgestattet.</p>
    <h3>Warum verwenden wir OpenStreetMap auf unserer Website?</h3>
    <p>Unsere Website soll Ihnen in erster Linie hilfreich sein. Und das ist sie aus unserer Sicht immer dann, wenn man
      Information schnell und einfach findet. Da geht es natürlich einerseits um unsere Dienstleistungen und Produkte,
      andererseits sollen Ihnen auch weitere hilfreiche Informationen zur Verfügung stehen. Deshalb nutzen wir auch den
      Kartendienst OpenStreetMap. Denn so können wir Ihnen beispielsweise genau zeigen, wie Sie unsere Firma finden. Die
      Karte zeigt Ihnen den besten Weg zu uns und Ihre Anfahrt wird zum Kinderspiel.</p>
    <h3>Welche Daten werden von OpenStreetMap gespeichert?</h3>
    <p>Wenn Sie eine unserer Webseiten besuchen, die OpenStreetMap anbietet, werden Nutzerdaten an den Dienst
      übermittelt und dort gespeichert. OpenStreetMap sammelt etwa Informationen über Ihre Interaktionen mit der
      digitalen Karte, Ihre IP-Adresse, Daten zu Ihrem Browser, Gerätetyp, Betriebssystem und an welchem Tag und zu
      welcher Uhrzeit Sie den Dienst in Anspruch genommen haben. Dafür wird auch Tracking-Software zur Aufzeichnung von
      Userinteraktionen verwendet. Das Unternehmen gibt hier in der eigenen Datenschutzerklärung das Analysetool „Piwik“
      an.</p>
    <p>Die erhobenen Daten sind in Folge den entsprechenden Arbeitsgruppen der OpenStreetMap Foundation zugänglich. Laut
      dem Unternehmen werden persönliche Daten nicht an andere Personen oder Firmen weitergegeben, außer dies ist
      rechtlich notwendig. Der Drittanbieter Piwik speichert zwar Ihre IP-Adresse, allerdings in gekürzter Form.</p>
    <p>Folgendes Cookie kann in Ihrem Browser gesetzt werden, wenn Sie mit OpenStreetMap auf unserer Website
      interagieren:</p>
    <p><strong>Name:</strong> _osm_location<br/>
      <strong>Wert:</strong> 9.63312%7C52.41500%7C17%7CM<br/>
      <strong>Verwendungszweck:</strong> Das Cookie wird benötigt, um die Inhalte von
      OpenStreetMap zu entsperren.<br/>
      <strong>Ablaufdatum:</strong> nach 10 Jahren</p>
    <p>Wenn Sie sich das Vollbild der Karte ansehen wollen, werden Sie auf die OpenStreetMap-Website verlinkt. Dort
      können unter anderem folgende Cookies in Ihrem Browser gespeichert werden:</p>
    <p><strong>Name:</strong> _osm_totp_token<br/>
      <strong>Wert:</strong> 148253321244596-2<br/>
      <strong>Verwendungszweck:</strong> Dieses Cookie wird benutzt, um die Bedienung des
      Kartenausschnitts zu gewährleisten.<br/>
      <strong>Ablaufdatum:</strong> nach einer Stunde</p>
    <p><strong>Name:</strong> _osm_session<br/>
      <strong>Wert:</strong> 1d9bfa122e0259d5f6db4cb8ef653a1c<br/>
      <strong>Verwendungszweck:</strong> Mit Hilfe des Cookies können Sitzungsinformationen
      (also Userverhalten) gespeichert werden.<br/>
      <strong>Ablaufdatum:</strong> nach Sitzungsende</p>
    <p><strong>Name:</strong> _pk_id.1.cf09<br/>
      <strong>Wert:</strong> 4a5.1593684142.2.1593688396.1593688396321244596-9<br/>
      <strong>Verwendungszweck:</strong> Dieses Cookie wird von Piwik gesetzt, um Userdaten
      wie etwa das Klickverhalten zu speichern bzw. zu messen.<br/>
      <strong>Ablaufdatum:</strong> nach einem Jahr</p>
    <h3>Wie lange und wo werden die Daten gespeichert?</h3>
    <p>Die API-Server, die Datenbanken und die Server von Hilfsdiensten befinden sich derzeit im Vereinten Königreich
      (Großbritannien und Nordirland) und in den Niederlanden. Ihre IP-Adresse und Userinformationen, die in gekürzter
      Form durch das Webanalysetool Piwik gespeichert werden, werden nach 180 Tagen wieder gelöscht.</p>
    <h3>Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
    <p>Sie haben jederzeit das Recht auf Ihre personenbezogenen Daten zuzugreifen und Einspruch gegen die Nutzung und
      Verarbeitung zu erheben. Cookies, die von OpenStreetMap möglicherweise gesetzt werden, können Sie in Ihrem Browser
      jederzeit verwalten, löschen oder deaktivieren. Dadurch wird allerdings der Dienst nicht mehr im vollen Ausmaß
      funktionieren. Bei jedem Browser funktioniert die Verwaltung, Löschung oder Deaktivierung von Cookies etwas
      anders. Im Folgenden finden Sie Links zu den Anleitungen der bekanntesten Browser:</p>
    <p><a class="adsimple-321244596" href="https://support.google.com/chrome/answer/95647?tid=321244596" target="_blank"
          rel="noopener noreferrer">Chrome: Cookies in Chrome löschen, aktivieren und verwalten</a></p>
    <p><a class="adsimple-321244596" href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=321244596"
          target="_blank" rel="noopener noreferrer">Safari: Verwalten von Cookies und Websitedaten mit Safari</a></p>
    <p><a class="adsimple-321244596"
          href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=321244596"
          target="_blank" rel="noopener noreferrer">Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf
      Ihrem Computer abgelegt haben</a></p>
    <p><a class="adsimple-321244596"
          href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=321244596"
          target="_blank" rel="noopener noreferrer">Internet Explorer: Löschen und Verwalten von Cookies</a></p>
    <p><a class="adsimple-321244596"
          href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=321244596" target="_blank"
          rel="noopener noreferrer">Microsoft Edge: Löschen und Verwalten von Cookies</a></p>
    <p>Wenn Sie mehr über die Datenverarbeitung durch OpenStreetMap erfahren wollen, empfehlen wir Ihnen die
      Datenschutzerklärung des Unternehmens unter <a class="adsimple-321244596"
                                                     href="https://wiki.osmfoundation.org/wiki/Privacy_Policy?tid=321244596"
                                                     target="_blank" rel="noopener noreferrer">https://wiki.osmfoundation.org/wiki/Privacy_Policy.</a>
    </p>
    <h2>Mapbox API Datenschutzerklärung</h2>
    <p>Auf unserer Website nutzen wir die Mapbox API des amerikanischen Software-Unternehmens Mapbox Inc., 740 15th
      Street NW, 5th Floor, District of Columbia 20005, USA. Mapbox ist ein Online-Kartentool (Open-Source-Mapping), das
      über eine Schnittstelle (API) abgerufen wird. Durch die Nutzung dieses Tools wird unter anderem Ihre IP-Adresse an
      Mapbox weitergeleitet und gespeichert. In dieser Datenschutzerklärung erfahren Sie mehr über die Funktionen des
      Tools, warum wir es verwenden und vor allem welche Daten gespeichert werden und wie Sie das verhindern können.</p>
    <h3>Was ist Mapbox API?</h3>
    <p>Mapbox ist ein amerikanisches Software-Unternehmen, das benutzerdefinierte Online-Karten für Websites anbieten.
      Mit Mapbox kann man Inhalte auf unserer Website illustrieren oder beispielsweise Anfahrtswege grafisch darstellen.
      Die Karten können mit kleinen Code-Snippets (JavaScript-Code) sehr leicht in unsere Website eingebunden werden.
      Mapbox bietet unter anderem eine mobile-freundliche Umgebung, die Routenauskunft erfolgt in Echtzeit und Daten
      werden visualisiert dargestellt.</p>
    <h3>Warum verwenden wir Mapbox API auf unserer Website?</h3>
    <p>Wir wollen Ihnen auch auf unserer Website einen umfassenden Service bieten und dieser soll nicht einfach bei
      unseren Dienstleistungen oder Produkten enden. Nein, auch unsere gesamten Inhalte sollen Ihnen von Nutzen sein.
      Und dazu zählen zum Beispiel auch Anfahrtskarten, die Ihnen etwa den Weg zu unserem Unternehmen zeigen.</p>
    <h3>Welche Daten werden von Mapbox API gespeichert?</h3>
    <p>Wenn Sie eine unserer Unterseiten aufrufen, die eine Online-Karte von Mapbox eingebunden hat, können Daten über
      Ihr Nutzerverhalten gesammelt und gespeichert werden. Das muss sein, damit die eingebundenen Online-Karten
      einwandfrei funktionieren. Es kann auch sein, dass erhobene Daten durch Mapbox an Dritte weitergegeben werden,
      allerdings keine personenbezogenen Daten. Das geschieht entweder, wenn dies aus rechtlichen Gründen nötig ist oder
      wenn Mapbox ein anderes Unternehmen explizit beauftragt. Die Karteninhalte werden direkt an Ihren Browser
      übermittelt und in unsere Website eingebunden.</p>
    <p>Mapbox erfasst automatisch bestimmte technische Informationen, wenn Anfragen an die APIs gestellt werden. Dazu
      zählen neben Ihrer IP-Adresse etwa Browserinformationen, Ihr Betriebssystem, Inhalt der Anfrage, eingeschränkte
      Standorts- und Nutzungsdaten, die URL der besuchten Webseite und Datum und Uhrzeit des Websitebesuchs. Laut Mapbox
      werden die Daten nur zur Verbesserung der eigenen Produkte verwendet. Zudem sammelt Mapbox auch zufällig
      generierte IDs, um Nutzerverhalten zu analysieren und die Anzahl der aktiven User festzustellen.</p>
    <p>Wenn Sie eine unserer Unterseiten nutzen und mit einer Online-Karte interagieren, setzt Mapbox folgendes Cookie
      in Ihrem Browser:</p>
    <p><strong>Name:</strong> ppcbb-enable-content-mapbox_js<br/>
      <strong>Wert:</strong> 1605795587321244596-4<br/>
      <strong>Verwendungszweck:</strong> Genauere Informationen über den Verwendungszweck des
      Cookies konnten wir bis dato noch nicht in Erfahrung bringen.<br/>
      <strong>Ablaufdatum:</strong> nach einem Jahr</p>
    <p><strong>Anmerkung:</strong> Bei unseren Tests haben wir im Chrome-Browser kein Cookie
      gefunden, in anderen Browsern allerdings schon.</p>
    <h3>Wo und wie lange werden Daten gespeichert?</h3>
    <p>Die erhobenen Daten werden auf amerikanischen Servern des Unternehmens Mapbox gespeichert und verarbeitet. Ihre
      IP-Adresse wird aus Sicherheitsgründen für 30 Tage aufbewahrt und anschließend gelöscht. Zufällig generierte IDs
      (keine personenbezogenen Daten), die die Nutzung der APIs analysieren werden nach 36 Monaten wieder gelöscht.</p>
    <h3>Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
    <p>Wenn Sie nicht wollen, dass Mapbox Daten über Sie bzw. Ihr Userverhalten verarbeitet, können Sie in Ihren
      Browsereinstellungen JavaScript deaktivieren. Natürlich können Sie dann allerdings auch die entsprechenden
      Funktionen nicht mehr im vollen Ausmaß nutzen.</p>
    <p>Sie haben jederzeit das Recht auf Ihre personenbezogenen Daten zuzugreifen und Einspruch gegen die Nutzung und
      Verarbeitung zu erheben. Cookies, die von Mapbox API möglicherweise gesetzt werden, können Sie in Ihrem Browser
      jederzeit verwalten, löschen oder deaktivieren. Dadurch funktioniert allerdings der Dienst eventuell nicht mehr
      vollständig. Bei jedem Browser funktioniert die Verwaltung, Löschung oder Deaktivierung von Cookies etwas anders.
      Im Folgenden finden Sie Links zu den Anleitungen der bekanntesten Browser:</p>
    <p><a class="adsimple-321244596" href="https://support.google.com/chrome/answer/95647?tid=321244596" target="_blank"
          rel="noopener noreferrer">Chrome: Cookies in Chrome löschen, aktivieren und verwalten</a></p>
    <p><a class="adsimple-321244596" href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=321244596"
          target="_blank" rel="noopener noreferrer">Safari: Verwalten von Cookies und Websitedaten mit Safari</a></p>
    <p><a class="adsimple-321244596"
          href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=321244596"
          target="_blank" rel="noopener noreferrer">Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf
      Ihrem Computer abgelegt haben</a></p>
    <p><a class="adsimple-321244596"
          href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=321244596"
          target="_blank" rel="noopener noreferrer">Internet Explorer: Löschen und Verwalten von Cookies</a></p>
    <p><a class="adsimple-321244596"
          href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=321244596" target="_blank"
          rel="noopener noreferrer">Microsoft Edge: Löschen und Verwalten von Cookies</a></p>
    <p>Wenn Sie mehr über die Datenverarbeitung durch Mapbox erfahren wollen, empfehlen wir Ihnen die
      Datenschutzerklärung des Unternehmens unter <a class="adsimple-321244596"
                                                     href="https://www.mapbox.com/legal/privacy?tid=321244596"
                                                     target="_blank" rel="noopener noreferrer">https://www.mapbox.com/legal/privacy</a>.
    </p>
    <h2>Google Analytics Datenschutzerklärung</h2>
    <p>Wir verwenden auf unserer Website das Analyse-Tracking Tool Google Analytics (GA) des amerikanischen Unternehmens
      Google Inc. Für den europäischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street
      Dublin 4, Irland) für alle Google-Dienste verantwortlich. Google Analytics sammelt Daten über Ihre Handlungen auf
      unserer Website. Wenn Sie beispielsweise einen Link anklicken, wird diese Aktion in einem Cookie gespeichert und
      an Google Analytics versandt. Mithilfe der Berichte, die wir von Google Analytics erhalten, können wir unsere
      Website und unser Service besser an Ihre Wünsche anpassen. Im Folgenden gehen wir näher auf das Tracking Tool ein
      und informieren Sie vor allem darüber, welche Daten gespeichert werden und wie Sie das verhindern können.</p>
    <h3>Was ist Google Analytics?</h3>
    <p>Google Analytics ist ein Trackingtool, das der Datenverkehrsanalyse unserer Website dient. Damit Google Analytics
      funktioniert, wird ein Tracking-Code in den Code unserer Website eingebaut. Wenn Sie unsere Website besuchen,
      zeichnet dieser Code verschiedene Handlungen auf, die Sie auf unserer Website ausführen. Sobald Sie unsere Website
      verlassen, werden diese Daten an die Google-Analytics-Server gesendet und dort gespeichert.</p>
    <p>Google verarbeitet die Daten und wir bekommen Berichte über Ihr Userverhalten. Dabei kann es sich unter anderem
      um folgende Berichte handeln:</p>
    <ul>
      <li>Zielgruppenberichte: Über Zielgruppenberichte lernen wir unsere User besser kennen
        und wissen genauer, wer sich für unser Service interessiert.
      </li>
      <li>Anzeigeberichte: Durch Anzeigeberichte können wir unsere Onlinewerbung leichter
        analysieren und verbessern.
      </li>
      <li>Akquisitionsberichte: Akquisitionsberichte geben uns hilfreiche Informationen
        darüber, wie wir mehr Menschen für unser Service begeistern können.
      </li>
      <li>Verhaltensberichte: Hier erfahren wir, wie Sie mit unserer Website interagieren.
        Wir können nachvollziehen welchen Weg Sie auf unserer Seite zurücklegen und welche Links Sie anklicken.
      </li>
      <li>Conversionsberichte: Conversion nennt man einen Vorgang, bei dem Sie aufgrund einer
        Marketing-Botschaft eine gewünschte Handlung ausführen. Zum Beispiel, wenn Sie von einem reinen Websitebesucher
        zu einem Käufer oder Newsletter-Abonnent werden. Mithilfe dieser Berichte erfahren wir mehr darüber, wie unsere
        Marketing-Maßnahmen bei Ihnen ankommen. So wollen wir unsere Conversionrate steigern.
      </li>
      <li>Echtzeitberichte: Hier erfahren wir immer sofort, was gerade auf unserer Website
        passiert. Zum Beispiel sehen wir wie viele User gerade diesen Text lesen.
      </li>
    </ul>
    <h3>Warum verwenden wir Google Analytics auf unserer Webseite?</h3>
    <p>Unser Ziel mit dieser Website ist klar: Wir wollen Ihnen das bestmögliche Service bieten. Die Statistiken und
      Daten von Google Analytics helfen uns dieses Ziel zu erreichen.</p>
    <p>Die statistisch ausgewerteten Daten zeigen uns ein klares Bild von den Stärken und Schwächen unserer Website.
      Einerseits können wir unsere Seite so optimieren, dass sie von interessierten Menschen auf Google leichter
      gefunden wird. Andererseits helfen uns die Daten, Sie als Besucher besser zu verstehen. Wir wissen somit sehr
      genau, was wir an unserer Website verbessern müssen, um Ihnen das bestmögliche Service zu bieten. Die Daten dienen
      uns auch, unsere Werbe- und Marketing-Maßnahmen individueller und kostengünstiger durchzuführen. Schließlich macht
      es nur Sinn, unsere Produkte und Dienstleistungen Menschen zu zeigen, die sich dafür interessieren.</p>
    <h3>Welche Daten werden von Google Analytics gespeichert?</h3>
    <p>Google Analytics erstellt mithilfe eines Tracking-Codes eine zufällige, eindeutige ID, die mit Ihrem
      Browser-Cookie verbunden ist. So erkennt Sie Google Analytics als neuen User. Wenn Sie das nächste Mal unsere
      Seite besuchen, werden Sie als „wiederkehrender“ User erkannt. Alle gesammelten Daten werden gemeinsam mit dieser
      User-ID gespeichert. So ist es überhaupt erst möglich pseudonyme Userprofile auszuwerten.</p>
    <p>Durch Kennzeichnungen wie Cookies und App-Instanz-IDs werden Ihre Interaktionen auf unserer Website gemessen.
      Interaktionen sind alle Arten von Handlungen, die Sie auf unserer Website ausführen. Wenn Sie auch andere
      Google-Systeme (wie z.B. ein Google-Konto) nützen, können über Google Analytics generierte Daten mit
      Drittanbieter-Cookies verknüpft werden. Google gibt keine Google Analytics-Daten weiter, außer wir als
      Websitebetreiber genehmigen das. Zu Ausnahmen kann es kommen, wenn es gesetzlich erforderlich ist.</p>
    <p>Folgende Cookies werden von Google Analytics verwendet:</p>
    <p><strong>Name:</strong> _ga<br/>
      <strong>Wert: </strong>2.1326744211.152321244596-5<br/>
      <strong>Verwendungszweck:</strong> Standardmäßig verwendet analytics.js das Cookie _ga,
      um die User-ID zu speichern. Grundsätzlich dient es zur Unterscheidung der Webseitenbesucher.<br/>
      <strong>Ablaufdatum:</strong> nach 2 Jahren</p>
    <p><strong>Name:</strong> _gid<br/>
      <strong>Wert: </strong>2.1687193234.152321244596-1<br/>
      <strong>Verwendungszweck:</strong> Das Cookie dient auch zur Unterscheidung der
      Webseitenbesucher.<br/>
      <strong>Ablaufdatum:</strong> nach 24 Stunden</p>
    <p><strong>Name:</strong> _gat_gtag_UA_&lt;property-id&gt;<br/>
      <strong>Wert:</strong> 1<br/>
      <strong>Verwendungszweck:</strong> Wird zum Senken der Anforderungsrate verwendet. Wenn
      Google Analytics über den Google Tag Manager bereitgestellt wird, erhält dieser Cookie den Namen _dc_gtm_ &lt;property-id&gt;.<br/>
      <strong>Ablaufdatum: </strong>nach 1 Minute</p>
    <p><strong>Name:</strong> AMP_TOKEN<br/>
      <strong>Wert:</strong> keine Angaben<br/>
      <strong>Verwendungszweck:</strong> Das Cookie hat einen Token, mit dem eine User ID vom
      AMP-Client-ID-Dienst abgerufen werden kann. Andere mögliche Werte weisen auf eine Abmeldung, eine Anfrage oder
      einen Fehler hin.<br/>
      <strong>Ablaufdatum:</strong> nach 30 Sekunden bis zu einem Jahr</p>
    <p><strong>Name:</strong> __utma<br/>
      <strong>Wert: </strong>1564498958.1564498958.1564498958.1<br/>
      <strong>Verwendungszweck:</strong> Mit diesem Cookie kann man Ihr Verhalten auf der
      Website verfolgen und die Leistung messen. Das Cookie wird jedes Mal aktualisiert, wenn Informationen an Google
      Analytics gesendet werden.<br/>
      <strong>Ablaufdatum:</strong> nach 2 Jahren</p>
    <p><strong>Name:</strong> __utmt<br/>
      <strong>Wert:</strong> 1<br/>
      <strong>Verwendungszweck:</strong> Das Cookie wird wie _gat_gtag_UA_&lt;property-id&gt;
      zum Drosseln der Anforderungsrate verwendet.<br/>
      <strong>Ablaufdatum:</strong> nach 10 Minuten</p>
    <p><strong>Name:</strong> __utmb<br/>
      <strong>Wert: </strong>3.10.1564498958<br/>
      <strong>Verwendungszweck:</strong> Dieses Cookie wird verwendet, um neue Sitzungen zu
      bestimmen. Es wird jedes Mal aktualisiert, wenn neue Daten bzw. Infos an Google Analytics gesendet werden.<br/>
      <strong>Ablaufdatum:</strong> nach 30 Minuten</p>
    <p><strong>Name:</strong> __utmc<br/>
      <strong>Wert:</strong> 167421564<br/>
      <strong>Verwendungszweck:</strong> Dieses Cookie wird verwendet, um neue Sitzungen für
      wiederkehrende Besucher festzulegen. Dabei handelt es sich um ein Session-Cookie, und es wird nur solange
      gespeichert, bis Sie den Browser wieder schließen.<br/>
      <strong>Ablaufdatum:</strong> Nach Schließung des Browsers</p>
    <p><strong>Name:</strong> __utmz<br/>
      <strong>Wert:</strong> m|utmccn=(referral)|utmcmd=referral|utmcct=/<br/>
      <strong>Verwendungszweck:</strong> Das Cookie wird verwendet, um die Quelle des
      Besucheraufkommens auf unserer Website zu identifizieren. Das heißt, das Cookie speichert, von wo Sie auf unsere
      Website gekommen sind. Das kann eine andere Seite bzw. eine Werbeschaltung gewesen sein.<br/>
      <strong>Ablaufdatum:</strong> nach 6 Monaten</p>
    <p><strong>Name:</strong> __utmv<br/>
      <strong>Wert:</strong> keine Angabe<br/>
      <strong>Verwendungszweck:</strong> Das Cookie wird verwendet, um benutzerdefinierte
      Userdaten zu speichern. Es wird immer aktualisiert, wenn Informationen an Google Analytics gesendet werden.<br/>
      <strong>Ablaufdatum:</strong> nach 2 Jahren</p>
    <p><strong>Anmerkung:</strong> Diese Aufzählung kann keinen Anspruch auf Vollständigkeit
      erheben, da Google die Wahl seiner Cookies immer wieder verändert.</p>
    <p>Hier zeigen wir Ihnen einen Überblick über die wichtigsten Daten, die mit Google Analytics erhoben werden:</p>
    <p><strong>Heatmaps:</strong> Google legt sogenannte Heatmaps an. Über Heatmaps sieht man
      genau jene Bereiche, die Sie anklicken. So bekommen wir Informationen, wo Sie auf unserer Seite „unterwegs“ sind.
    </p>
    <p><strong>Sitzungsdauer:</strong> Als Sitzungsdauer bezeichnet Google die Zeit, die Sie
      auf unserer Seite verbringen, ohne die Seite zu verlassen. Wenn Sie 20 Minuten inaktiv waren, endet die Sitzung
      automatisch.</p>
    <p><strong>Absprungrate</strong> (engl. Bouncerate): Von einem Absprung ist die Rede,
      wenn Sie auf unserer Website nur eine Seite ansehen und dann unsere Website wieder verlassen.</p>
    <p><strong>Kontoerstellung:</strong> Wenn Sie auf unserer Website ein Konto erstellen
      bzw. eine Bestellung machen, erhebt Google Analytics diese Daten.</p>
    <p><strong>IP-Adresse:</strong> Die IP-Adresse wird nur in gekürzter Form dargestellt,
      damit keine eindeutige Zuordnung möglich ist.</p>
    <p><strong>Standort:</strong> Über die IP-Adresse kann das Land und Ihr ungefährer
      Standort bestimmt werden. Diesen Vorgang bezeichnet man auch als IP- Standortbestimmung.</p>
    <p><strong>Technische Informationen:</strong> Zu den technischen Informationen zählen
      unter anderem Ihr Browsertyp, Ihr Internetanbieter oder Ihre Bildschirmauflösung.</p>
    <p><strong>Herkunftsquelle:</strong> Google Analytics beziehungsweise uns, interessiert
      natürlich auch über welche Website oder welche Werbung Sie auf unsere Seite gekommen sind.</p>
    <p>Weitere Daten sind Kontaktdaten, etwaige Bewertungen, das Abspielen von Medien (z.B., wenn Sie ein Video über
      unsere Seite abspielen), das Teilen von Inhalten über Social Media oder das Hinzufügen zu Ihren Favoriten. Die
      Aufzählung hat keinen Vollständigkeitsanspruch und dient nur zu einer allgemeinen Orientierung der
      Datenspeicherung durch Google Analytics.</p>
    <h3>Wie lange und wo werden die Daten gespeichert?</h3>
    <p>Google hat Ihre Server auf der ganzen Welt verteilt. Die meisten Server befinden sich in Amerika und folglich
      werden Ihre Daten meist auf amerikanischen Servern gespeichert. Hier können Sie genau nachlesen wo sich die
      Google-Rechenzentren befinden: <a class="adsimple-321244596"
                                        href="https://www.google.com/about/datacenters/inside/locations/?hl=de"
                                        target="_blank" rel="noopener noreferrer">https://www.google.com/about/datacenters/inside/locations/?hl=de</a>
    </p>
    <p>Ihre Daten werden auf verschiedenen physischen Datenträgern verteilt. Das hat den Vorteil, dass die Daten
      schneller abrufbar sind und vor Manipulation besser geschützt sind. In jedem Google-Rechenzentrum gibt es
      entsprechende Notfallprogramme für Ihre Daten. Wenn beispielsweise die Hardware bei Google ausfällt oder
      Naturkatastrophen Server lahmlegen, bleibt das Risiko einer Dienstunterbrechung bei Google dennoch gering.</p>
    <p>Standardisiert ist bei Google Analytics eine Aufbewahrungsdauer Ihrer Userdaten von 26 Monaten eingestellt. Dann
      werden Ihre Userdaten gelöscht. Allerdings haben wir die Möglichkeit, die Aufbewahrungsdauer von Nutzdaten selbst
      zu wählen. Dafür stehen uns fünf Varianten zur Verfügung:</p>
    <ul>
      <li>Löschung nach 14 Monaten</li>
      <li>Löschung nach 26 Monaten</li>
      <li>Löschung nach 38 Monaten</li>
      <li>Löschung nach 50 Monaten</li>
      <li>Keine automatische Löschung</li>
    </ul>
    <p>Wenn der festgelegte Zeitraum abgelaufen ist, werden einmal im Monat die Daten gelöscht. Diese Aufbewahrungsdauer
      gilt für Ihre Daten, die mit Cookies, Usererkennung und Werbe-IDs (z.B. Cookies der DoubleClick-Domain) verknüpft
      sind. Berichtergebnisse basieren auf aggregierten Daten und werden unabhängig von Nutzerdaten gespeichert.
      Aggregierte Daten sind eine Zusammenschmelzung von Einzeldaten zu einer größeren Einheit.</p>
    <h3>Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
    <p>Nach dem Datenschutzrecht der Europäischen Union haben Sie das Recht, Auskunft über Ihre Daten zu erhalten, sie
      zu aktualisieren, zu löschen oder einzuschränken. Mithilfe des Browser-Add-ons zur Deaktivierung von Google
      Analytics-JavaScript (ga.js, analytics.js, dc.js) verhindern Sie, dass Google Analytics Ihre Daten verwendet. Das
      Browser-Add-on können Sie unter <a class="adsimple-321244596"
                                         href="https://tools.google.com/dlpage/gaoptout?hl=de" target="_blank"
                                         rel="noopener noreferrer">https://tools.google.com/dlpage/gaoptout?hl=de</a>
      runterladen und installieren. Beachten Sie bitte, dass durch dieses Add-on nur die Datenerhebung durch Google
      Analytics deaktiviert wird.</p>
    <p>Falls Sie grundsätzlich Cookies (unabhängig von Google Analytics) deaktivieren, löschen oder verwalten wollen,
      gibt es für jeden Browser eine eigene Anleitung:</p>
    <p><a class="adsimple-321244596" href="https://support.google.com/chrome/answer/95647?tid=321244596" target="_blank"
          rel="noopener noreferrer">Chrome: Cookies in Chrome löschen, aktivieren und verwalten</a></p>
    <p><a class="adsimple-321244596" href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=321244596"
          target="_blank" rel="noopener noreferrer">Safari: Verwalten von Cookies und Websitedaten mit Safari</a></p>
    <p><a class="adsimple-321244596"
          href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=321244596"
          target="_blank" rel="noopener noreferrer">Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf
      Ihrem Computer abgelegt haben</a></p>
    <p><a class="adsimple-321244596"
          href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=321244596"
          target="_blank" rel="noopener noreferrer">Internet Explorer: Löschen und Verwalten von Cookies</a></p>
    <p><a class="adsimple-321244596"
          href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=321244596" target="_blank"
          rel="noopener noreferrer">Microsoft Edge: Löschen und Verwalten von Cookies</a></p>
    <p>Google Analytics ist aktiver Teilnehmer beim EU-U.S. Privacy Shield Framework, wodurch der korrekte und sichere
      Datentransfer persönlicher Daten geregelt wird. Mehr Informationen dazu finden Sie auf <a
          class="adsimple-321244596" href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI"
          target="_blank" rel="follow noopener noreferrer">https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;tid=321244596</a>.
      Wir hoffen wir konnten Ihnen die wichtigsten Informationen rund um die Datenverarbeitung von Google Analytics
      näherbringen. Wenn Sie mehr über den Tracking-Dienst erfahren wollen, empfehlen wir diese beiden Links: <a
          class="adsimple-321244596" href="http://www.google.com/analytics/terms/de.html" target="_blank"
          rel="noopener noreferrer">http://www.google.com/analytics/terms/de.html</a> und <a class="adsimple-321244596"
                                                                                             href="https://support.google.com/analytics/answer/6004245?hl=de"
                                                                                             target="_blank"
                                                                                             rel="noopener noreferrer">https://support.google.com/analytics/answer/6004245?hl=de</a>.
    </p>
    <h2>Google Analytics IP-Anonymisierung</h2>
    <p>Wir haben auf dieser Webseite die IP-Adressen-Anonymisierung von Google Analytics implementiert. Diese Funktion
      wurde von Google entwickelt, damit diese Webseite die geltenden Datenschutzbestimmungen und Empfehlungen der
      lokalen Datenschutzbehörden einhalten kann, wenn diese eine Speicherung der vollständigen IP-Adresse untersagen.
      Die Anonymisierung bzw. Maskierung der IP findet statt, sobald die IP-Adressen im Google
      Analytics-Datenerfassungsnetzwerk eintreffen und bevor eine Speicherung oder Verarbeitung der Daten
      stattfindet.</p>
    <p>Mehr Informationen zur IP-Anonymisierung finden Sie auf <a class="adsimple-321244596"
                                                                  href="https://support.google.com/analytics/answer/2763052?hl=de"
                                                                  target="_blank" rel="noopener">https://support.google.com/analytics/answer/2763052?hl=de</a>.
    </p>
    <h2>Google Analytics Berichte zu demografischen Merkmalen und Interessen</h2>
    <p>Wir haben in Google Analytics die Funktionen für Werbeberichte eingeschaltet. Die Berichte zu demografischen
      Merkmalen und Interessen enthalten Angaben zu Alter, Geschlecht und Interessen. Damit können wir uns – ohne diese
      Daten einzelnen Personen zuordnen zu können – ein besseres Bild von unseren Nutzern machen. Mehr über die
      Werbefunktionen erfahren Sie <a class="adsimple-321244596"
                                      href="https://support.google.com/analytics/answer/3450482?hl=de_AT&amp;utm_id=ad"
                                      target="_blank" rel="noopener">auf
        https://support.google.com/analytics/answer/3450482?hl=de_AT&amp;utm_id=ad</a>.</p>
    <p>Sie können die Nutzung der Aktivitäten und Informationen Ihres Google Kontos unter “Einstellungen für Werbung”
      auf <a>https://adssettings.google.com/authenticated</a>
      per Checkbox beenden.</p>
    <h2>Google Analytics Deaktivierungslink</h2>
    <p>Wenn Sie auf folgenden <strong>Deaktivierungslink</strong> klicken, können Sie
      verhindern, dass Google weitere Besuche auf dieser Webseite erfasst. Achtung: Das Löschen von Cookies, die Nutzung
      des Inkognito/Privatmodus Ihres Browsers, oder die Nutzung eines anderen Browsers führt dazu, dass wieder Daten
      erhoben werden.</p>
    <p><a>Google Analytics deaktivieren</a></p>
    <h2>Google Analytics Zusatz zur Datenverarbeitung</h2>
    <p>Wir haben mit Google einen Direktkundenvertrag zur Verwendung von Google Analytics abgeschlossen, indem wir den
      “Zusatz zur Datenverarbeitung” in Google Analytics akzeptiert haben.</p>
    <p>Mehr über den Zusatz zur Datenverarbeitung für Google Analytics finden Sie hier: <a class="adsimple-321244596"
                                                                                           href="https://support.google.com/analytics/answer/3379636?hl=de&amp;utm_id=ad"
                                                                                           target="_blank"
                                                                                           rel="noopener">https://support.google.com/analytics/answer/3379636?hl=de&amp;utm_id=ad</a>
    </p>
    <h2>Google Analytics Google-Signale Datenschutzerklärung</h2>
    <p>Wir haben in Google Analytics die Google-Signale aktiviert. So werden die bestehenden Google-Analytics-Funktionen
      (Werbeberichte, Remarketing, gerätübergreifende Berichte und Berichte zu Interessen und demografische Merkmale)
      aktualisiert, um zusammengefasste und anonymisierte Daten von Ihnen zu erhalten, sofern Sie personalisierte
      Anzeigen in Ihrem Google-Konto erlaubt haben.</p>
    <p>Das besondere daran ist, dass es sich dabei um ein Cross-Device-Tracking handelt. Das heißt Ihre Daten können
      geräteübergreifend analysiert werden. Durch die Aktivierung von Google-Signale werden Daten erfasst und mit dem
      Google-Konto verknüpft. Google kann dadurch zum Beispiel erkennen, wenn Sie auf unsere Webseite über ein
      Smartphone ein Produkt ansehen und erst später über einen Laptop das Produkt kaufen. Dank der Aktivierung von
      Google-Signale können wir gerätübergreifende Remarketing-Kampagnen starten, die sonst in dieser Form nicht möglich
      wären. Remarketing bedeutet, dass wir Ihnen auch auf anderen Webseiten unser Angebot zeigen können.</p>
    <p>In Google Analytics werden zudem durch die Google-Signale weitere Besucherdaten wie Standort, Suchverlauf,
      YouTube-Verlauf und Daten über Ihre Handlungen auf unserer Webseite, erfasst. Wir erhalten dadurch von Google
      bessere Werbeberichte und nützlichere Angaben zu Ihren Interessen und demografischen Merkmalen. Dazu gehören Ihr
      Alter, welche Sprache sie sprechen, wo Sie wohnen oder welchem Geschlecht Sie angehören. Weiters kommen auch noch
      soziale Kriterien wie Ihr Beruf, Ihr Familienstand oder Ihr Einkommen hinzu. All diese Merkmal helfen Google
      Analytics Personengruppen bzw. Zielgruppen zu definieren.</p>
    <p>Die Berichte helfen uns auch Ihr Verhalten, Ihre Wünsche und Interessen besser einschätzen zu können. Dadurch
      können wir unsere Dienstleistungen und Produkte für Sie optimieren und anpassen. Diese Daten laufen standardmäßig
      nach 26 Monaten ab. Bitte beachten Sie, dass diese Datenerfassung nur erfolgt, wenn Sie personalisierte Werbung in
      Ihrem Google-Konto zugelassen haben. Es handelt sich dabei immer um zusammengefasste und anonyme Daten und nie um
      Daten einzelner Personen. In Ihrem Google-Konto können Sie diese Daten verwalten bzw. auch löschen.</p>
    <h2>Amazon-Partnerprogramm Datenschutzerklärung</h2>
    <p>Wir verwenden auf unserer Website das Amazon-Partnerprogramm des Unternehmens Amazon.com, Inc. Die
      verantwortlichen Stellen im Sinne der Datenschutzerklärung sind die Amazon Europe Core S.à.r.l., die Amazon EU
      S.à.r.l, die Amazon Services Europe S.à.r.l. und die Amazon Media EU S.à.r.l., alle vier ansässig 5, Rue Plaetis,
      L-2338 Luxemburg sowie Amazon Instant Video Germany GmbH, Domagkstr. 28, 80807 München. Als Datenverarbeiter wird
      die Amazon Deutschland Services GmbH, Marcel-Breuer-Str. 12, 80807 München, tätig. Durch die Verwendung dieses
      Amazon-Partnerprogramms können Daten von Ihnen an Amazon übertragen, gespeichert und verarbeitet werden.</p>
    <p>In dieser Datenschutzerklärung informieren wir Sie um welche Daten es sich handelt, warum wir das Programm
      verwenden und wie Sie die Datenübertragung verwalten bzw. unterbinden können.</p>
    <h3>Was ist das Amazon-Partnerprogramm?</h3>
    <p>Das Amazon-Partnerprogramm ist ein Affiliate-Marketing-Programm des Online-Versandunternehmens <a
        class="adsimple-321244596"
        href="https://www.amazon.de/ref=as_li_ss_tl?site-redirect=at&amp;linkCode=ll2&amp;tag=thetraffic-21&amp;linkId=16a65fb03b8cb39206283c5345d87944&amp;language=de_DE&amp;tid=321244596"
        target="_blank" rel="follow noopener noreferrer">Amazon.de</a>. Wie jedes Affiliate-Programm basiert auch das
      Amazon-Partnerprogramm auf dem Prinzip der Vermittlungsprovision. Amazon bzw. wir platzieren auf unserer Website
      Werbung oder Partnerlinks und wenn Sie darauf klicken und ein Produkt über Amazon kaufen, erhalten wir eine
      Werbekostenerstattung (Provision).</p>
    <h3>Warum verwenden wir das Amazon-Partnerprogramm auf unserer Webseite?</h3>
    <p>Unser Ziel ist es Ihnen eine angenehme Zeit mit vielen hilfreichen Inhalten zu liefern. Dafür stecken wir sehr
      viel Arbeit und Energie in die Entwicklung unserer Website. Mit Hilfe des Amazon-Partnerprogramms haben wir die
      Möglichkeit, für unsere Arbeit auch ein bisschen entlohnt zu werden. Jeder Partnerlink zu Amazon hat
      selbstverständlich immer mit unserem Thema zu tun und zeigt Angebote, die Sie interessieren könnten.</p>
    <h3>Welche Daten werden durch das Amazon-Partnerprogramm gespeichert?</h3>
    <p>Sobald Sie mit den Produkten und Dienstleistungen von Amazon interagieren, erhebt Amazon Daten von Ihnen. Amazon
      unterscheidet zwischen Informationen, die Sie aktiv dem Unternehmen geben und Informationen, die automatisch
      gesammelt und gespeichert werden. Zu den “aktiven Informationen” zählen zum Beispiel Name, E-Mail-Adresse,
      Telefonnummer, Alter, Zahlungsinformationen oder Standortinformationen. Sogenannte „automatische Informationen“
      werden in erster Linie über Cookies gespeichert. Dazu zählen Informationen zum Userverhalten, IP-Adresse,
      Gerätinformationen (Browsertyp, Standort, Betriebssysteme) oder die URL. Amazon speichert weiters auch den
      Clickstream. Damit ist der Weg (Reihenfolge der Seiten) gemeint, den Sie als User zurücklegen, um zu einem Produkt
      zu kommen. Auch um die Herkunft einer Bestellung nachvollziehen zu können, speichert Amazon Cookies in Ihrem
      Browser. So erkennt das Unternehmen, dass Sie über unsere Website eine Amazon-Werbeanzeige oder einen Partnerlink
      angeklickt haben.</p>
    <p>Wenn Sie ein Amazon-Konto haben und angemeldet sind, während Sie auf unserer Webseite surfen, können die
      erhobenen Daten Ihrem Account zugewiesen werden. Das verhindern Sie, indem Sie sich bei Amazon abmelden, bevor Sie
      auf unserer Website surfen.</p>
    <p>Hier zeigen wir Ihnen beispielhafte Cookies, die in Ihrem Browser gesetzt werden, wenn Sie auf unserer Website
      auf einen Amazon-Link kicken.</p>
    <p><strong>Name</strong>: uid<br/>
      <strong>Wert: </strong>3230928052675285215321244596-9<br/>
      <strong>Verwendungszweck: </strong>Dieses Cookie speichert eine eindeutige User-ID und
      sammelt Informationen über Ihre Websiteaktivität.<br/>
      <strong>Ablaufdatum:</strong> nach 2 Monaten</p>
    <p><strong>Name</strong>: ad-id<br/>
      <strong>Wert: </strong>AyDaInRV1k-Lk59xSnp7h5o<br/>
      <strong>Verwendungszweck: </strong>Dieses Cookie wird von amazon-adsystem.com zur
      Verfügung gestellt und dient dem Unternehmen für verschiedene Werbezwecke.<br/>
      <strong>Ablaufdatum:</strong> nach 8 Monaten</p>
    <p><strong>Name</strong>: uuid2<br/>
      <strong>Wert: </strong>8965834524520213028321244596-2<br/>
      <strong>Verwendungszweck: </strong>Dieses Cookie ermöglicht gezielte und
      interessensbezogene Werbung über die AppNexus-Plattform. Das Cookie sammelt und speichert über die IP-Adresse
      beispielsweise anonyme Daten darüber, welche Werbung Sie angeklickt haben und welche Seiten Sie aufgerufen
      haben.<br/>
      <strong>Ablaufdatum:</strong> nach 3 Monaten</p>
    <p><strong>Name</strong>: session-id<br/>
      <strong>Wert: </strong>262-0272718-2582202321244596-1<br/>
      <strong>Verwendungszweck: </strong>Dieses Cookie speichert eine eindeutige User-ID, die
      der Server Ihnen für die Dauer eines Websitebesuchs (Session) zuweist. Besuchen Sie dieselbe Seite wieder, werden
      die darin gespeichert Information wieder abgerufen.<br/>
      <strong>Ablaufdatum:</strong> nach 15 Jahren</p>
    <p><strong>Name</strong>: APID<br/>
      <strong>Wert: </strong>UP9801199c-4bee-11ea-931d-02e8e13f0574<br/>
      <strong>Verwendungszweck: </strong>Dieses Cookie speichert Informationen darüber, wie
      Sie eine Website nutzen und welche Werbung Sie vor dem Websitebesuch angesehen haben.<br/>
      <strong>Ablaufdatum:</strong> nach einem Jahr</p>
    <p><strong>Name</strong>: session-id-time<br/>
      <strong>Wert: </strong>tb:s-STNY7ZS65H5335FZEVPE|1581329862486&amp;t:1581329864300&amp;adb:adblk_no<br/>
      <strong>Verwendungszweck: </strong>Dieses Cookie erfasst die Zeit, die Sie mit einer
      eindeutigen Cookie-ID auf einer Webseite verbringen.<br/>
      <strong>Ablaufdatum:</strong> nach 2 Jahren</p>
    <p><strong>Name</strong>: csm-hit<br/>
      <strong>Wert: </strong>2082754801l<br/>
      <strong>Verwendungszweck: </strong>Wir könnten über dieses Cookie keine genauen
      Informationen in Erfahrung bringen.<br/>
      <strong>Ablaufdatum:</strong> nach 15 Jahren</p>
    <p><strong>Anmerkung: </strong>Bitte beachten Sie, dass diese Liste lediglich
      Cookie-Beispiele zeigt und keinen Anspruch auf Vollständigkeit erheben kann.</p>
    <p>Amazon nutzt diese erhaltenen Informationen, um Werbeanzeigen genauer an die Interessen der User abzustimmen.</p>
    <h3>Wie lange und wo werden die Daten gespeichert?</h3>
    <p>Persönliche Daten werden von Amazon so lange gespeichert, wie es für die geschäftlichen Dienste von Amazon
      erforderlich ist oder aus rechtlichen Gründen notwendig ist. Da das Unternehmen Amazon seinen Hauptsitz in den USA
      hat, werden die gesammelten Daten auch auf amerikanischen Servern gespeichert.</p>
    <h3>Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
    <p>Sie haben jederzeit das Recht auf Ihre personenbezogenen Daten zuzugreifen und sie auch zu löschen. Wenn Sie
      einen Amazon-Account besitzen, können Sie in Ihrem Account viele der erhobenen Daten verwalten oder löschen.</p>
    <p>Eine weitere Option, die Datenverarbeitung und -speicherung durch Amazon nach Ihren Vorlieben zu verwalten,
      bietet Ihr Browser. Dort können Sie Cookies verwalten, deaktivieren oder löschen. Dies funktioniert bei jedem
      Browser ein bisschen anders. Hier finden Sie die Anleitungen zu den gängigsten Browsern:</p>
    <p><a class="adsimple-321244596" href="https://support.google.com/chrome/answer/95647?tid=321244596" target="_blank"
          rel="noopener noreferrer">Chrome: Cookies in Chrome löschen, aktivieren und verwalten</a></p>
    <p><a class="adsimple-321244596" href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=321244596"
          target="_blank" rel="noopener noreferrer">Safari: Verwalten von Cookies und Websitedaten mit Safari</a></p>
    <p><a class="adsimple-321244596"
          href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=321244596"
          target="_blank" rel="noopener noreferrer">Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf
      Ihrem Computer abgelegt haben</a></p>
    <p><a class="adsimple-321244596"
          href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=321244596"
          target="_blank" rel="noopener noreferrer">Internet Explorer: Löschen und Verwalten von Cookies</a></p>
    <p><a class="adsimple-321244596"
          href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=321244596" target="_blank"
          rel="noopener noreferrer">Microsoft Edge: Löschen und Verwalten von Cookies</a></p>
    <p>Amazon ist aktiver Teilnehmer des EU-U.S. Privacy Shield Frameworks, wodurch der korrekte Datentransfer
      persönlicher Daten zwischen den USA und der EU geregelt wird. Mehr Informationen dazu finden Sie auf <a
          class="adsimple-321244596"
          href="https://www.privacyshield.gov/participant?id=a2zt0000000TOWQAA4&amp;tid=321244596" target="_blank"
          rel="noopener noreferrer">https://www.privacyshield.gov/participant?id=a2zt0000000TOWQAA4</a>. Wir hoffen wir
      haben Ihnen die wichtigsten Informationen über die Datenübertragung durch die Verwendung von dem
      Amazon-Partnerprogramm nähergebracht. Mehr Informationen finden Sie unter <a class="adsimple-321244596"
                                                                                   href="https://www.amazon.de/gp/help/customer/display.html?nodeId=201909010"
                                                                                   target="_blank"
                                                                                   rel="noopener noreferrer">https://www.amazon.de/gp/help/customer/display.html?nodeId=201909010</a>.
    </p>
    <h2>Google Ads (Google AdWords) Conversion-Tracking Datenschutzerklärung</h2>
    <p>Wir verwenden als Online-Marketing-Maßnahme Google Ads (früher Google AdWords), um unsere Produkte und
      Dienstleistungen zu bewerben. So wollen wir im Internet mehr Menschen auf die hohe Qualität unserer Angebote
      aufmerksam machen. Im Rahmen unserer Werbe-Maßnahmen durch Google Ads verwenden wir auf unserer Website das
      Conversion-Tracking der Firma Google Inc. In Europa ist allerdings für alle Google-Dienste das Unternehmen Google
      Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) verantwortlich. Mithilfe dieses kostenlosen
      Tracking-Tools können wir unser Werbeangebot an Ihre Interessen und Bedürfnisse besser anpassen. Im Folgenden
      Artikel wollen wir genauer darauf eingehen, warum wir Conversion-Tracking benutzen, welche Daten dabei gespeichert
      werden und wie Sie diese Datenspeicherung verhindern.</p>
    <h3>Was ist Google Ads Conversion-Tracking?</h3>
    <p>Google Ads (früher Google AdWords) ist das hauseigene Online-Werbesystem der Firma Google Inc. Wir sind von der
      Qualität unseres Angebots überzeugt und wollen, dass so viele Menschen wie möglich unsere Webseite kennenlernen.
      Im Onlinebereich bietet Google Ads dafür die beste Plattform. Natürlich wollen wir auch einen genauen Überblick
      über den Kosten-Nutzen-Faktor unsere Werbeaktionen gewinnen. Darum verwenden wir das Conversion-Tracking-Tool von
      Google Ads.</p>
    <p>Doch was ist eine Conversion eigentlich? Eine Conversion entsteht, wenn Sie von einem rein interessierten
      Webseitenbesucher zu einem handelnden Besucher werden. Dies passiert immer dann, wenn Sie auf unsere Anzeige
      klicken und im Anschluss eine andere Aktion ausführen, wie zum Beispiel unsere Webseite besuchen. Mit dem
      Conversion-Tracking-Tool von Google erfassen wir, was nach einem Klick eines Users auf unsere Google Ads-Anzeige
      geschieht. Zum Beispiel können wir so sehen, ob Produkte gekauft werden, Dienstleistungen in Anspruch genommen
      werden oder ob sich User für unseren Newsletter angemeldet haben.</p>
    <h3>Warum verwenden wir Google Ads Conversion-Tracking auf unserer Webseite?</h3>
    <p>Wir setzen Google Ads ein, um auch auf anderen Webseiten auf unser Angebot aufmerksam zu machen. Ziel ist es,
      dass unsere Werbekampagnen wirklich auch nur jene Menschen erreichen, die sich für unsere Angebote interessieren.
      Mit dem Conversion-Tracking Tool sehen wir welche Keywords, Anzeigen, Anzeigengruppen und Kampagnen zu den
      gewünschten Kundenaktionen führen. Wir sehen wie viele Kunden mit unseren Anzeigen auf einem Gerät interagieren
      und dann eine Conversion durchführen. Durch diese Daten können wir unseren Kosten-Nutzen-Faktor berechnen, den
      Erfolg einzelner Werbemaßnahmen messen und folglich unsere Online-Marketing-Maßnahmen optimieren. Wir können
      weiters mithilfe der gewonnenen Daten unsere Webseite für Sie interessanter gestalten und unser Werbeangebot noch
      individueller an Ihre Bedürfnisse anpassen.</p>
    <h3>Welche Daten werden bei Google Ads Conversion-Tracking gespeichert?</h3>
    <p>Wir haben ein Conversion-Tracking-Tag oder Code-Snippet auf unserer Webseite eingebunden, um gewisse
      User-Aktionen besser analysieren zu können. Wenn Sie nun eine unserer Google Ads-Anzeigen anklicken, wird auf
      Ihrem Computer (meist im Browser) oder Mobilgerät das Cookie „Conversion“ von einer Google-Domain gespeichert.
      Cookies sind kleine Textdateien, die Informationen auf Ihrem Computer speichern.</p>
    <p>Hier die Daten der wichtigsten Cookies für das Conversion-Tracking von Google:</p>
    <p><strong>Name:</strong> Conversion<br/>
      <strong>Wert:</strong> EhMI_aySuoyv4gIVled3Ch0llweVGAEgt-mr6aXd7dYlSAGQ321244596-3<br/>
      <strong>Verwendungszweck: </strong>Dieses Cookie speichert jede Conversion, die Sie auf
      unserer Seite machen, nachdem Sie über eine Google Ad zu uns gekommen sind.<br/>
      <strong>Ablaufdatum:</strong> nach 3 Monaten</p>
    <p><strong>Name:</strong> _gac<br/>
      <strong>Wert:</strong>
      1.1558695989.EAIaIQobChMIiOmEgYO04gIVj5AYCh2CBAPrEAAYASAAEgIYQfD_BwE<br/>
      <strong>Verwendungszweck:</strong> Dies ist ein klassisches Google Analytics-Cookie und
      dient dem Erfassen verschiedener Handlungen auf unserer Webseite.<br/>
      <strong>Ablaufdatum:</strong> nach 3 Monaten</p>
    <p><strong>Anmerkung:</strong> Das Cookie _gac scheint nur in Verbindung mit Google
      Analytics auf. Die oben angeführte Aufzählung hat keinen Anspruch auf Vollständigkeit, da Google für analytische
      Auswertung immer wieder auch andere Cookies verwendet.</p>
    <p>Sobald Sie eine Aktion auf unserer Webseite abschließen, erkennt Google das Cookie und speichert Ihre Handlung
      als sogenannte Conversion. Solange Sie auf unserer Webseite surfen und das Cookie noch nicht abgelaufen ist,
      erkennen wir und Google, dass Sie über unsere Google-Ads-Anzeige zu uns gefunden haben. Das Cookie wird ausgelesen
      und mit den Conversion-Daten zurück an Google Ads gesendet. Es ist auch möglich, dass noch andere Cookies zur
      Messung von Conversions verwendet werden. Das Conversion-Tracking von Google Ads kann mithilfe von Google
      Analytics noch verfeinert und verbessert werden. Bei Anzeigen, die Google an verschiedenen Orten im Web anzeigt,
      werden unter unserer Domain möglicherweise Cookies mit dem Namen “__gads” oder “_gac” gesetzt. Seit September 2017
      werden diverse Kampagneninformationen von analytics.js mit dem _gac-Cookie gespeichert. Das Cookie speichert diese
      Daten, sobald Sie eine unserer Seiten aufrufen, für die die automatische Tag-Kennzeichnung von Google Ads
      eingerichtet wurde. Im Gegensatz zu Cookies, die für Google-Domains gesetzt werden, kann Google diese
      Conversion-Cookies nur lesen, wenn Sie sich auf unserer Webseite befinden. Wir erheben und erhalten keine
      personenbezogenen Daten. Wir bekommen von Google einen Bericht mit statistischen Auswertungen. So erfahren wir
      beispielsweise die Gesamtanzahl der User, die unsere Anzeige angeklickt haben und wir sehen, welche Werbemaßnahmen
      gut ankamen.</p>
    <h3>Wie lange und wo werden die Daten gespeichert?</h3>
    <p>An dieser Stelle wollen wir darauf hinweisen, dass wir keinen Einfluss darauf haben, wie Google die erhobenen
      Daten weiterverwendet. Laut Google werden die Daten verschlüsselt und auf sicheren Servern gespeichert. In den
      meisten Fällen laufen Conversion-Cookies nach 30 Tagen ab und übermitteln keine personenbezogenen Daten. Die
      Cookies mit dem Namen „Conversion“ und „_gac“ (das in Verbindung mit Google Analytics zum Einsatz kommt) haben ein
      Ablaufdatum von 3 Monaten.</p>
    <h3>Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
    <p>Sie haben die Möglichkeit am Conversion-Tracking von Google Ads nicht teilzunehmen. Wenn Sie das Cookie des
      Google Conversion-Tracking über Ihren Browser deaktivieren, blockieren Sie das Conversion-Tracking. In diesem Fall
      werden Sie in der Statistik des Tracking-Tools nicht berücksichtigt. Sie können die Cookie-Einstellungen in Ihrem
      Browser jederzeit verändern. Bei jedem Browser funktioniert dies etwas anders. Hier finden Sie die Anleitung, wie
      Sie Cookies in Ihrem Browser verwalten:</p>
    <p><a class="adsimple-321244596" href="https://support.google.com/chrome/answer/95647?tid=321244596" target="_blank"
          rel="noopener noreferrer">Chrome: Cookies in Chrome löschen, aktivieren und verwalten</a></p>
    <p><a class="adsimple-321244596" href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=321244596"
          target="_blank" rel="noopener noreferrer">Safari: Verwalten von Cookies und Websitedaten mit Safari</a></p>
    <p><a class="adsimple-321244596"
          href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=321244596"
          target="_blank" rel="noopener noreferrer">Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf
      Ihrem Computer abgelegt haben</a></p>
    <p><a class="adsimple-321244596"
          href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=321244596"
          target="_blank" rel="noopener noreferrer">Internet Explorer: Löschen und Verwalten von Cookies</a></p>
    <p><a class="adsimple-321244596"
          href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=321244596" target="_blank"
          rel="noopener noreferrer">Microsoft Edge: Löschen und Verwalten von Cookies</a></p>
    <p>Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser so einrichten, dass er Sie immer
      informiert, wenn ein Cookie gesetzt werden soll. So können Sie bei jedem einzelnen Cookie entscheiden, ob Sie das
      Cookie erlauben oder nicht. Durch das Herunterladen und Installieren dieses Browser-Plug-ins auf <a
          class="adsimple-321244596" href="https://support.google.com/ads/answer/7395996" target="_blank"
          rel="noopener noreferrer">https://support.google.com/ads/answer/7395996</a> werden ebenfalls alle
      „Werbecookies“ deaktiviert. Bedenken Sie, dass Sie durch das Deaktivieren dieser Cookies nicht die Werbeanzeigen
      verhindern, sondern nur die personalisierte Werbung.</p>
    <p>Durch die Zertifizierung für das amerikanische-europäische Datenschutzübereinkommen “Privacy Shield”, muss der
      amerikanische Konzern Google LLC die in der EU geltenden Datenschutzgesetze einhalten. Wenn Sie Näheres über den
      Datenschutz bei Google erfahren möchten, empfehlen wir die allgemeine Datenschutzerklärung von Google: <a
          class="adsimple-321244596" href="https://policies.google.com/privacy?hl=de" target="_blank"
          rel="noopener noreferrer">https://policies.google.com/privacy?hl=de</a>.</p>
    <h2>WP Statistics Datenschutzerklärung</h2>
    <p>Wir verwenden auf unserer Website das Analyse-Plugin WP Statistics. Entwickelt wurde dieses Plugin von Veronalabs
      (5460 W Main St, Verona, NY 13478, Vereinigte Staaten), einem amerikanischen Software-Unternehmen. Mit diesem
      Plugin erhalten wir einfache Statistiken, wie Sie als User unsere Website nutzen. In dieser Datenschutzerklärung
      gehen wir näher auf das Analyse-Tool ein und zeigen Ihnen, welche Daten wo und wie lange gespeichert werden.</p>
    <h3>Was ist WP Statistics?</h3>
    <p>Bei diesem Plugin handelt es sich um eine Analyse-Software, die speziell für Websites entwickelt wurde, die das
      Content-Management-System WordPress verwenden. WordPress hilft uns, unsere Website auch ohne Programmierkenntnisse
      leicht zu bearbeiten. WP Statistics kann etwa Daten darüber sammeln, wie lange Sie auf unserer Website verweilen,
      welche Unterseiten Sie besuchen, wie viele Besucher auf der Website sind oder von welcher Website Sie zu uns
      gekommen sind. Es werden durch WP Statistics keine Cookies gesetzt und Sie können als Person durch die erhobenen
      Daten nicht identifiziert werden.</p>
    <h3>Warum verwenden wir WP Statistics?</h3>
    <p>Mithilfe von WP Statistics erhalten wir einfache Statistiken, die uns dabei helfen, unsere Website für Sie noch
      interessanter und besser zu machen. Unsere Website und die darauf angebotenen Inhalte, Produkte und/oder
      Dienstleistungen sollen Ihren Anforderungen und Wünschen so gut wie möglich entsprechen. Um dieses Ziel zu
      erreichen, müssen wir natürlich auch in Erfahrung bringen, wo wir Verbesserungen und Änderungen vornehmen sollen.
      Die erhaltenen Statistiken, helfen uns dabei, diesem Ziel einen Schritt näher zu kommen.</p>
    <h3>Welche Daten werden von WP Statistics gespeichert?</h3>
    <p>WP Statistics setzt keine Cookies und durch die erhobenen Daten werden nur in anonymisierter Form Statistiken
      über die Verwendung unserer Website erstellt. WP Statistics anonymisiert auch Ihre IP-Adresse. Sie als Person
      können nicht identifiziert werden.</p>
    <p>Durch WP Statistics werden Besucher-Daten (sogenannte Visitos´Data) erhoben, wenn sich Ihr Webbrowser mit unserem
      Webserver verbindet. Diese Daten werden in unserer Datenbank auf unserem Server gespeichert. Dazu zählen
      beispielsweise:</p>
    <ul>
      <li>die Adresse (URL) der aufgerufenen Webseite</li>
      <li>Browser und Browserversion</li>
      <li>das verwendete Betriebssystem</li>
      <li>die Adresse (URL) der zuvor besuchten Seite (Referrer URL)</li>
      <li>den Hostname und die IP-Adresse des Geräts von welchem aus zugegriffen wird</li>
      <li>Datum und Uhrzeit</li>
      <li>Informationen zu Land/Stadt</li>
      <li>Anzahl der Besucher, die von einer Suchmaschine kommen</li>
      <li>Dauer des Webseitenaufenthalts</li>
      <li>Klicks auf der Website</li>
    </ul>
    <p>Die Daten werden nicht weitergegeben und auch nicht verkauft.</p>
    <h3>Wie lange und wo werden die Daten gespeichert?</h3>
    <p>Alle Daten werden lokal auf unserem Webserver gespeichert. Die Daten werden solange auf unserem Webserver
      gespeichert, bis Sie für die oben angeführten Zwecke nicht mehr benötigt werden.</p>
    <h3>Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
    <p>Sie haben jederzeit das Recht auf Auskunft, Berichtigung bzw. Löschung und Einschränkung der Verarbeitung Ihrer
      personenbezogenen Daten. Sie können zudem auch jederzeit die Einwilligung zur Verarbeitung von Daten
      widerrufen.</p>
    <p>Wir haben Ihnen nun die wichtigsten Informationen zur Datenverarbeitung durch WP Analytics mitgeteilt. Dadurch,
      dass das Plugin keine Cookies verwendet und die Daten zur statistischen Auswertung lokal im Webserver gespeichert
      werden, wird hier mit Ihren Daten sehr sorgsam umgegangen. Wenn Sie mehr über WP Analytics erfahren wollen,
      sollten Sie sich die Datenschutzerklärung des Unternehmens unter <a class="adsimple-321244596"
                                                                          href="https://wp-statistics.com/privacy-and-policy/?tid=321244596">https://wp-statistics.com/privacy-and-policy/</a>
      ansehen.</p>
    <h2>Eingebettete Social Media Elemente Datenschutzerklärung</h2>
    <p>Wir binden auf unserer Webseite Elemente von Social Media Diensten ein um Bilder, Videos und Texte
      anzuzeigen.<br/>
      Durch den Besuch von Seiten die diese Elemente darstellen, werden Daten von Ihrem Browser zum jeweiligen Social
      Media Dienst übertragen und dort gespeichert. Wir haben keinen Zugriff auf diese Daten.<br/>
      Die folgenden Links führen Sie zu den Seiten der jeweiligen Social Media Dienste wo erklärt wird, wie diese mit
      Ihren Daten umgehen:</p>
    <ul>
      <li>Instagram-Datenschutzrichtlinie: <a class="adsimple-321244596"
                                                                         href="https://help.instagram.com/519522125107875?tid=321244596"
                                                                         target="_blank" rel="noopener">https://help.instagram.com/519522125107875</a>
      </li>
      <li>Für YouTube gilt die Google Datenschutzerklärung: <a class="adsimple-321244596"
                                                                                          href="https://policies.google.com/privacy?hl=de&amp;tid=321244596"
                                                                                          target="_blank"
                                                                                          rel="noopener">https://policies.google.com/privacy?hl=de</a>
      </li>
      <li>Facebook-Datenrichtline: <a class="adsimple-321244596"
                                                                 href="https://www.facebook.com/about/privacy?tid=321244596"
                                                                 target="_blank" rel="noopener">https://www.facebook.com/about/privacy</a>
      </li>
      <li>Twitter Datenschutzrichtlinie: <a class="adsimple-321244596"
                                                                       href="https://twitter.com/de/privacy?tid=321244596"
                                                                       target="_blank" rel="noopener">https://twitter.com/de/privacy</a>
      </li>
    </ul>
    <h2>Instagram Datenschutzerklärung</h2>
    <p>Wir haben auf unserer Webseite Funktionen von Instagram eingebaut. Instagram ist eine Social Media Plattform des
      Unternehmens Instagram LLC, 1601 Willow Rd, Menlo Park CA 94025, USA. Instagram ist seit 2012 ein
      Tochterunternehmen von Facebook Inc. und gehört zu den Facebook-Produkten. Das Einbetten von Instagram-Inhalten
      auf unserer Webseite nennt man Embedding. Dadurch können wir Ihnen Inhalte wie Buttons, Fotos oder Videos von
      Instagram direkt auf unserer Webseite zeigen. Wenn Sie Webseiten unserer Webpräsenz aufrufen, die eine
      Instagram-Funktion integriert haben, werden Daten an Instagram übermittelt, gespeichert und verarbeitet. Instagram
      verwendet dieselben Systeme und Technologien wie Facebook. Ihre Daten werden somit über alle Facebook-Firmen
      hinweg verarbeitet.</p>
    <p>Im Folgenden wollen wir Ihnen einen genaueren Einblick geben, warum Instagram Daten sammelt, um welche Daten es
      sich handelt und wie Sie die Datenverarbeitung weitgehend kontrollieren können. Da Instagram zu Facebook Inc.
      gehört, beziehen wir unsere Informationen einerseits von den Instagram-Richtlinien, andererseits allerdings auch
      von den Facebook-Datenrichtlinien selbst.</p>
    <h3>Was ist Instagram?</h3>
    <p>Instagram ist eines der bekanntesten Social Media Netzwerken weltweit. Instagram kombiniert die Vorteile eines
      Blogs mit den Vorteilen von audiovisuellen Plattformen wie YouTube oder Vimeo. Sie können auf „Insta“ (wie viele
      der User die Plattform salopp nennen) Fotos und kurze Videos hochladen, mit verschiedenen Filtern bearbeiten und
      auch in anderen sozialen Netzwerken verbreiten. Und wenn Sie selbst nicht aktiv sein wollen, können Sie auch nur
      anderen interessante Users folgen.</p>
    <h3>Warum verwenden wir Instagram auf unserer Webseite?</h3>
    <p>Instagram ist jene Social Media Plattform, die in den letzten Jahren so richtig durch die Decke ging. Und
      natürlich haben auch wir auf diesen Boom reagiert. Wir wollen, dass Sie sich auf unserer Webseite so wohl wie
      möglich fühlen. Darum ist für uns eine abwechslungsreiche Aufbereitung unserer Inhalte selbstverständlich. Durch
      die eingebetteten Instagram-Funktionen können wir unseren Content mit hilfreichen, lustigen oder spannenden
      Inhalten aus der Instagram-Welt bereichern. Da Instagram eine Tochtergesellschaft von Facebook ist, können uns die
      erhobenen Daten auch für personalisierte Werbung auf Facebook dienlich sein. So bekommen unsere Werbeanzeigen nur
      Menschen, die sich wirklich für unsere Produkte oder Dienstleistungen interessieren.</p>
    <p>Instagram nützt die gesammelten Daten auch zu Messungs- und Analysezwecken. Wir bekommen zusammengefasste
      Statistiken und so mehr Einblick über Ihre Wünsche und Interessen. Wichtig ist zu erwähnen, dass diese Berichte
      Sie nicht persönlich identifizieren.</p>
    <h3>Welche Daten werden von Instagram gespeichert?</h3>
    <p>Wenn Sie auf eine unserer Seiten stoßen, die Instagram-Funktionen (wie Instagrambilder oder Plug-ins) eingebaut
      haben, setzt sich Ihr Browser automatisch mit den Servern von Instagram in Verbindung. Dabei werden Daten an
      Instagram versandt, gespeichert und verarbeitet. Und zwar unabhängig, ob Sie ein Instagram-Konto haben oder nicht.
      Dazu zählen Informationen über unserer Webseite, über Ihren Computer, über getätigte Käufe, über Werbeanzeigen,
      die Sie sehen und wie Sie unser Angebot nutzen. Weiters werden auch Datum und Uhrzeit Ihrer Interaktion mit
      Instagram gespeichert. Wenn Sie ein Instagram-Konto haben bzw. eingeloggt sind, speichert Instagram deutlich mehr
      Daten über Sie.</p>
    <p>Facebook unterscheidet zwischen Kundendaten und Eventdaten. Wir gehen davon aus, dass dies bei Instagram genauso
      der Fall ist. Kundendaten sind zum Beispiel Name, Adresse, Telefonnummer und IP-Adresse. Wichtig zu erwähnen ist,
      dass diese Kundendaten erst an Instagram übermittelt werden, wenn Sie zuvor „gehasht“ wurden. Hashing meint, ein
      Datensatz wird in eine Zeichenkette verwandelt. Dadurch kann man die Kontaktdaten verschlüsseln. Zudem werden auch
      die oben genannten „Event-Daten“ übermittelt. Unter „Event-Daten“ versteht Facebook – und folglich auch Instagram
      – Daten über Ihr Userverhalten. Es kann auch vorkommen, dass Kontaktdaten mit Event-Daten kombiniert werden. Die
      erhobenen Kontaktdaten werden mit den Daten, die Instagram bereits von Ihnen hat abgeglichen.</p>
    <p>Über kleine Text-Dateien (Cookies), die meist in Ihrem Browser gesetzt werden, werden die gesammelten Daten an
      Facebook übermittelt. Je nach verwendeten Instagram-Funktionen und ob Sie selbst ein Instagram-Konto haben, werden
      unterschiedlich viele Daten gespeichert.</p>
    <p>Wir gehen davon aus, dass bei Instagram die Datenverarbeitung gleich funktioniert wie bei Facebook. Das bedeutet:
      wenn Sie ein Instagram-Konto haben oder <a class="adsimple-321244596"
                                                 href="http://www.instagram.com?tid=321244596" target="_blank"
                                                 rel="noopener">www.instagram.com</a> besucht haben, hat Instagram
      zumindest ein Cookie gesetzt. Wenn das der Fall ist, sendet Ihr Browser über das Cookie Infos an Instagram, sobald
      Sie mit einer Instagram-Funktion in Berührung kommen. Spätestens nach 90 Tagen (nach Abgleichung) werden diese
      Daten wieder gelöscht bzw. anonymisiert. Obwohl wir uns intensiv mit der Datenverarbeitung von Instagram
      beschäftigt haben, können wir nicht ganz genau sagen, welche Daten Instagram exakt sammelt und speichert.</p>
    <p>Im Folgenden zeigen wir Ihnen Cookies, die in Ihrem Browser mindestens gesetzt werden, wenn Sie auf eine
      Instagram-Funktion (wie z.B. Button oder ein Insta-Bild) klicken. Bei unserem Test gehen wir davon aus, dass Sie
      kein Instagram-Konto haben. Wenn Sie bei Instagram eingeloggt sind, werden natürlich deutlich mehr Cookies in
      Ihrem Browser gesetzt.</p>
    <p>Diese Cookies wurden bei unserem Test verwendet:</p>
    <p><strong>Name: </strong>csrftoken<br/>
      <strong>Wert: </strong>“”<br/>
      <strong>Verwendungszweck: </strong>Dieses Cookie wird mit hoher Wahrscheinlichkeit aus
      Sicherheitsgründen gesetzt, um Fälschungen von Anfragen zu verhindern. Genauer konnten wir das allerdings nicht in
      Erfahrung bringen.<br/>
      <strong>Ablaufdatum:</strong> nach einem Jahr</p>
    <p><strong>Name: </strong>mid<br/>
      <strong>Wert: </strong>“”<br/>
      <strong>Verwendungszweck: </strong>Instagram setzt dieses Cookie, um die eigenen
      Dienstleistungen und Angebote in und außerhalb von Instagram zu optimieren. Das Cookie legt eine eindeutige
      User-ID fest.<br/>
      <strong>Ablaufdatum:</strong> nach Ende der Sitzung</p>
    <p><strong>Name:</strong> fbsr_321244596124024<br/>
      <strong>Wert: </strong>keine Angaben<br/>
      <strong>Verwendungszweck: </strong>Dieses Cookie speichert die Log-in-Anfrage für User
      der Instagram-App.<strong><br/>
      </strong> <strong>Ablaufdatum:</strong> nach Ende der Sitzung</p>
    <p><strong>Name:</strong> rur<br/>
      <strong>Wert: </strong>ATN<br/>
      <strong>Verwendungszweck: </strong>Dabei handelt es sich um ein Instagram-Cookie, das
      die Funktionalität auf Instagram gewährleistet.<br/>
      <strong>Ablaufdatum:</strong> nach Ende der Sitzung</p>
    <p><strong>Name:</strong> urlgen<br/>
      <strong>Wert: </strong>“{\”194.96.75.33\”: 1901}:1iEtYv:Y833k2_UjKvXgYe321244596”<br/>
      <strong>Verwendungszweck: </strong>Dieses Cookie dient den Marketingzwecken von
      Instagram.<br/>
      <strong>Ablaufdatum:</strong> nach Ende der Sitzung</p>
    <p><strong>Anmerkung:</strong> Wir können hier keinen Vollständigkeitsanspruch erheben.
      Welche Cookies im individuellen Fall gesetzt werden, hängt von den eingebetteten Funktionen und Ihrer Verwendung
      von Instagram ab.</p>
    <h3>Wie lange und wo werden die Daten gespeichert?</h3>
    <p>Instagram teilt die erhaltenen Informationen zwischen den Facebook-Unternehmen mit externen Partnern und mit
      Personen, mit denen Sie sich weltweit verbinden. Die Datenverarbeitung erfolgt unter Einhaltung der eigenen
      Datenrichtlinie. Ihre Daten sind, unter anderem aus Sicherheitsgründen, auf den Facebook-Servern auf der ganzen
      Welt verteilt. Die meisten dieser Server stehen in den USA.</p>
    <h3>Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
    <p>Dank der Datenschutz Grundverordnung haben Sie das Recht auf Auskunft, Übertragbarkeit, Berichtigung und Löschung
      Ihrer Daten. In den Instagram-Einstellungen können Sie Ihre Daten verwalten. Wenn Sie Ihre Daten auf Instagram
      völlig löschen wollen, müssen Sie Ihr Instagram-Konto dauerhaft löschen.</p>
    <p>Und so funktioniert die Löschung des Instagram-Kontos:</p>
    <p>Öffnen Sie zuerst die Instagram-App. Auf Ihrer Profilseite gehen Sie nach unten und klicken Sie auf
      „Hilfebereich“. Jetzt kommen Sie auf die Webseite des Unternehmens. Klicken Sie auf der Webseite auf „Verwalten
      des Kontos“ und dann auf „Dein Konto löschen“.</p>
    <p>Wenn Sie Ihr Konto ganz löschen, löscht Instagram Posts wie beispielsweise Ihre Fotos und Status-Updates.
      Informationen, die andere Personen über Sie geteilt haben, gehören nicht zu Ihrem Konto und werden folglich nicht
      gelöscht.</p>
    <p>Wie bereits oben erwähnt, speichert Instagram Ihre Daten in erster Linie über Cookies. Diese Cookies können Sie
      in Ihrem Browser verwalten, deaktivieren oder löschen. Abhängig von Ihrem Browser funktioniert die Verwaltung
      immer ein bisschen anders. Hier zeigen wir Ihnen die Anleitungen der wichtigsten Browser.</p>
    <p><a class="adsimple-321244596" href="https://support.google.com/chrome/answer/95647?tid=321244596" target="_blank"
          rel="noopener">Chrome: Cookies in Chrome löschen, aktivieren und verwalten</a></p>
    <p><a class="adsimple-321244596" href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=321244596"
          target="_blank" rel="noopener">Safari: Verwalten von Cookies und Websitedaten mit Safari</a></p>
    <p><a class="adsimple-321244596"
          href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=321244596"
          target="_blank" rel="noopener">Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem
      Computer abgelegt haben</a></p>
    <p><a class="adsimple-321244596"
          href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=321244596"
          target="_blank" rel="noopener">Internet Explorer: Löschen und Verwalten von Cookies</a></p>
    <p><a class="adsimple-321244596"
          href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=321244596" target="_blank"
          rel="noopener">Microsoft Edge: Löschen und Verwalten von Cookies</a></p>
    <p>Sie können auch grundsätzlich Ihren Browser so einrichten, dass Sie immer informiert werden, wenn ein Cookie
      gesetzt werden soll. Dann können Sie immer individuell entscheiden, ob Sie das Cookie zulassen wollen oder
      nicht.</p>
    <p>Instagram ist ein Tochterunternehmen von Facebook Inc. und Facebook ist aktiver Teilnehmer beim EU-U.S. Privacy
      Shield Framework. Dieses Framework stellt eine korrekte Datenübertragung zwischen den USA und der Europäischen
      Union sicher. Unter <a class="adsimple-321244596"
                             href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC" target="_blank"
                             rel="noopener">https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC </a>
      erfahren Sie mehr darüber. Wir haben versucht, Ihnen die wichtigsten Informationen über die Datenverarbeitung
      durch Instagram näherzubringen. Auf <a class="adsimple-321244596"
                                             href="https://help.instagram.com/519522125107875" target="_blank"
                                             rel="noopener">https://help.instagram.com/519522125107875</a><br/>
      können Sie sich noch näher mit den Datenrichtlinien von Instagram auseinandersetzen.</p>
    <h2>YouTube Datenschutzerklärung</h2>
    <p>Wir haben auf unserer Website YouTube-Videos eingebaut. So können wir Ihnen interessante Videos direkt auf
      unserer Seite präsentieren. YouTube ist ein Videoportal, das seit 2006 eine Tochterfirma von Google ist. Betrieben
      wird das Videoportal durch YouTube, LLC, 901 Cherry Ave., San Bruno, CA 94066, USA. Wenn Sie auf unserer Website
      eine Seite aufrufen, die ein YouTube-Video eingebettet hat, verbindet sich Ihr Browser automatisch mit den Servern
      von YouTube bzw. Google. Dabei werden (je nach Einstellungen) verschiedene Daten übertragen. Für die gesamte
      Datenverarbeitung im europäischen Raum ist Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland)
      verantwortlich.</p>
    <p>Im Folgenden wollen wir Ihnen genauer erklären, welche Daten verarbeitet werden, warum wir YouTube-Videos
      eingebunden haben und wie Sie Ihre Daten verwalten oder löschen können.</p>
    <h3>Was ist YouTube?</h3>
    <p>Auf YouTube können die User kostenlos Videos ansehen, bewerten, kommentieren und selbst hochladen. Über die
      letzten Jahre wurde YouTube zu einem der wichtigsten Social-Media-Kanäle weltweit. Damit wir Videos auf unserer
      Webseite anzeigen können, stellt YouTube einen Codeausschnitt zur Verfügung, den wir auf unserer Seite eingebaut
      haben.</p>
    <h3>Warum verwenden wir YouTube-Videos auf unserer Webseite?</h3>
    <p>YouTube ist die Videoplattform mit den meisten Besuchern und dem besten Content. Wir sind bemüht, Ihnen die
      bestmögliche User-Erfahrung auf unserer Webseite zu bieten. Und natürlich dürfen interessante Videos dabei nicht
      fehlen. Mithilfe unserer eingebetteten Videos stellen wir Ihnen neben unseren Texten und Bildern weiteren
      hilfreichen Content zur Verfügung. Zudem wird unsere Webseite auf der Google-Suchmaschine durch die eingebetteten
      Videos leichter gefunden. Auch wenn wir über Google Ads Werbeanzeigen schalten, kann Google – dank der gesammelten
      Daten – diese Anzeigen wirklich nur Menschen zeigen, die sich für unsere Angebote interessieren.</p>
    <h3>Welche Daten werden von YouTube gespeichert?</h3>
    <p>Sobald Sie eine unserer Seiten besuchen, die ein YouTube-Video eingebaut hat, setzt YouTube zumindest ein Cookie,
      das Ihre IP-Adresse und unsere URL speichert. Wenn Sie in Ihrem YouTube-Konto eingeloggt sind, kann YouTube Ihre
      Interaktionen auf unserer Webseite meist mithilfe von Cookies Ihrem Profil zuordnen. Dazu zählen Daten wie
      Sitzungsdauer, Absprungrate, ungefährer Standort, technische Informationen wie Browsertyp, Bildschirmauflösung
      oder Ihr Internetanbieter. Weitere Daten können Kontaktdaten, etwaige Bewertungen, das Teilen von Inhalten über
      Social Media oder das Hinzufügen zu Ihren Favoriten auf YouTube sein.</p>
    <p>Wenn Sie nicht in einem Google-Konto oder einem Youtube-Konto angemeldet sind, speichert Google Daten mit einer
      eindeutigen Kennung, die mit Ihrem Gerät, Browser oder App verknüpft sind. So bleibt beispielsweise Ihre
      bevorzugte Spracheinstellung beibehalten. Aber viele Interaktionsdaten können nicht gespeichert werden, da weniger
      Cookies gesetzt werden.</p>
    <p>In der folgenden Liste zeigen wir Cookies, die in einem Test im Browser gesetzt wurden. Wir zeigen einerseits
      Cookies, die ohne angemeldeten YouTube-Konto gesetzt werden. Andererseits zeigen wir Cookies, die mit angemeldetem
      Account gesetzt werden. Die Liste kann keinen Vollständigkeitsanspruch erheben, weil die Userdaten immer von den
      Interaktionen auf YouTube abhängen.</p>
    <p><strong>Name:</strong> YSC<br/>
      <strong>Wert:</strong> b9-CV6ojI5Y321244596-1<br/>
      <strong>Verwendungszweck:</strong> Dieses Cookie registriert eine eindeutige ID, um
      Statistiken des gesehenen Videos zu speichern.<br/>
      <strong>Ablaufdatum:</strong> nach Sitzungsende</p>
    <p><strong>Name:</strong> PREF<br/>
      <strong>Wert:</strong> f1=50000000<br/>
      <strong>Verwendungszweck:</strong> Dieses Cookie registriert ebenfalls Ihre eindeutige
      ID. Google bekommt über PREF Statistiken, wie Sie YouTube-Videos auf unserer Webseite verwenden.<br/>
      <strong>Ablaufdatum:</strong> nach 8 Monaten</p>
    <p><strong>Name:</strong> GPS<br/>
      <strong>Wert:</strong> 1<br/>
      <strong>Verwendungszweck:</strong> Dieses Cookie registriert Ihre eindeutige ID auf
      mobilen Geräten, um den GPS-Standort zu tracken.<br/>
      <strong>Ablaufdatum:</strong> nach 30 Minuten</p>
    <p><strong>Name:</strong> VISITOR_INFO1_LIVE<br/>
      <strong>Wert:</strong> 95Chz8bagyU<br/>
      <strong>Verwendungszweck:</strong> Dieses Cookie versucht die Bandbreite des Users auf
      unseren Webseiten (mit eingebautem YouTube-Video) zu schätzen.<br/>
      <strong>Ablaufdatum:</strong> nach 8 Monaten</p>
    <p>Weitere Cookies, die gesetzt werden, wenn Sie mit Ihrem YouTube-Konto angemeldet sind:</p>
    <p><strong>Name:</strong> APISID<br/>
      <strong>Wert:</strong> zILlvClZSkqGsSwI/AU1aZI6HY7321244596-<br/>
      <strong>Verwendungszweck:</strong> Dieses Cookie wird verwendet, um ein Profil über
      Ihre Interessen zu erstellen. Genützt werden die Daten für personalisierte Werbeanzeigen.<br/>
      <strong>Ablaufdatum:</strong> nach 2 Jahren</p>
    <p><strong>Name:</strong> CONSENT<br/>
      <strong>Wert:</strong> YES+AT.de+20150628-20-0<br/>
      <strong>Verwendungszweck:</strong> Das Cookie speichert den Status der Zustimmung eines
      Users zur Nutzung unterschiedlicher Services von Google. CONSENT dient auch der Sicherheit, um User zu überprüfen
      und Userdaten vor unbefugten Angriffen zu schützen.<br/>
      <strong>Ablaufdatum: </strong>nach 19 Jahren</p>
    <p><strong>Name:</strong> HSID<br/>
      <strong>Wert:</strong> AcRwpgUik9Dveht0I<br/>
      <strong>Verwendungszweck:</strong> Dieses Cookie wird verwendet, um ein Profil über
      Ihre Interessen zu erstellen. Diese Daten helfen personalisierte Werbung anzeigen zu können.<br/>
      <strong>Ablaufdatum:</strong> nach 2 Jahren</p>
    <p><strong>Name:</strong> LOGIN_INFO<br/>
      <strong>Wert:</strong> AFmmF2swRQIhALl6aL…<br/>
      <strong>Verwendungszweck:</strong> In diesem Cookie werden Informationen über Ihre
      Login-Daten gespeichert.<br/>
      <strong>Ablaufdatum:</strong> nach 2 Jahren</p>
    <p><strong>Name:</strong> SAPISID<br/>
      <strong>Wert:</strong> 7oaPxoG-pZsJuuF5/AnUdDUIsJ9iJz2vdM<br/>
      <strong>Verwendungszweck:</strong> Dieses Cookie funktioniert, indem es Ihren Browser
      und Ihr Gerät eindeutig identifiziert. Es wird verwendet, um ein Profil über Ihre Interessen zu erstellen.<br/>
      <strong>Ablaufdatum:</strong> nach 2 Jahren</p>
    <p><strong>Name:</strong> SID<br/>
      <strong>Wert:</strong> oQfNKjAsI321244596-<br/>
      <strong>Verwendungszweck:</strong> Dieses Cookie speichert Ihre Google-Konto-ID und
      Ihren letzten Anmeldezeitpunkt in digital signierter und verschlüsselter Form.<br/>
      <strong>Ablaufdatum:</strong> nach 2 Jahren</p>
    <p><strong>Name:</strong> SIDCC<br/>
      <strong>Wert:</strong> AN0-TYuqub2JOcDTyL<br/>
      <strong>Verwendungszweck:</strong> Dieses Cookie speichert Informationen, wie Sie die
      Webseite nutzen und welche Werbung Sie vor dem Besuch auf unserer Seite möglicherweise gesehen haben.<br/>
      <strong>Ablaufdatum:</strong> nach 3 Monaten</p>
    <h3>Wie lange und wo werden die Daten gespeichert?</h3>
    <p>Die Daten, die YouTube von Ihnen erhält und verarbeitet werden auf den Google-Servern gespeichert. Die meisten
      dieser Server befinden sich in Amerika. Unter <a class="adsimple-321244596"
                                                       href="https://www.google.com/about/datacenters/inside/locations/?hl=de"
                                                       target="_blank" rel="noopener noreferrer">https://www.google.com/about/datacenters/inside/locations/?hl=de</a>
      sehen Sie genau wo sich die Google-Rechenzentren befinden. Ihre Daten sind auf den Servern verteilt. So sind die
      Daten schneller abrufbar und vor Manipulation besser geschützt.</p>
    <p>Die erhobenen Daten speichert Google unterschiedlich lang. Manche Daten können Sie jederzeit löschen, andere
      werden automatisch nach einer begrenzten Zeit gelöscht und wieder andere werden von Google über längere Zeit
      gespeichert. Einige Daten (wie Elemente aus „Meine Aktivität“, Fotos oder Dokumente, Produkte), die in Ihrem
      Google-Konto gespeichert sind, bleiben so lange gespeichert, bis Sie sie löschen. Auch wenn Sie nicht in einem
      Google-Konto angemeldet sind, können Sie einige Daten, die mit Ihrem Gerät, Browser oder App verknüpft sind,
      löschen.</p>
    <h3>Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
    <p>Grundsätzlich können Sie Daten im Google Konto manuell löschen. Mit der 2019 eingeführten automatische
      Löschfunktion von Standort- und Aktivitätsdaten werden Informationen abhängig von Ihrer Entscheidung – entweder 3
      oder 18 Monate gespeichert und dann gelöscht.</p>
    <p>Unabhängig, ob Sie ein Google-Konto haben oder nicht, können Sie Ihren Browser so konfigurieren, dass Cookies von
      Google gelöscht bzw. deaktiviert werden. Je nach dem welchen Browser Sie verwenden, funktioniert dies auf
      unterschiedliche Art und Weise. Die folgenden Anleitungen zeigen, wie Sie Cookies in Ihrem Browser verwalten:</p>
    <p><a class="adsimple-321244596" href="https://support.google.com/chrome/answer/95647?tid=321244596" target="_blank"
          rel="noopener noreferrer">Chrome: Cookies in Chrome löschen, aktivieren und verwalten</a></p>
    <p><a class="adsimple-321244596" href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=321244596"
          target="_blank" rel="noopener noreferrer">Safari: Verwalten von Cookies und Websitedaten mit Safari</a></p>
    <p><a class="adsimple-321244596"
          href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=321244596"
          target="_blank" rel="noopener noreferrer">Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf
      Ihrem Computer abgelegt haben</a></p>
    <p><a class="adsimple-321244596"
          href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=321244596"
          target="_blank" rel="noopener noreferrer">Internet Explorer: Löschen und Verwalten von Cookies</a></p>
    <p><a class="adsimple-321244596"
          href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=321244596" target="_blank"
          rel="noopener noreferrer">Microsoft Edge: Löschen und Verwalten von Cookies</a></p>
    <p>Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser so einrichten, dass er Sie immer
      informiert, wenn ein Cookie gesetzt werden soll. So können Sie bei jedem einzelnen Cookie entscheiden, ob Sie es
      erlauben oder nicht. Da YouTube ein Tochterunternehmen von Google ist, gibt es eine gemeinsame
      Datenschutzerklärung. Wenn Sie mehr über den Umgang mit Ihren Daten erfahren wollen, empfehlen wir Ihnen die
      Datenschutzerklärung unter <a class="adsimple-321244596" href="https://policies.google.com/privacy?hl=de"
                                    target="_blank"
                                    rel="noopener noreferrer">https://policies.google.com/privacy?hl=de.</a></p>
    <h2>YouTube Abonnieren Button Datenschutzerklärung</h2>
    <p>Wir haben auf unserer Webseite den YouTube Abonnieren Button (engl. „Subscribe-Button“) eingebaut. Sie erkennen
      den Button meist am klassischen YouTube-Logo. Das Logo zeigt vor rotem Hintergrund in weißer Schrift die Wörter
      „Abonnieren“ oder „YouTube“ und links davon das weiße „Play-Symbol“. Der Button kann aber auch in einem anderen
      Design dargestellt sein.</p>
    <p>Unser YouTube-Kanal bietet Ihnen immer wieder lustige, interessante oder spannende Videos. Mit dem eingebauten
      „Abonnieren-Button“ können Sie unseren Kanal direkt von unserer Webseite aus abonnieren und müssen nicht eigens
      die YouTube-Webseite aufrufen. Wir wollen Ihnen somit den Zugang zu unserem umfassenden Content so einfach wie
      möglich machen. Bitte beachten Sie, dass YouTube dadurch Daten von Ihnen speichern und verarbeiten kann.</p>
    <p>Wenn Sie auf unserer Seite einen eingebauten Abo-Button sehen, setzt YouTube – laut Google – mindestens ein
      Cookie. Dieses Cookie speichert Ihre IP-Adresse und unsere URL. Auch Informationen über Ihren Browser, Ihren
      ungefähren Standort und Ihre voreingestellte Sprache kann YouTube so erfahren. Bei unserem Test wurden folgende
      vier Cookies gesetzt, ohne bei YouTube angemeldet zu sein:</p>
    <p><strong>Name:</strong> YSC<br/>
      <strong>Wert:</strong> b9-CV6ojI5321244596Y<br/>
      <strong>Verwendungszweck:</strong> Dieses Cookie registriert eine eindeutige ID, um
      Statistiken des gesehenen Videos zu speichern.<br/>
      <strong>Ablaufdatum:</strong> nach Sitzungsende</p>
    <p><strong>Name:</strong> PREF<br/>
      <strong>Wert:</strong> f1=50000000<br/>
      <strong>Verwendungszweck:</strong> Dieses Cookie registriert ebenfalls Ihre eindeutige
      ID. Google bekommt über PREF Statistiken, wie Sie YouTube-Videos auf unserer Webseite verwenden.<br/>
      <strong>Ablaufdatum:</strong> nach 8 Monate</p>
    <p><strong>Name:</strong> GPS<br/>
      <strong>Wert:</strong> 1<br/>
      <strong>Verwendungszweck:</strong> Dieses Cookie registriert Ihre eindeutige ID auf
      mobilen Geräten, um den GPS-Standort zu tracken.<br/>
      <strong>Ablaufdatum:</strong> nach 30 Minuten</p>
    <p><strong>Name:</strong> VISITOR_INFO1_LIVE<br/>
      <strong>Wert:</strong> 32124459695Chz8bagyU<br/>
      <strong>Verwendungszweck:</strong> Dieses Cookie versucht die Bandbreite des Users auf
      unseren Webseiten (mit eingebautem YouTube-Video) zu schätzen.<br/>
      <strong>Ablaufdatum:</strong> nach 8 Monaten</p>
    <p><strong>Anmerkung:</strong> Diese Cookies wurden nach einem Test gesetzt und können
      nicht den Anspruch auf Vollständigkeit erheben.</p>
    <p>Wenn Sie in Ihrem YouTube-Konto angemeldet sind, kann YouTube viele Ihrer Handlungen/Interaktionen auf unserer
      Webseite mit Hilfe von Cookies speichern und Ihrem YouTube-Konto zuordnen. YouTube bekommt dadurch zum Beispiel
      Informationen wie lange Sie auf unserer Seite surfen, welchen Browsertyp Sie verwenden, welche Bildschirmauflösung
      Sie bevorzugen oder welche Handlungen Sie ausführen.</p>
    <p>YouTube verwendet diese Daten zum einen um die eigenen Dienstleistungen und Angebote zu verbessern, zum anderen
      um Analysen und Statistiken für Werbetreibende (die Google Ads verwenden) bereitzustellen.</p>
    <h2>Zoom Datenschutzerklärung</h2>
    <p>Wir verwenden für unsere Website das Videokonferenz-Tool Zoom des amerikanischen Software-Unternehmens Zoom Video
      Communications. Der Firmenhauptsitz ist im kalifornischen San Jose, 55 Almaden Boulevard, 6th Floor, CA 95113.
      Dank „Zoom“ können wir mit Kunden, Geschäftspartnern, Klienten und auch Mitarbeitern ganz einfach und ohne
      Software-Installation eine Videokonferenz abhalten. In dieser Datenschutzerklärung gehen wir näher auf das Service
      ein und informieren Sie über die wichtigsten datenschutzrelevanten Aspekte.</p>
    <h3>Was ist Zoom?</h3>
    <p>Zoom ist eine der weltweit bekanntesten Videokonferenzlösungen. Mit dem Dienst „Zoom Meetings“ können wir
      beispielsweise mit Ihnen, aber auch mit Mitarbeitern oder anderen Usern über einen digitalen Konferenzraum eine
      Online-Videokonferenz abhalten. So können wir sehr einfach digital in Kontakt treten, uns über diverse Themen
      austauschen, Textnachrichten schicken oder auch telefonieren. Weiters kann man über Zoom auch den Bildschirm
      teilen, Dateien austauschen und ein Whiteboard nutzen.</p>
    <h3>Warum verwenden wir Zoom auf unserer Webseite?</h3>
    <p>Uns ist es wichtig, dass wir mit Ihnen schnell und unkompliziert kommunizieren können. Und genau diese
      Möglichkeit bietet uns Zoom. Das Softwareprogramm funktioniert auch direkt über einen Browser. Das heißt wir
      können Ihnen einfach einen Link schicken und schon mit der Videokonferenz starten. Zudem sind natürlich auch
      Zusatzfunktionen wie Screensharing oder das Austauschen von Dateien sehr praktisch.</p>
    <h3>Welche Daten werden von Zoom gespeichert?</h3>
    <p>Wenn Sie Zoom verwenden, werden auch Daten von Ihnen erhoben, damit Zoom ihre Dienste bereitstellen kann. Das
      sind einerseits Daten, die Sie dem Unternehmen bewusst zur Verfügung stellen. Dazu gehören zum Beispiel Name,
      Telefonnummer oder Ihre E-Mail-Adresse. Es werden aber auch Daten automatisch an Zoom übermittelt und gespeichert.
      Hierzu zählen beispielsweise technische Daten Ihres Browsers oder Ihre IP-Adresse. Im Folgenden gehen wir genauer
      auf die Daten ein, die Zoom von Ihnen erheben und speichern kann:</p>
    <p>Wenn Sie Daten wie Ihren Namen, Ihren Benutzernamen, Ihre E-Mail-Adresse oder Ihre Telefonnummer angeben, werden
      diese Daten bei Zoom gespeichert. Inhalte, die Sie während der Zoom-Nutzung hochladen werden ebenfalls
      gespeichert. Dazu zählen beispielsweise Dateien oder Chatprotokolle.</p>
    <p>Zu den technischen Daten, die Zoom automatisch speichert, zählen neben der oben bereits erwähnten IP-Adresse auch
      die MAC-Adresse, weitere Geräte-IDs, Gerätetyp, welches Betriebssystem Sie nutzen, welchen Client Sie nutzen,
      Kameratyp, Mikrofon- und Lautsprechertyp. Auch Ihr ungefährer Standort wird bestimmt und gespeichert. Des Weiteren
      speichert Zoom auch Informationen darüber wie Sie den Dienst nutzen. Also beispielsweise ob Sie via Desktop oder
      Smartphone „zoomen“, ob Sie einen Telefonanruf oder VoIP nutzen, ob Sie mit oder ohne Video teilnehmen oder ob Sie
      ein Passwort anfordern. Zoom zeichnet auch sogenannte Metadaten wie Dauer des Meetings/Anrufs, Beginn und Ende der
      Meetingteilnahme, Meetingname und Chatstatus auf.</p>
    <p>Zoom erwähnt in der eigenen Datenschutzerklärung, dass das Unternehmen keine Werbecookies oder
      Tracking-Technologien für Ihre Dienste verwenden. Nur auf den eigenen Marketing-Websites wie etwa <a
          class="adsimple-321244596" href="https://www.zoom.us/de-de/home.html" target="_blank"
          rel="follow noopener noreferrer">www.zoom.us</a> werden diese Trackingmethoden genutzt. Zoom verkauft
      personenbezogenen Daten nicht weiter und nutzt diese auch nicht für Werbezwecke.</p>
    <h3>Wie lange und wo werden die Daten gespeichert?</h3>
    <p>Zoom gibt diesbezüglich keinen konkreten Zeitrahmen bekannt, sondern betont, dass die erhobenen Daten solange
      gespeichert bleiben, wie es zur Bereitstellung der Dienste bzw. für die eigenen Zwecke nötig ist. Länger werden
      die Daten nur gespeichert, wenn dies aus rechtlichen Gründen gefordert wird.</p>
    <p>Grundsätzlich speichert Zoom die erhobenen Daten auf amerikanischen Servern, aber Daten können weltweit auf
      unterschiedlichen Rechenzentren eintreffen.</p>
    <h3>Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
    <p>Wenn Sie nicht wollen, dass Daten während des Zoom-Meetings gespeichert werden, müssen Sie auf das Meeting
      verzichten. Sie haben aber auch immer das Recht und die Möglichkeit all Ihre personenbezogenen Daten löschen zu
      lassen. Falls Sie ein Zoom-Konto haben, finden Sie unter <a class="adsimple-321244596"
                                                                  href="https://support.zoom.us/hc/en-us/articles/201363243-How-Do-I-Delete-Terminate-My-Account"
                                                                  target="_blank" rel="follow noopener noreferrer">https://support.zoom.us/hc/en-us/articles/201363243-How-Do-I-Delete-Terminate-My-Account</a>
      eine Anleitung wie Sie Ihr Konto löschen können.</p>
    <p>Zoom Video Communications ist aktiver Teilnehmer beim EU-U.S. Privacy Shield Framework, wodurch der korrekte und
      sichere Datentransfer persönlicher Daten geregelt wird. Mehr Informationen dazu finden Sie auf <a
          class="adsimple-321244596"
          href="https://www.privacyshield.gov/participant?id=a2zt0000000TNkCAAW&amp;status=Active" target="_blank"
          rel="noopener noreferrer">https://www.privacyshield.gov/participant?id=a2zt0000000TNkCAAW&amp;status=Active</a>.<br/>
      Wir hoffen Ihnen einen Überblick über die Datenverarbeitung durch Zoom geboten zu haben. Es kann natürlich immer
      auch vorkommen, dass sich die Datenschutzrichtlinien des Unternehmens ändern. Daher empfehlen wir Ihnen für mehr
      Informationen auch die Datenschutzerklärung von Zoom unter <a class="adsimple-321244596"
                                                                    href="https://zoom.us/de-de/privacy.html?tid=321244596"
                                                                    target="_blank" rel="noopener noreferrer">https://zoom.us/de-de/privacy.html</a>.
    </p>
    <h2>AdSimple Cookie Manager Datenschutzerklärung</h2>
    <p>Wir verwenden auf unserer Website den AdSimple Cookie Manager des Softwareentwicklungs- und Online-Marketing
      Unternehmens AdSimple GmbH, Fabriksgasse 20, 2230 Gänserndorf. Der AdSimple Cookie Manager bietet uns unter
      anderem die Möglichkeit, Ihnen einen umfangreichen und datenschutzkonformen Cookie-Hinweis zu liefern, damit Sie
      selbst entscheiden können, welche Cookies Sie zulassen und welche nicht. Durch die Verwendung dieser Software
      werden Daten von Ihnen an AdSimple gesendet und gespeichert. In dieser Datenschutzerklärung informieren wir Sie,
      warum wir den AdSimple Cookie Manager verwenden, welche Daten übertragen und gespeichert werden und wie Sie diese
      Datenübertragung verhindern können.</p>
    <h3>Was ist der AdSimple Cookie Manager?</h3>
    <p>Der AdSimple Cookie Manager ist eine Software, die unsere Website scannt und alle vorhandenen Cookies
      identifiziert und kategorisiert. Zudem werden Sie als Websitebesucher über ein Cookie Hinweis Script über die
      Verwendung von Cookies informiert und entscheiden selbst welche Cookies Sie zulassen und welche nicht.</p>
    <h3>Warum verwenden wir den AdSimple Cookie Manager auf unserer Website?</h3>
    <p>Wir wollen Ihnen maximale Transparenz im Bereich Datenschutz bieten. Um das zu gewährleisten, müssen wir zuerst
      genau wissen, welche Cookies im Laufe der Zeit auf unserer Website gelandet sind. Dadurch, dass der Cookie Manager
      von AdSimple regelmäßig unsere Website scannt und alle Cookies ausfindig macht, haben wir die volle Kontrolle über
      diese Cookies und können so DSGVO-konform handeln. Wir können Sie dadurch über die Nutzung der Cookies auf unserer
      Website genau informieren. Weiters bekommen Sie stets einen aktuellen und datenschutzkonformen Cookie-Hinweis und
      entscheiden per Checkbox-System selbst, welche Cookies Sie akzeptieren bzw. blockieren.</p>
    <h3>Welche Daten werden von dem AdSimple Cookie Manager gespeichert?</h3>
    <p>Wenn Sie Cookies auf unserer Website zustimmen, wird folgendes Cookie von dem AdSimple Cookie Manager
      gesetzt:</p>
    <p><strong>Name:</strong> acm_status<br/>
      <strong>Wert:</strong>
      “:true,”statistik”:true,”marketing”:true,”socialmedia”:true,”einstellungen”:true}<br/>
      <strong>Verwendungszweck:</strong> In diesem Cookie wird Ihr Zustimmungsstatus,
      gespeichert. Dadurch kann unsere Website auch bei zukünftigen Besuchen den aktuellen Status lesen und
      befolgen.<br/>
      <strong>Ablaufdatum:</strong> nach einem Jahr</p>
    <h3>Wie lange und wo werden die Daten gespeichert?</h3>
    <p>Alle Daten, die durch den AdSimple Cookie Manager erhoben werden, werden ausschließlich innerhalb der
      Europäischen Union übertragen und gespeichert. Die erhobenen Daten werden auf den Servern von AdSimple bei der
      Hetzner GmbH in Deutschland gespeichert. Zugriff auf diese Daten hat ausschließlich die AdSimple GmbH und die
      Hetzner GmbH.</p>
    <h3>Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
    <p>Sie haben jederzeit das Recht auf Ihre personenbezogenen Daten zuzugreifen und sie auch zu löschen. Die
      Datenerfassung und Speicherung können Sie beispielsweise verhindern, indem Sie über das Cookie-Hinweis-Script die
      Verwendung von Cookies ablehnen. Eine weitere Möglichkeit die Datenverarbeitung zu unterbinden bzw. nach Ihren
      Wünschen zu verwalten, bietet Ihr Browser. Je nach Browser funktioniert die Cookie-Verwaltung etwas anders. Hier
      finden Sie die Anleitungen zu den momentan bekanntesten Browsern:</p>
    <p><a class="adsimple-321244596" href="https://support.google.com/chrome/answer/95647?tid=321244596" target="_blank"
          rel="noopener noreferrer">Chrome: Cookies in Chrome löschen, aktivieren und verwalten</a></p>
    <p><a class="adsimple-321244596" href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=321244596"
          target="_blank" rel="noopener noreferrer">Safari: Verwalten von Cookies und Websitedaten mit Safari</a></p>
    <p><a class="adsimple-321244596"
          href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=321244596"
          target="_blank" rel="noopener noreferrer">Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf
      Ihrem Computer abgelegt haben</a></p>
    <p><a class="adsimple-321244596"
          href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=321244596"
          target="_blank" rel="noopener noreferrer">Internet Explorer: Löschen und Verwalten von Cookies</a></p>
    <p><a class="adsimple-321244596"
          href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=321244596" target="_blank"
          rel="noopener noreferrer">Microsoft Edge: Löschen und Verwalten von Cookies</a></p>
    <p>Wir hoffen, wir haben Ihnen einen guten Überblick über den Datenverkehr und die Datenverarbeitung durch den
      AdSimple Cookie Manager geliefert. Wenn Sie mehr über dieses Tool erfahren wollen, empfehlen wir Ihnen die
      Beschreibungsseite auf <a class="adsimple-321244596"
                                href="https://www.adsimple.at/adsimple-cookie-manager/?tid=321244596" target="_blank"
                                rel="noopener noreferrer">https://www.adsimple.at/adsimple-cookie-manager/.</a></p>
    <h2>Google reCAPTCHA Datenschutzerklärung</h2>
    <p>Unser oberstes Ziel ist es, unsere Webseite für Sie und für uns bestmöglich zu sichern und zu schützen. Um das zu
      gewährleisten, verwenden wir Google reCAPTCHA der Firma Google Inc. Für den europäischen Raum ist das Unternehmen
      Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) für alle Google-Dienste verantwortlich. Mit
      reCAPTCHA können wir feststellen, ob Sie auch wirklich ein Mensch aus Fleisch und Blut sind und kein Roboter oder
      eine andere Spam-Software. Unter Spam verstehen wir jede, auf elektronischen Weg, unerwünschte Information, die
      uns ungefragter Weise zukommt. Bei den klassischen CAPTCHAS mussten Sie zur Überprüfung meist Text- oder
      Bildrätsel lösen. Mit reCAPTCHA von Google müssen wir Sie meist nicht mit solchen Rätseln belästigen. Hier reicht
      es in den meisten Fällen, wenn Sie einfach ein Häkchen setzen und so bestätigen, dass Sie kein Bot sind. Mit der
      neuen Invisible reCAPTCHA Version müssen Sie nicht mal mehr ein Häkchen setzen. Wie das genau funktioniert und vor
      allem welche Daten dafür verwendet werden, erfahren Sie im Verlauf dieser Datenschutzerklärung.</p>
    <h3>Was ist reCAPTCHA?</h3>
    <p>reCAPTCHA ist ein freier Captcha-Dienst von Google, der Webseiten vor Spam-Software und den Missbrauch durch
      nicht-menschliche Besucher schützt. Am häufigsten wird dieser Dienst verwendet, wenn Sie Formulare im Internet
      ausfüllen. Ein Captcha-Dienst ist eine Art automatischer Turing-Test, der sicherstellen soll, dass eine Handlung
      im Internet von einem Menschen und nicht von einem Bot vorgenommen wird. Im klassischen Turing-Test (benannt nach
      dem Informatiker Alan Turing) stellt ein Mensch die Unterscheidung zwischen Bot und Mensch fest. Bei Captchas
      übernimmt das auch der Computer bzw. ein Softwareprogramm. Klassische Captchas arbeiten mit kleinen Aufgaben, die
      für Menschen leicht zu lösen sind, doch für Maschinen erhebliche Schwierigkeiten aufweisen. Bei reCAPTCHA müssen
      Sie aktiv keine Rätsel mehr lösen. Das Tool verwendet moderne Risikotechniken, um Menschen von Bots zu
      unterscheiden. Hier müssen Sie nur noch das Textfeld „Ich bin kein Roboter“ ankreuzen bzw. bei Invisible reCAPTCHA
      ist selbst das nicht mehr nötig. Bei reCAPTCHA wird ein JavaScript-Element in den Quelltext eingebunden und dann
      läuft das Tool im Hintergrund und analysiert Ihr Benutzerverhalten. Aus diesen Useraktionen berechnet die Software
      einen sogenannten Captcha-Score. Google berechnet mit diesem Score schon vor der Captcha-Eingabe wie hoch die
      Wahrscheinlichkeit ist, dass Sie ein Mensch sind. reCAPTCHA bzw. Captchas im Allgemeinen kommen immer dann zum
      Einsatz, wenn Bots gewisse Aktionen (wie z.B. Registrierungen, Umfragen usw.) manipulieren oder missbrauchen
      könnten.</p>
    <h3>Warum verwenden wir reCAPTCHA auf unserer Webseite?</h3>
    <p>Wir wollen nur Menschen aus Fleisch und Blut auf unserer Seite begrüßen. Bots oder Spam-Software
      unterschiedlichster Art dürfen getrost zuhause bleiben. Darum setzen wir alle Hebel in Bewegung, uns zu schützen
      und die bestmögliche Benutzerfreundlichkeit für Sie anzubieten. Aus diesem Grund verwenden wir Google reCAPTCHA
      der Firma Google. So können wir uns ziemlich sicher sein, dass wir eine „botfreie“ Webseite bleiben. Durch die
      Verwendung von reCAPTCHA werden Daten an Google übermittelt, um festzustellen, ob Sie auch wirklich ein Mensch
      sind. reCAPTCHA dient also der Sicherheit unserer Webseite und in weiterer Folge damit auch Ihrer Sicherheit. Zum
      Beispiel könnte es ohne reCAPTCHA passieren, dass bei einer Registrierung ein Bot möglichst viele E-Mail-Adressen
      registriert, um im Anschluss Foren oder Blogs mit unerwünschten Werbeinhalten „zuzuspamen“. Mit reCAPTCHA können
      wir solche Botangriffe vermeiden.</p>
    <h3>Welche Daten werden von reCAPTCHA gespeichert?</h3>
    <p>reCAPTCHA sammelt personenbezogene Daten von Usern, um festzustellen, ob die Handlungen auf unserer Webseite auch
      wirklich von Menschen stammen. Es kann also die IP-Adresse und andere Daten, die Google für den reCAPTCHA-Dienst
      benötigt, an Google versendet werden. IP-Adressen werden innerhalb der Mitgliedstaaten der EU oder anderer
      Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum fast immer zuvor gekürzt, bevor die Daten auf
      einem Server in den USA landen. Die IP-Adresse wird nicht mit anderen Daten von Google kombiniert, sofern Sie
      nicht während der Verwendung von reCAPTCHA mit Ihrem Google-Konto angemeldet sind. Zuerst prüft der
      reCAPTCHA-Algorithmus, ob auf Ihrem Browser schon Google-Cookies von anderen Google-Diensten (YouTube. Gmail usw.)
      platziert sind. Anschließend setzt reCAPTCHA ein zusätzliches Cookie in Ihrem Browser und erfasst einen
      Schnappschuss Ihres Browserfensters.</p>
    <p>Die folgende Liste von gesammelten Browser- und Userdaten, hat nicht den Anspruch auf Vollständigkeit. Vielmehr
      sind es Beispiele von Daten, die nach unserer Erkenntnis, von Google verarbeitet werden.</p>
    <ul>
      <li>Referrer URL (die Adresse der Seite von der der Besucher kommt)</li>
      <li>IP-Adresse (z.B. 256.123.123.1)</li>
      <li>Infos über das Betriebssystem (die Software, die den Betrieb Ihres Computers
        ermöglicht. Bekannte Betriebssysteme sind Windows, Mac OS X oder Linux)
      </li>
      <li>Cookies (kleine Textdateien, die Daten in Ihrem Browser speichern)</li>
      <li>Maus- und Keyboardverhalten (jede Aktion, die Sie mit der Maus oder der Tastatur
        ausführen wird gespeichert)
      </li>
      <li>Datum und Spracheinstellungen (welche Sprache bzw. welches Datum Sie auf Ihrem PC
        voreingestellt haben wird gespeichert)
      </li>
      <li>Alle Javascript-Objekte (JavaScript ist eine Programmiersprache, die Webseiten
        ermöglicht, sich an den User anzupassen. JavaScript-Objekte können alle möglichen Daten unter einem Namen
        sammeln)
      </li>
      <li>Bildschirmauflösung (zeigt an aus wie vielen Pixeln die Bilddarstellung besteht)
      </li>
    </ul>
    <p>Unumstritten ist, dass Google diese Daten verwendet und analysiert noch bevor Sie auf das Häkchen „Ich bin kein
      Roboter“ klicken. Bei der Invisible reCAPTCHA-Version fällt sogar das Ankreuzen weg und der ganze
      Erkennungsprozess läuft im Hintergrund ab. Wie viel und welche Daten Google genau speichert, erfährt man von
      Google nicht im Detail.</p>
    <p>Folgende Cookies werden von reCAPTCHA verwendet: Hierbei beziehen wir uns auf die reCAPTCHA Demo-Version von
      Google unter <a class="adsimple-321244596" href="https://www.google.com/recaptcha/api2/demo" target="_blank"
                      rel="noopener noreferrer">https://www.google.com/recaptcha/api2/demo</a>. All diese Cookies
      benötigen zu Trackingzwecken eine eindeutige Kennung. Hier ist eine Liste an Cookies, die Google reCAPTCHA auf der
      Demo-Version gesetzt hat:</p>
    <p><strong>Name:</strong> IDE<br/>
      <strong>Wert:</strong> WqTUmlnmv_qXyi_DGNPLESKnRNrpgXoy1K-pAZtAkMbHI-321244596-8<br/>
      <strong>Verwendungszweck:</strong> Dieses Cookie wird von der Firma DoubleClick (gehört
      auch Google) gesetzt, um die Aktionen eines Users auf der Webseite im Umgang mit Werbeanzeigen zu registrieren und
      zu melden. So kann die Werbewirksamkeit gemessen und entsprechende Optimierungsmaßnahmen getroffen werden. IDE
      wird in Browsern unter der Domain doubleclick.net gespeichert.<br/>
      <strong>Ablaufdatum:</strong> nach einem Jahr</p>
    <p><strong>Name:</strong> 1P_JAR<br/>
      <strong>Wert:</strong> 2019-5-14-12<br/>
      <strong>Verwendungszweck:</strong> Dieses Cookie sammelt Statistiken zur
      Webseite-Nutzung und misst Conversions. Eine Conversion entsteht z.B., wenn ein User zu einem Käufer wird. Das
      Cookie wird auch verwendet, um Usern relevante Werbeanzeigen einzublenden. Weiters kann man mit dem Cookie
      vermeiden, dass ein User dieselbe Anzeige mehr als einmal zu Gesicht bekommt.<br/>
      <strong>Ablaufdatum:</strong> nach einem Monat</p>
    <p><strong>Name:</strong> ANID<br/>
      <strong>Wert:</strong> U7j1v3dZa3212445960xgZFmiqWppRWKOr<br/>
      <strong>Verwendungszweck:</strong> Viele Infos konnten wir über dieses Cookie nicht in
      Erfahrung bringen. In der Datenschutzerklärung von Google wird das Cookie im Zusammenhang mit „Werbecookies“ wie
      z. B. “DSID”, “FLC”, “AID”, “TAID” erwähnt. ANID wird unter Domain google.com gespeichert.<br/>
      <strong>Ablaufdatum:</strong> nach 9 Monaten</p>
    <p><strong>Name:</strong> CONSENT<br/>
      <strong>Wert: </strong>YES+AT.de+20150628-20-0<br/>
      <strong>Verwendungszweck:</strong> Das Cookie speichert den Status der Zustimmung eines
      Users zur Nutzung unterschiedlicher Services von Google. CONSENT dient auch der Sicherheit, um User zu überprüfen,
      Betrügereien von Anmeldeinformationen zu verhindern und Userdaten vor unbefugten Angriffen zu schützen.<br/>
      <strong>Ablaufdatum:</strong> nach 19 Jahren</p>
    <p><strong>Name:</strong> NID<br/>
      <strong>Wert:</strong> 0WmuWqy321244596zILzqV_nmt3sDXwPeM5Q<br/>
      <strong>Verwendungszweck:</strong> NID wird von Google verwendet, um Werbeanzeigen an
      Ihre Google-Suche anzupassen. Mit Hilfe des Cookies „erinnert“ sich Google an Ihre meist eingegebenen Suchanfragen
      oder Ihre frühere Interaktion mit Anzeigen. So bekommen Sie immer maßgeschneiderte Werbeanzeigen. Das Cookie
      enthält eine einzigartige ID, um persönliche Einstellungen des Users für Werbezwecke zu sammeln.<br/>
      <strong>Ablaufdatum:</strong> nach 6 Monaten</p>
    <p><strong>Name:</strong> DV<br/>
      <strong>Wert:</strong> gEAABBCjJMXcI0dSAAAANbqc321244596-4<br/>
      <strong>Verwendungszweck:</strong> Sobald Sie das „Ich bin kein Roboter“-Häkchen
      angekreuzt haben, wird dieses Cookie gesetzt. Das Cookie wird von Google Analytics für personalisierte Werbung
      verwendet. DV sammelt Informationen in anonymisierter Form und wird weiters benutzt, um User-Unterscheidungen zu
      treffen.<br/>
      <strong>Ablaufdatum:</strong> nach 10 Minuten</p>
    <p><strong>Anmerkung: </strong>Diese Aufzählung kann keinen Anspruch auf Vollständigkeit
      erheben, da Google erfahrungsgemäß die Wahl ihrer Cookies immer wieder auch verändert.</p>
    <h3>Wie lange und wo werden die Daten gespeichert?</h3>
    <p>Durch das Einfügen von reCAPTCHA werden Daten von Ihnen auf den Google-Server übertragen. Wo genau diese Daten
      gespeichert werden, stellt Google, selbst nach wiederholtem Nachfragen, nicht klar dar. Ohne eine Bestätigung von
      Google erhalten zu haben, ist davon auszugehen, dass Daten wie Mausinteraktion, Verweildauer auf der Webseite oder
      Spracheinstellungen auf den europäischen oder amerikanischen Google-Servern gespeichert werden. Die IP-Adresse,
      die Ihr Browser an Google übermittelt, wird grundsätzlich nicht mit anderen Google-Daten aus weiteren
      Google-Diensten zusammengeführt. Wenn Sie allerdings während der Nutzung des reCAPTCHA-Plug-ins bei Ihrem
      Google-Konto angemeldet sind, werden die Daten zusammengeführt.<strong> </strong>Dafür
      gelten die abweichenden Datenschutzbestimmungen der Firma Google.</p>
    <h3>Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
    <p>Wenn Sie wollen, dass über Sie und über Ihr Verhalten keine Daten an Google übermittelt werden, müssen Sie sich,
      bevor Sie unsere Webseite besuchen bzw. die reCAPTCHA-Software verwenden, bei Google vollkommen ausloggen und alle
      Google-Cookies löschen. Grundsätzlich werden die Daten sobald Sie unsere Seite aufrufen automatisch an Google
      übermittelt. Um diese Daten wieder zu löschen, müssen Sie den Google-Support auf <a class="adsimple-321244596"
                                                                                          href="https://support.google.com/?hl=de&amp;tid=321244596"
                                                                                          target="_blank"
                                                                                          rel="noopener">https://support.google.com/?hl=de&amp;tid=321244596</a>
      kontaktieren.</p>
    <p>Wenn Sie also unsere Webseite verwenden, erklären Sie sich einverstanden, dass Google LLC und deren Vertreter
      automatisch Daten erheben, bearbeiten und nutzen.</p>
    <p>Etwas mehr über reCAPTCHA erfahren Sie auf der Webentwickler-Seite von Google auf <a class="adsimple-321244596"
                                                                                            href="https://developers.google.com/recaptcha/"
                                                                                            target="_blank"
                                                                                            rel="noopener noreferrer">https://developers.google.com/recaptcha/</a>.
      Google geht hier zwar auf die technische Entwicklung der reCAPTCHA näher ein, doch genaue Informationen über
      Datenspeicherung und datenschutzrelevanten Themen sucht man auch dort vergeblich. Eine gute Übersicht über die
      grundsätzliche Verwendung von Daten bei Google finden Sie in der hauseigenen Datenschutzerklärung auf <a
          class="adsimple-321244596" href="https://policies.google.com/privacy?hl=de&amp;tid=321244596" target="_blank"
          rel="noopener noreferrer">https://www.google.com/intl/de/policies/privacy/</a>.</p>
    <h2>Benutzerdefinierte Google Suche Datenschutzerklärung</h2>
    <p>Wir haben auf unserer Website das Google-Plug-in zur benutzerdefinierten Suche eingebunden. Google ist die größte
      und bekannteste Suchmaschine weltweit und wird von dem US-amerikanische Unternehmen Google Inc. betrieben. Für den
      europäischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland)
      verantwortlich. Durch die benutzerdefinierte Google Suche können Daten von Ihnen an Google übertragen werden. In
      dieser Datenschutzerklärung informieren wir Sie, warum wir dieses Plug-in verwenden, welche Daten verarbeitet
      werden und wie Sie diese Datenübertragung verwalten oder unterbinden können.</p>
    <h3>Was ist die benutzerdefinierte Google Suche?</h3>
    <p>Das Plug-in zur benutzerdefinierten Google Suche ist eine Google-Suchleiste direkt auf unserer Website. Die Suche
      findet wie auf <a class="adsimple-321244596" href="https://www.google.com?tid=321244596" target="_blank"
                        rel="follow noopener">www.google.com</a> statt, nur fokussieren sich die Suchergebnisse auf
      unsere Inhalte und Produkte bzw. auf einen eingeschränkten Suchkreis.</p>
    <h3>Warum verwenden wir die benutzerdefinierte Google Suche auf unserer Webseite?</h3>
    <p>Eine Website mit vielen interessanten Inhalten wird oft so groß, dass man unter Umständen den Überblick verliert.
      Über die Zeit hat sich auch bei uns viel wertvolles Material angesammelt und wir wollen als Teil unserer
      Dienstleistung, dass Sie unsere Inhalte so schnell und einfach wie möglich finden. Durch die benutzerdefinierte
      Google-Suche wird das Finden von interessanten Inhalten zu einem Kinderspiel. Das eingebaute Google-Plug-in
      verbessert insgesamt die Qualität unserer Website und macht Ihnen das Suchen leichter.</p>
    <h3>Welche Daten werden durch die benutzerdefinierte Google Suche gespeichert?</h3>
    <p>Durch die benutzerdefinierte Google-Suche werden nur Daten von Ihnen an Google übertragen, wenn Sie die auf
      unserer Website eingebaute Google-Suche aktiv verwenden. Das heißt, erst wenn Sie einen Suchbegriff in die
      Suchleiste eingeben und dann diesen Begriff bestätigen (z.B. auf „Enter“ klicken) wird neben dem Suchbegriff auch
      Ihre IP-Adresse an Google gesandt, gespeichert und dort verarbeitet. Anhand der gesetzten Cookies (wie z.B.
      1P_JAR) ist davon auszugehen, dass Google auch Daten zur Webseiten-Nutzung erhält. Wenn Sie während Ihrem Besuch
      auf unserer Webseite, über die eingebaute Google-Suchfunktion, Inhalte suchen und gleichzeitig mit Ihrem
      Google-Konto angemeldet sind, kann Google die erhobenen Daten auch Ihrem Google-Konto zuordnen. Als
      Websitebetreiber haben wir keinen Einfluss darauf, was Google mit den erhobenen Daten macht bzw. wie Google die
      Daten verarbeitet.</p>
    <p>Folgende Cookie werden in Ihrem Browser gesetzt, wenn Sie die benutzerdefinierte Google Suche verwenden und nicht
      mit einem Google-Konto angemeldet sind:</p>
    <p><strong>Name:</strong> 1P_JAR<br/>
      <strong>Wert:</strong> 2020-01-27-13321244596-5<br/>
      <strong>Verwendungszweck:</strong> Dieses Cookie sammelt Statistiken zur
      Website-Nutzung und misst Conversions. Eine Conversion entsteht zum Beispiel, wenn ein User zu einem Käufer wird.
      Das Cookie wird auch verwendet, um Usern relevante Werbeanzeigen einzublenden.<br/>
      <strong>Ablaufdatum:</strong> nach einem Monat</p>
    <p><strong>Name:</strong> CONSENT<br/>
      <strong>Wert:</strong> WP.282f52321244596-9<br/>
      <strong>Verwendungszweck:</strong> Das Cookie speichert den Status der Zustimmung eines
      Users zur Nutzung unterschiedlicher Services von Google. CONSENT dient auch der Sicherheit, um User zu überprüfen
      und Userdaten vor unbefugten Angriffen zu schützen.<br/>
      <strong>Ablaufdatum:</strong> nach 18 Jahren</p>
    <p><strong>Name:</strong> NID<br/>
      <strong>Wert:</strong> 196=pwIo3B5fHr-8<br/>
      <strong>Verwendungszweck:</strong> NID wird von Google verwendet, um Werbeanzeigen an
      Ihre Google-Suche anzupassen. Mit Hilfe des Cookies „erinnert“ sich Google an Ihre eingegebenen Suchanfragen oder
      Ihre frühere Interaktion mit Anzeigen. So bekommen Sie immer maßgeschneiderte Werbeanzeigen.<br/>
      <strong>Ablaufdatum:</strong> nach 6 Monaten</p>
    <p><strong>Anmerkung:</strong> Diese Aufzählung kann keinen Anspruch auf Vollständigkeit
      erheben, da Google die Wahl ihrer Cookies immer wieder auch verändert.</p>
    <h3>Wie lange und wo werden die Daten gespeichert?</h3>
    <p>Die Google-Server sind auf der ganzen Welt verteilt. Da es sich bei Google um ein amerikanisches Unternehmen
      handelt, werden die meisten Daten auf amerikanischen Servern gespeichert. Unter <a class="adsimple-321244596"
                                                                                         href="https://www.google.com/about/datacenters/inside/locations/?hl=de"
                                                                                         target="_blank"
                                                                                         rel="follow noopener">https://www.google.com/about/datacenters/inside/locations/?hl=de</a>
      sehen Sie genau, wo die Google-Server stehen.<br/>
      Ihre Daten werden auf verschiedenen physischen Datenträgern verteilt. Dadurch sind die Daten schneller abrufbar
      und vor möglichen Manipulationen besser geschützt. Google hat auch entsprechende Notfallprogramme für Ihre Daten.
      Wenn es beispielsweise bei Google interne technische Probleme gibt und dadurch Server nicht mehr funktionieren,
      bleibt das Risiko einer Dienstunterbrechung und eines Datenverlusts dennoch gering.<br/>
      Je nach dem um welche Daten es sich handelt, speichert Google diese unterschiedlich lange. Manche Daten können Sie
      selbst löschen, andere werden von Google automatisch gelöscht oder anonymisiert. Es gibt aber auch Daten, die
      Google länger speichert, wenn dies aus juristischen oder geschäftlichen Gründen erforderlich ist.</p>
    <h3>Wie kann ich meinen Daten löschen bzw. die Datenspeicherung verhindern?</h3>
    <p>Nach dem Datenschutzrecht der Europäischen Union haben Sie das Recht, Auskunft über Ihre Daten zu erhalten, sie
      zu aktualisieren, zu löschen oder einzuschränken. Es gibt einige Daten, die Sie jederzeit löschen können. Wenn Sie
      ein Google-Konto besitzen, können Sie dort Daten zu Ihrer Webaktivität löschen bzw. festlegen, dass sie nach einer
      bestimmten Zeit gelöscht werden sollen.<br/>
      In Ihrem Browser haben Sie zudem die Möglichkeit, Cookies zu deaktivieren, zu löschen oder nach Ihren Wünschen und
      Vorlieben zu verwalten. Hier finden Sie Anleitungen zu den wichtigsten Browsern:</p>
    <p><a class="adsimple-321244596" href="https://support.google.com/chrome/answer/95647?tid=321244596" target="_blank"
          rel="noopener">Chrome: Cookies in Chrome löschen, aktivieren und verwalten</a></p>
    <p><a class="adsimple-321244596" href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=321244596"
          target="_blank" rel="noopener">Safari: Verwalten von Cookies und Websitedaten mit Safari</a></p>
    <p><a class="adsimple-321244596"
          href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=321244596"
          target="_blank" rel="noopener">Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem
      Computer abgelegt haben</a></p>
    <p><a class="adsimple-321244596"
          href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=321244596"
          target="_blank" rel="noopener">Internet Explorer: Löschen und Verwalten von Cookies</a></p>
    <p><a class="adsimple-321244596"
          href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=321244596" target="_blank"
          rel="noopener">Microsoft Edge: Löschen und Verwalten von Cookies</a></p>
    <p>Google ist aktiver Teilnehmer beim EU-U.S. Privacy Shield Framework, wodurch der korrekte und sichere
      Datentransfer persönlicher Daten geregelt wird. Mehr Informationen dazu finden Sie auf <a
          class="adsimple-321244596"
          href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;tid=321244596" target="_blank"
          rel="noopener">https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI</a>. Wir hoffen wir konnten
      Ihnen die wichtigsten Informationen rund um die Datenverarbeitung durch Google näherbringen. Wenn Sie mehr darüber
      erfahren wollen, empfehlen wir die umfangreiche Datenschutzerklärung von Google unter <a
          class="adsimple-321244596" href="https://policies.google.com/privacy?hl=de" target="_blank"
          rel="follow noopener">https://policies.google.com/privacy?hl=de</a>.</p>
    <h2>WooCommerce Datenschutzerklärung</h2>
    <p>Wir haben auf unserer Website das Open-Source Shopsystem WooCommerce als Plugin eingebunden. Dieses
      WooCommerce-Plugin basiert auf dem Content-Management-System WordPress, das ein Tochterunternehmen der Firma
      Automattic Inc. (60 29th Street #343, San Francisco, CA 94110, USA) ist. Durch die implementierten Funktionen
      werden Daten an Automattic Inc. versandt, gespeichert und verarbeitet. In dieser Datenschutzerklärung informieren
      wir Sie, um welche Daten es sich handelt, wie das Netzwerk diese Daten verwendet und wie Sie die Datenspeicherung
      verwalten bzw. unterbinden können.</p>
    <h3>Was ist WooCommerce?</h3>
    <p>WooCommerce ist ein Onlineshop-System, das seit 2011 Teil des Verzeichnisses von WordPress ist und speziell für
      WordPress-Websites entwickelt wurde. Es ist eine anpassbare, quelloffene eCommerce-Plattform, die auf WordPress
      basiert und auch als WordPress-Plugin in unsere Website eingebunden wurde.</p>
    <h3>Warum verwenden wir WooCommerce auf unserer Webseite?</h3>
    <p>Wir nutzen diese praktische Onlineshop-Lösung, um Ihnen unserer physischen oder digitalen Produkte oder
      Dienstleistungen bestmöglich auf unserer Website anbieten zu können. Ziel ist es, Ihnen einen einfachen und
      leichten Zugang zu unserem Angebot zu ermöglichen, damit Sie unkompliziert und schnell zu Ihren gewünschten
      Produkten kommen. Mit WooCommerce haben wir hier ein gutes Plugin gefunden, das unseren Ansprüchen an einen
      Onlineshop erfüllt.</p>
    <h3>Welche Daten werden von WooCommerce gespeichert?</h3>
    <p>Informationen, die Sie aktiv in ein Textfeld in unserem Onlineshop eingeben, können von WooCommerce bzw. von
      Automattic gesammelt und gespeichert werden. Also wenn Sie sich bei uns anmelden bzw. ein Produkt bestellen, kann
      Automattic diese Daten sammeln, verarbeiten und speichern. Dabei kann es sich neben E-Mail-Adresse, Namen oder
      Adresse auch um Kreditkarten- oder Rechnungsinformationen handeln. Automattic kann diese Informationen in weiterer
      Folge auch für eigene Marketing-Kampagnen nützen.</p>
    <p>Zudem gibt es auch noch Informationen, die Automattic automatisch in sogenannten Serverlogfiles von Ihnen
      sammelt:</p>
    <ul>
      <li>IP-Adresse</li>
      <li>Browserinformationen</li>
      <li>Voreingestellte Spracheinstellung</li>
      <li>Datum und Uhrzeit des Webzugriffs</li>
    </ul>
    <p>WooCommerce setzt in Ihrem Browser auch Cookies und verwendet Technologien wie Pixel-Tags (Web Beacons), um
      beispielsweise Sie als User klar zu identifizieren und möglicherweise interessensbezogene Werbung anbieten zu
      können. WooCommerce verwendet eine Reihe verschiedener Cookies, die je nach Useraktion gesetzt werden. Das heißt,
      wenn Sie zum Beispiel ein Produkt in den Warenkorb legen wird ein Cookie gesetzt, damit das Produkt auch im
      Warenkorb bleibt, wenn Sie unsere Website verlassen und zu einem späteren Zeitpunkt wiederkommen.</p>
    <p>Hier zeigen wir Ihnen eine beispielhafte Liste möglicher Cookies, die von WooCommerce gesetzt werden können:</p>
    <p><strong>Name:</strong> woocommerce_items_in_cart<br/>
      <strong>Wert:</strong> 1<br/>
      <strong>Verwendungszweck:</strong> Das Cookie hilft WooCommerce festzustellen, wann
      sich der Inhalt im Warenkorb verändert.<br/>
      <strong>Ablaufdatum:</strong> nach Sitzungsende</p>
    <p><strong>Name:</strong> woocommerce_cart_hash<br/>
      <strong>Wert:</strong> 447c84f810834056ab37cfe5ed27f204321244596-7<br/>
      <strong>Verwendungszweck:</strong> Auch dieses Cookie wird dafür eingesetzt, um die
      Veränderungen in Ihrem Warenkorb zu erkennen und zu speichern.<br/>
      <strong>Ablaufdatum:</strong> nach Sitzungsende</p>
    <p><strong>Name:</strong> wp_woocommerce_session_d9e29d251cf8a108a6482d9fe2ef34b6<br/>
      <strong>Wert:</strong>
      1146%7C%7C1589034207%7C%7C95f8053ce0cea135bbce671043e740321244596-4aa<br/>
      <strong>Verwendungszweck:</strong> Dieses Cookie enthält eine eindeutige Kennung für
      Sie, damit die Warenkorbdaten in der Datenbank auch gefunden werden können.<br/>
      <strong>Ablaufdatum:</strong> nach 2 Tagen</p>
    <h3>Wie lange und wo werden die Daten gespeichert?</h3>
    <p>Sofern es keine gesetzliche Verpflichtung gibt, Daten für einen längeren Zeitraum aufzubewahren, löscht
      WooCommerce die Daten dann, wenn Sie für die eigenen Zwecke, für die Sie gespeichert wurden, nicht mehr benötigt
      werden. So werden zum Beispiel Serverlogfiles, die technische Daten zu Ihrem Browser und Ihrer IP-Adresse erhalten
      etwa nach 30 Tagen wieder gelöscht. Solange verwendet Automattic die Daten, um den Verkehr auf den eigenen
      Websites (zum Beispiel alle WordPress-Seiten) zu analysieren und mögliche Probleme zu beheben. Die Daten werden
      auf amerikanischen Servern von Automattic gespeichert.</p>
    <h3>Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
    <p>Sie haben jederzeit das Recht auf Ihre personenbezogenen Daten zuzugreifen und Einspruch gegen die Nutzung und
      Verarbeitung dieser zu erheben. Sie können auch jederzeit bei einer staatlichen Aufsichtsbehörde eine Beschwerde
      einreichen.</p>
    <p>In Ihrem Browser haben Sie auch die Möglichkeit, Cookies individuell zu verwalten, zu löschen oder zu
      deaktivieren. Nehmen Sie aber bitte zur Kenntnis, dass deaktivierte oder gelöschte Cookies mögliche negative
      Auswirkungen auf die Funktionen unseres WooCommerce-Onlineshops haben. Je nachdem, welchen Browser Sie verwenden,
      funktioniert das Verwalten der Cookies etwas anders. Im Folgenden sehen Sie Links zu den Anleitungen der
      gängigsten Browser:</p>
    <p><a class="adsimple-321244596" href="https://support.google.com/chrome/answer/95647?tid=321244596" target="_blank"
          rel="noopener noreferrer">Chrome: Cookies in Chrome löschen, aktivieren und verwalten</a></p>
    <p><a class="adsimple-321244596" href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=321244596"
          target="_blank" rel="noopener noreferrer">Safari: Verwalten von Cookies und Websitedaten mit Safari</a></p>
    <p><a class="adsimple-321244596"
          href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=321244596"
          target="_blank" rel="noopener noreferrer">Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf
      Ihrem Computer abgelegt haben</a></p>
    <p><a class="adsimple-321244596"
          href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=321244596"
          target="_blank" rel="noopener noreferrer">Internet Explorer: Löschen und Verwalten von Cookies</a></p>
    <p><a class="adsimple-321244596"
          href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=321244596" target="_blank"
          rel="noopener noreferrer">Microsoft Edge: Löschen und Verwalten von Cookies</a></p>
    <p>Automattic ist aktiver Teilnehmer beim EU-U.S. Privacy Shield Framework, wodurch der korrekte und sichere
      Datentransfer persönlicher Daten geregelt wird. Mehr Informationen dazu finden Sie auf <a
          class="adsimple-321244596" href="https://www.privacyshield.gov/participant?id=a2zt0000000CbqcAAC"
          target="_blank" rel="noopener noreferrer">https://www.privacyshield.gov/participant?id=a2zt0000000CbqcAAC</a>.<br/>
      Mehr Details zur Datenschutzrichtlinie und welche Daten auf welche Art durch WooCommerce erfasst werden, finden
      Sie auf <a class="adsimple-321244596" href="https://automattic.com/privacy/?tid=321244596" target="_blank"
                 rel="noopener noreferrer">https://automattic.com/privacy/ </a>und allgemeine Informationen zu
      WooCommerce auf <a class="adsimple-321244596" href="https://woocommerce.com/?tid=321244596" target="_blank"
                         rel="noopener noreferrer">https://woocommerce.com/</a>.</p>
    <h2>Sofortüberweisung Datenschutzerklärung</h2>
    <p>Wir bieten auf unserer Website die Zahlungsmethode „Sofortüberweisung“ des Unternehmens Sofort GmbH zur
      bargeldlosen Bezahlung an. Die Sofort GmbH gehört seit 2014 zum schwedischen Unternehmen Klarna, hat aber ihren
      Firmensitz in Deutschland, Theresienhöhe 12, 80339 München.</p>
    <p>Entscheiden Sie sich für diese Zahlungsmethode werden unter anderem auch personenbezogene Daten an die Sofort
      GmbH bzw. an Klarna übermittelt, gespeichert und dort verarbeitet. Mit diesem Datenschutztext geben wir Ihnen
      einen Überblick über die Datenverarbeitung durch die Sofort GmbH.</p>
    <h3>Was ist eine „Sofortüberweisung“?</h3>
    <p>Bei der Sofortüberweisung handelt es sich um ein Online-Zahlungssystem, das es Ihnen ermöglicht eine Bestellung
      über Online-Banking durchzuführen. Dabei wird die Zahlungsabwicklung durch die Sofort GmbH durchgeführt und wir
      erhalten sofort eine Information über die getätigte Zahlung. Diese Methode kann jeder User nutzen, der ein aktives
      Online-Banking-Konto mit PIN und TAN hat. Nur noch wenige Banken unterstützen diese Zahlungsmethode noch
      nicht.</p>
    <h3>Warum verwenden wir „Sofortüberweisung“ auf unserer Website?</h3>
    <p>Unser Ziel mit unserer Website und unserem eingebundenen Onlineshop ist es, Ihnen das bestmögliche Service zu
      bieten. Dazu zählt neben dem Gesamterlebnis auf der Website und neben unseren Angeboten auch eine reibungslose,
      schnelle und sichere Zahlungsabwicklung Ihrer Bestellungen. Um das zu gewährleisten, nutzen wir
      „Sofortüberweisung“ als Zahlungssystem.</p>
    <h3>Welche Daten werden von „Sofortüberweisung“ gespeichert?</h3>
    <p>Wenn Sie über den Sofort/Klarna-Dienst eine Sofortüberweisung durchführen, werden Daten wie Name, Kontonummer,
      Bankleitzahl, Betreff, Betrag und Datum auf den Servern des Unternehmens gespeichert. Diese Informationen erhalten
      auch wir über die Zahlungsbestätigung.</p>
    <p>Im Rahmen der Kontodeckungsprüfung überprüft die Sofort GmbH, ob Ihr Kontostand und Überziehungskreditrahmen den
      Zahlungsbeitrag abdeckt. In manchen Fällen wird auch überprüft, ob in den letzten 30 Tagen Sofortüberweisungen
      erfolgreich durchgeführt wurden. Weiters wird Ihre User-Identifikation (wie etwa Verfügernummer oder
      Vertragsnummer) in gekürzter („gehashter“) Form und Ihre IP-Adresse erhoben und gespeichert. Bei
      SEPA-Überweisungen wird auch BIC und IBAN gespeichert.</p>
    <p>Laut dem Unternehmen werden sonst keine weiteren personenbezogenen Daten (wie Kontostände, Umsatzdaten,
      Verfügungsrahmen, Kontolisten, Mobiltelefonnummer, Authentifizierungszertifikate, Sicherheitscodses oder PIN/TAN)
      erhoben, gespeichert oder an Dritte weitergegeben.</p>
    <p>Sofortüberweisung nutzt auch Cookies, um den eigenen Dienst benutzerfreundlicher zu gestalten. Wenn Sie ein
      Produkt bestellen, werden Sie auf die Sofort bzw. Klarna-Website umgeleitet. Nach der erfolgreichen Zahlung werden
      Sie auf unsere Dankesseite weitergeleitet. Hier werden folgende drei Cookies gesetzt:</p>
    <p><strong>Name</strong>: SOFUEB<br/>
      <strong>Wert: </strong>e8cipp378mdscn9e17kajlfhv7321244596-5<br/>
      <strong>Verwendungszweck:</strong> Dieses Cookie speichert Ihre Session-ID.<br/>
      <strong>Ablaufdatum:</strong> nach Beenden der Browsersitzung</p>
    <p><strong>Name</strong>: User[user_cookie_rules] <strong>Wert: </strong>1<br/>
      <strong>Verwendungszweck:</strong> Dieses Cookie speichert Ihre Zustimmung zur
      Verwendung von Cookies.<br/>
      <strong>Ablaufdatum:</strong> nach 10 Jahren</p>
    <p><strong>Name: </strong>_ga<br/>
      <strong>Wert: </strong>GA1.2.69759879.1589470706<br/>
      <strong>Verwendungszweck:</strong> Standardmäßig verwendet analytics.js das Cookie _ga,
      um die User-ID zu speichern. Grundsätzlich dient es zur Unterscheidung der Webseitenbesucher. Hier handelt es sich
      um ein Cookie von Google Analytics.<br/>
      <strong>Ablaufdatum:</strong> nach 2 Jahren</p>
    <p><strong>Anmerkung:</strong> Die hier angeführten Cookies erheben keinen Anspruch auch
      Vollständigkeit. Es kann immer sein, dass Sofortüberweisung auch andere Cookies verwendet.</p>
    <h3>Wie lange und wo werden die Daten gespeichert?</h3>
    <p>Alle erhobenen Daten werden innerhalb der rechtlichen Aufbewahrungspflicht gespeichert. Diese Pflicht kann
      zwischen drei und zehn Jahren dauern.</p>
    <p>Klarna/Sofort GmbH versucht Daten nur innerhalb der EU bzw. des Europäischen Wirtschaftsraums (EWR) zu speichern.
      Wenn Daten außerhalb des EU/EWR übertragen werden, muss der Datenschutz mit der DSGVO übereinstimmen, das Land in
      einer Angemessenheitsentscheidung der EU stehen oder das US-Privacy-Shield-Zertifikat besitzen.</p>
    <h3>Wie kann ich meine Daten löschen oder die Datenspeicherung verhindern?</h3>
    <p>Sie können Ihre Einwilligung, dass Klarna personenbezogene Daten verarbeitet jederzeit widerrufen. Sie haben auch
      immer das Recht auf Auskunft, Berichtigung und Löschung Ihrer personenbezogenen Daten. Dafür können Sie einfach
      das Datenschutzteam des Unternehmens per E-Mail an datenschutz@sofort.com kontaktieren.</p>
    <p>Mögliche Cookies, die Sofortüberweisung verwendet, können Sie in Ihrem Browser verwalten, löschen oder
      deaktivieren. Abhängig von Ihrem bevorzugten Browser funktioniert das auf unterschiedliche Weise. Die folgenden
      Anleitungen zeigen wie Sie Cookies in den gängigsten Browsern verwalten:</p>
    <p><a class="adsimple-321244596" href="https://support.google.com/chrome/answer/95647?tid=321244596" target="_blank"
          rel="noopener noreferrer">Chrome: Cookies in Chrome löschen, aktivieren und verwalten</a></p>
    <p><a class="adsimple-321244596" href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=321244596"
          target="_blank" rel="noopener noreferrer">Safari: Verwalten von Cookies und Websitedaten mit Safari</a></p>
    <p><a class="adsimple-321244596"
          href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=321244596"
          target="_blank" rel="noopener noreferrer">Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf
      Ihrem Computer abgelegt haben</a></p>
    <p><a
          href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=321244596"
          target="_blank" rel="noopener noreferrer">Internet Explorer: Löschen und Verwalten von Cookies</a></p>
    <p><a
          href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=321244596" target="_blank"
          rel="noopener noreferrer">Microsoft Edge: Löschen und Verwalten von Cookies</a></p>
    <p>Wenn Sie mehr über die Datenverarbeitung durch die „Sofortüberweisung“ des Unternehmens Sofort GmbH erfahren
      wollen, empfehlen wir Ihnen die Datenschutzerklärung unter <a
                                                                    href="https://www.sofort.de/datenschutz.html?tid=321244596">https://www.sofort.de/datenschutz.html</a>.
    </p>
    <h2>jsdelivr.com-CDN Datenschutzerklärung</h2>
    <p>Damit wir Ihnen unsere einzelnen Webseiten schnell und auf allen unterschiedlichen Geräten einwandfrei ausliefern
      können, verwenden wir die Open-Source-Dienste von <a
                                                           target="_blank"
                                                           rel="follow noopener noreferrer">jsdelivr.com</a> des
      polnischen Software-Unternehmens ProspectOne, Królewska 65A/1, 30-081, Kraków, Polen.</p>
    <p>Dabei handelt es sich um ein Content Delivery Network (CDN). Das ist ein Netzwerk regional verteilter Server, die
      über das Internet verbunden sind. Dadurch können Inhalte, speziell große Dateien, auch bei großen Lastspitzen
      schnell und optimal ausgeliefert werden.</p>
    <p>jsDelivr ist so aufgebaut, dass JavaScript-Bibliotheken heruntergeladen werden können, die auf npm- und
      Github-Servern gehostet werden. Es können aber auch WordPress-Plugins geladen werden, sofern diese auf <a
          class="adsimple-321244596" href="https://wordpress.org/" target="_blank" rel="follow noopener noreferrer">WordPress.org</a>
      gehostet werden. Um diesen Dienst bereitstellen zu können, kann Ihr Browser personenbezogene Daten an <a
          class="adsimple-321244596" href="https://www.jsdelivr.com/?tid=321244596" target="_blank" rel="noopener">jsdelivr.com</a>
      senden. jsDelivr kann somit Userdaten wie IP-Adresse, Browsertyp, Browserversion, welche Webseite geladen wird
      oder Uhrzeit und Datum des Seitenbesuchs sammeln und speichern. In der Datenschutzerklärung von <a
          class="adsimple-321244596" href="https://www.jsdelivr.com/" target="_blank" rel="follow noopener noreferrer">jsdelivr.com</a>
      wird ausdrücklich darauf hingewiesen, dass das Unternehmen keine Cookies oder andere Tracking-Services verwendet.
    </p>
    <p>Wenn Sie diese Datenübertragung unterbinden wollen, können Sie einen JavaScript-Blocker (siehe beispielsweise <a
        class="adsimple-321244596" href="https://noscript.net/" target="_blank" rel="noopener noreferrer">https://noscript.net/</a>)
      installieren. Bitte beachten Sie aber, dass dadurch die Website nicht mehr das gewohnte Service (wie etwa schnelle
      Ladegeschwindigkeit) bieten kann.</p>
    <p>Nähere Informationen zur Datenverarbeitung durch den Softwaredienst jsDelivr finden Sie in der
      Datenschutzerklärung des Unternehmens unter <a
                                                     href="https://www.jsdelivr.com/privacy-policy-jsdelivr-net?tid=321244596"
                                                     target="_blank" rel="noopener noreferrer">https://www.jsdelivr.com/privacy-policy-jsdelivr-net</a>.
    </p>
    <h2>BootstrapCDN Datenschutzerklärung</h2>
    <p>Um Ihnen all unsere einzelnen Webseiten (Unterseiten unserer Website) auf allen Geräten schnell und sicher
      ausliefern zu können, nutzen wir das Content Delivery Network (CDN) BootstrapCDN des amerikanischen
      Software-Unternehmens StackPath, LLC 2012 McKinney Ave. Suite 1100, Dallas, TX 75201, USA.</p>
    <p>Ein Content Delivery Network (CDN) ist ein Netzwerk regional verteilter Server, die über das Internet miteinander
      verbunden sind. Durch dieses Netzwerk können Inhalte, speziell sehr große Dateien, auch bei großen Lastspitzen
      schnell ausgeliefert werden.</p>
    <p>BootstrapCDN funktioniert so, dass sogenannte JavaScript-Bibliotheken an Ihren Browser ausgeliefert werden. Lädt
      nun Ihr Browser eine Datei vom BootstrapCDN herunter, wird Ihre IP-Adresse während der Verbindung zum
      Bootstrap-CDN-Server an das Unternehmen StockPath übermittelt.</p>
    <p>StackPath erwähnt auch in der hauseigenen Datenschutzerklärung, dass das Unternehmen aggregierte und
      anonymisierte Daten von diversen Diensten (wie BootstrapCDN) für die Erweiterung der Sicherung und für andere
      StackPath-Dienste und Clients verwenden. All diese Daten können aber keine Person identifizieren.</p>
    <p>Wenn Sie diese Datenübertragung unterbinden wollen, können Sie einen JavaScript-Blocker (siehe beispielsweise <a
        class="adsimple-321244596" href="https://noscript.net/" target="_blank" rel="noopener noreferrer">https://noscript.net/</a>)
      installieren oder in Ihrem Browser die Ausführung von JavaScript-Codes deaktivieren. Bitte beachten Sie aber, dass
      dadurch die Website nicht mehr das gewohnte Service (wie etwa schnelle Ladegeschwindigkeit) bieten kann.</p>
    <p>StackPath ist aktiver Teilnehmer beim EU-U.S. Privacy Shield Framework, wodurch der korrekte und sichere
      Datentransfer persönlicher Daten geregelt wird. Mehr Informationen dazu finden Sie auf <a
          class="adsimple-321244596"
          href="https://www.privacyshield.gov/participant?id=a2zt0000000CbahAAC&amp;status=Active" target="_blank"
          rel="follow noopener noreferrer">https://www.privacyshield.gov/participant?id=a2zt0000000CbahAAC&amp;status=Active</a>.<br/>
      Mehr Informationen zum Datenschutz bei StackPath bzw. BootstrapCDN finden Sie auf <a
                                                                                           href="https://www.bootstrapcdn.com/privacy-policy/?tid=321244596"
                                                                                           target="_blank"
                                                                                           rel="noopener">https://www.bootstrapcdn.com/privacy-policy/</a>.
    </p>
    <h2>jQuery CDN Datenschutzerklärung</h2>
    <p>Um Ihnen unsere Website bzw. all unsere einzelnen Unterseiten (Webseiten) auf unterschiedlichen Geräten schnell
      und problemlos auszuliefern, nutzen wir Dienste von jQuery CDN des Unternehmens jQuery Foundation. jQuery wird
      über das Content Delivery Network (CDN) des amerikanischen Software-Unternehmens StackPath (LCC 2012 McKinney Ave.
      Suite 1100, Dallas, TX 75201, USA) verteilt. Durch diesen Dienst werden personenbezogene Daten von Ihnen
      gespeichert, verwaltet und verarbeitet.</p>
    <p>Ein Content Delivery Network (CDN) ist ein Netzwerk regional verteilter Server, die über das Internet miteinander
      verbunden sind. Durch dieses Netzwerk können Inhalte, speziell sehr große Dateien, auch bei großen Lastspitzen
      schnell ausgeliefert werden.</p>
    <p>jQuery nutzt JavaScript-Bibliotheken, um unsere Website-Inhalte zügig ausliefern zu können. Dafür lädt ein
      CDN-Server die nötigen Dateien. Sobald eine Verbindung zum CDN-Server aufgebaut ist, wird Ihre IP-Adresse erfasst
      und gespeichert. Das geschieht nur, wenn diese Daten nicht schon durch einen vergangenen Websitebesuch in Ihrem
      Browser gespeichert sind.</p>
    <p>In den Datenschutz-Richtlinien von StackPath wird ausdrücklich erwähnt, dass StackPath aggregierte und
      anonymisierte Daten von diversen Diensten (wie eben auch jQuery) für die Erweiterung der Sicherheit und für eigene
      Dienste benutzen. Diese Daten können Sie als Person allerdings nicht identifizieren.</p>
    <p>Wenn Sie nicht wollen, dass es zu dieser Datenübertragung kommt, haben Sie immer auch die Möglichkeit
      Java-Scriptblocker wie beispielsweise <a
                                               target="_blank" rel="follow noopener noreferrer">ghostery.com</a> oder <a
          class="adsimple-321244596" href="https://noscript.net/" target="_blank" rel="follow noopener noreferrer">noscript.net</a>
      zu installieren. Sie können aber auch einfach in Ihrem Browser die Ausführung von JavaScript-Codes deaktivieren.
      Wenn Sie sich für die Deaktivierung von JavaScript-Codes entscheiden, verändern sich auch die gewohnten
      Funktionen. So wird beispielsweise eine Website nicht mehr so schnell geladen.</p>
    <p>StackPath ist aktiver Teilnehmer beim EU-U.S. Privacy Shield Framework, wodurch der korrekte und sichere
      Datentransfer persönlicher Daten geregelt wird. Mehr Informationen dazu finden Sie auf <a
          class="adsimple-321244596"
          href="https://www.privacyshield.gov/participant?id=a2zt0000000CbahAAC&amp;status=Active" target="_blank"
          rel="follow noopener noreferrer">https://www.privacyshield.gov/participant?id=a2zt0000000CbahAAC&amp;status=Active</a>.<br/>
      Mehr Informationen zum Datenschutz bei StackPath finden Sie unter <a
                                                                           href="https://www.stackpath.com/legal/privacy-statement/?tid=321244596"
                                                                           target="_blank" rel="noopener noreferrer">https://www.stackpath.com/legal/privacy-statement/</a>
      und zu jQuery unter <a
                             href="https://openjsf.org/wp-content/uploads/sites/84/2019/11/OpenJS-Foundation-Privacy-Policy-2019-11-15.pdf"
                             target="_blank" rel="follow noopener noreferrer">https://openjsf.org/wp-content/uploads/sites/84/2019/11/OpenJS-Foundation-Privacy-Policy-2019-11-15.pdf</a>.
    </p>
    <p>Quelle: Erstellt mit dem <a title="Datenschutz Generator Deutschland"
                                   href="https://www.adsimple.de/datenschutz-generator/" target="_blank"
                                   rel="follow noopener">Datenschutz Generator</a> von AdSimple in Kooperation mit <a
        title="" href="https://www.fashiongott.de" target="_blank" rel="follow noopener">fashiongott.de</a></p>
  </b-container>
</template>

<script>
export default {
  name: "Datenschutz",
  data() {
    return {
      name: 'Susan Treppner',
      address: 'Käthe-Kollwitz-Str. 13',
      city: '07743 Jena',
      country: '',
      uid: '162/282/19111',
      tel: '',
      fax: '',
      email: 'kontakt@susanbewegt.de',
      titel: 'B.A. Sportwissenschaftlerin, Personal Trainerin',
      imagerights: [
        {
          name:'Eric Wittkopf',
          url:'https://www.ericwittkopf.com',
        },
        {
          name:'Christoph Hoffmann',
          url:'https://instagram.com/betweenthebarsphotography',
        },
        {
          name:'Samir Haider',
          url:'https://instagram.com/unbrokenpictures',
        },
        {
          name:'Christoph Worsch',
          url:'https://instagram.com/christophworsch/',
        },
        {
          name:'Tobi Wagner',
          url:'https://tobiwagner.com',
        },
      ],
      iconrights: [
        {
          name:'iconixar',
          url:'https://www.flaticon.com/de/autoren/iconixar',
        },
        {
          name:'srip',
          url:'https://www.flaticon.com/de/autoren/srip',
        },
        {
          name:'Smashicons',
          url:'https://www.flaticon.com/de/autoren/smashicons',
        },
      ]
    }
  },
  computed: {
    rights() {
      return [].concat(this.imagerights).concat(this.iconrights)
    }
  }
}
</script>

<style scoped>

</style>