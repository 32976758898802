export default {
    navbar: {
        home: "Home",
        'about me': "Über mich",
        services: "Für euch",
        datenschutz: 'Datenschutz & Impressum',
    },
    home: {
        'about me': "Über mich",
        hi: 'Hi!',
        greet: 'Schön, dass du hier bist. Willkommen in meiner Welt - Susan',
        whoami: 'Ich bin Susan, Sportlerin, studierte Sportwissenschaftlerin und Fitness Trainerin. ' +
            'Sport und Fitness begleiten mich mein ganzes Leben und sind für mich meine Berufung. ' +
            'Ich liebe es Menschen mit meiner Energie für Bewegung inspirieren zu können. ' +
            'Gerne würde ich auch Dich auf Deinem Weg in einen gesünderen Lebensstil begeistern. ' +
            'Mit einem individuellen Trainingsprogramm begleite ich Dich auf Deinem Weg zum Ziel.',
        idliketohearfromyou: 'Ich freue mich, von Dir zu hören.',
        more: 'Mehr erfahren',
        twainquote: 'Das Geheimnis des Erfolgs ist anzufangen.',
        foryou: 'Für Euch',
        personaltraining: 'Personaltraining',
        kleingruppentraining: 'Kleingruppentraining',
        onlinetraining: 'Onlinetraining',
        vereinstraining: 'Vereinstraining',
    },
    about: {
        greet: 'Hallo',
        name: 'mein Name ist Susan Treppner ' +
            'und ich bin studierte Sportwissenschaftlerin und Fitnesstrainerin in und um Jena.',
        bio: 'Schon als kleines Kind haben sich mein Ehrgeiz und meine Liebe zum Sport gezeigt. ' +
            'Begonnen hat meine Reise mit 6 Jahren im Akrobatikverein. ' +
            'Später probierte ich mich auch in Volleyball und im Kampfsportbereich aus. ' +
            'Schnell wurde mir klar, dass ich die Leidenschaft für Bewegung gerne zum Beruf machen möchte ' +
            'und entschied mich für das Studium der Sportwissenschaften.',
        crossfit: 'In dieser Zeit entdeckte ich meine große Leidenschaft für den Sport Cross-Training, ' +
            'welcher so ziemlich alle Sportarten miteinander vereint. ' +
            'Egal ob Turnen, Laufen, Gewichtheben oder Schwimmen - in dieser Sportart wird alles miteinander verbunden. ' +
            'Ich war schon immer ein Mensch der die Herausforderung und den Wettkampf sucht. ' +
            'So begann ich auch im Cross-Training als Athletin und als Trainerin ambitioniert an mir zu arbeiten. ' +
            '2020 wurde ich fünftbeste deutsche Cross-Training-Athletin. ' +
            'Bei internationalen Wettkämpfen in Belgien, den Niederlanden, oder Österreich konnte ich bis jetzt ' +
            'mindestens eine Top-10-Platzierung erreichen. ' +
            'Neben den Wettkämpfen gebe ich mein Wissen über das funktionelle Training schon seit 6 Jahren als ' +
            'Trainerin an meine Sportler weiter. ' +
            'So durfte ich auch schon die erste Damenmannschaft im Basketball des USV Jena trainieren.',
        lifestyle: 'Die Ganzheitlichkeit des Trainings und die Begeisterung für Bewegung versuche ich jeden Tag ' +
            'an meine Kunden weiterzugeben. Für mich ist Personal Training nicht nur eine Einheit mit Dir, ' +
            'sondern vielmehr der gemeinsame Weg zu einem gesunden und ausgewogenen Lebensstil.',
    },
    services: {
        bewegen: 'Für alle die bewegt werden wollen.',
        services: 'Leistungen',
        prices: 'Preise',
        personaltraining: '<p>Genauso individuell wie Du, genauso so persönlich ist auch meine 1:1 Personal Training Betreuung für Dich.</p>' +
            '<p>Egal welches gesundheitliche oder sportliche Ziel du verfolgst, ich helfe Dir Dein ganz persönliches Ziel zu erreichen. ' +
            'Gemeinsam erarbeiten wir ein auf Dich abgestimmtes Training, das sich in der Umsetzung Deiner persönlichen ' +
            'Lebenssituation und Deinen zeitlichen Ressourcen anpasst. Dabei ist es egal, wie jung oder alt Du Dich ' +
            'fühlst, ich hole Dich genau dort ab, wo Du gerade stehst und sorge mit Dir für den gewünschten Erfolg ' +
            'und den Spaß an der Bewegung.</p>' +
            '<p>Du bist nicht mehr alleine! Gemeinsam gehen wir den Weg zu einem fitteren und aktiveren Selbst.</p>',
        onlinetraining: ' <p>In meinem Online Coaching arbeiten wir auch ortsungebunden an Deinen individuellen Zielen. ' +
            'Während eines gemeinsamen Anamnesegespräches lernen wir uns kennen, legen Deine Trainingsmöglichkeiten sowie ' +
            'Deine Ziele fest und erarbeiten einen gemeinsamem Trainingsplan.</p>' +
            '<p>Egal wo Du lebst, ob Du auf einer Geschäftsreise bist oder im Urlaub - Dein Trainingsplan wird auf ' +
            'jede Situation abgestimmt und angepasst. So ist es Dir möglich Deinen Trainingsplan optimal in Deinen Alltag ' +
            'zu integrieren.</p>' +
            '<p>Durch intensiven Kontakt mittels Videokonferenzen, Telefonaten oder Nachrichten ist es mir möglich ' +
            ' Dein individuelles Trainingsdesign noch spaßiger und effektiver zu gestalten. Ich würde mich freuen durch ' +
            'mein Online Coaching Teil Deines Alltags und einer gesünderen Lebensweise sein zu dürfen.</p>',
        vereinstraining: '<p>Hinter dem Erfolg im Sport – egal ob im Leistungs- oder im Freizeitsport, steht ein ganzheitliches ' +
            'Training. Athletiktraining bietet das optimale Ausgleichs- und Aufbautraining zum Erreichen Eurer ' +
            'Ziele.</p>' +
            '<p>Ich biete Euch individuell angepasste Übungseinheiten zum Ausgleich eurer Schwachstellen und zur ' +
            'Förderung von Explosivität, Stabilität, Beweglichkeit und Kraft. Gerne komme ich für das Training ' +
            'auch in Eure (Vereins-) Räumlichkeiten oder wir verlegen die Trainingseinheiten in ein ausgewähltes ' +
            'Fitnessstudio.</p> ' +
            '<p>Lasst uns Euch gemeinsam optimal auf Eure Saison vorbereiten.</p>',
        kleingruppentraining: '<p>Du möchtest lieber mit Deinem Partner oder Deinen Freunden zusammen trainieren? Dann ist das ' +
            'Kleingruppentraining genau das Richtige für Dich!</p>' +
            '<p>Gemeinsam in einer Gruppe von 2 bis 5 Personen könnt Ihr Euch gegenseitig motivieren und Euch zu ' +
            'persönlichen Bestleistungen anspornen. Die Trainingseinheit kann sowohl aus offenen als auch ' +
            'geschlossenen Trainingseinheiten bestehen. Falls Du niemanden in Deinem persönlichen Umfeld hierfür ' +
            'motivieren kannst, besteht auch die Möglichkeit, Teil einer bereits bestehenden offenen Gruppe zu werden.</p>' +
            '<p>Freu Dich auf ein abwechslungsreiches Training, gemischt aus funktionellem Training, sowie Ausdauer- ' +
            'und Krafteinheiten, welches dem Einzeltraining in nichts nachsteht.</p>',
        pricefactores: 'Der Preis setzt sich aus den unten aufgeführten Faktoren zusammen und ist genauso individuell, wie Du es bist.',
        factors: {
            ort: {
                trainingsort: 'Trainingsort',
                factors: {
                    outdoors: 'Draußen an der frischen Luft',
                    home: 'Bei dir Zuhause',
                    fitness: 'In der nächstgelegenen Fitnessanlage',
                }
            },
            umfang: {
                trainingsumfang: 'Trainingsumfang',
                factors: {
                    length: 'Wie lange ist unsere gemeinsame Reise',
                    einheiten: 'Wie viele Einheiten absolvieren wir',
                }
            },
            dauer: {
                trainingsdauer: 'Trainingsdauer',
                factors: {
                    dauer: 'Wie lang ist unsere gemeinsame Trainingszeit'
                }

            }
        },
        beingpartofyourlife: 'Ich freue mich jetzt schon auf Deine Anfrage und Teil Deines bewegten Lebens zu sein.',

    },
    pagenotfound: {
        message: 'Die gewünschte Seite konnte leider nicht gefunden werden.'
    }
}
