<template>
  <b-card>
    <b-avatar style="bottom: 2.5rem">
      <b-icon-chat-quote-fill font-scale="3"/>
    </b-avatar>
    <b-card-body>
      <b-card-title>{{ text }}</b-card-title>
      <b-card-text class="text-center" v-if="author !== undefined">{{ author }}</b-card-text>
    </b-card-body>
  </b-card>
</template>

<script>
export default {
  name: "Quote",
  props: {
    text: {
      type: String,
      required: true,
    },
    author: {
      type: String,
      required: false,
    }
  }
}
</script>

<style scoped>
.card {
  padding: 0;
  font-family: 'Dosis', sans-serif;
  background: rgba(69, 68, 68, 0.6);
  width: 80%;
}

.card-body {
  text-align: center;
  color: white;
}

.card-title {
  font-size: 2rem;
  font-weight: bold;
}

.card-text {
  font-size: 1.5rem;
  font-style: italic;
}
</style>