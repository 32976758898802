<template>
  <div>
    <b-navbar toggleable="lg" class="align-items-center" fixed="top">
      <b-img class='mt-2 mb-2 bg-image'
             :src="require('../assets/Susan_bewegt_nebeneinander_Pantone_424_Linie_transparent.svg')"
             fluid :width="width"/>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav style="margin:auto">
          <b-nav-item v-for="(item, i) in items" :key="i" :to="'/' + $i18n.locale + item.path" exact>{{ $t('navbar.'+ item.name) }}</b-nav-item>
        </b-navbar-nav>
        <language-switcher/>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import LanguageSwitcher from "@/components/LanguageSwitcher";
export default {
  name: "navbar",
  components: {LanguageSwitcher},
  data() {
    return {
      items: [{
        name: 'home',
        path: '/',
      },
        {
          name: 'about me',
          path: '/ueber-mich',
        },
        {
          name: 'services',
          path: '/fuer-euch',
        }],
      width: '400px',
      fixed: true,
    }
  },
  created() {
    this.$router.options.routes.filter(x => x.navbar).forEach(route => {
      this.items.push({
        name: route.name,
        path: route.path
      })
    })
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll)
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll)
  },
  methods: {
    onScroll() {
      if (window.scrollY > 25) {
        this.fixed = false;
        this.width = '250px'
      } else {
        this.fixed = true;
        this.width = '400px'
      }
    }
  },
}
</script>

<style scoped>
.navbar {
  background: #FFF;
  display: block;
  text-align: center;
  -webkit-box-shadow: 0px -10px 20px 0px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 0px -10px 20px 0px rgba(0, 0, 0, 0.35);
  box-shadow: 0px -10px 20px 0px rgba(0, 0, 0, 0.35);
}

.nav-link {
  color: #5b686d !important;
  text-transform: uppercase;
  font-size: .8rem;
}

.router-link-active {
  color: #edb059 !important;
}


.navbar.center .navbar-inner {
  text-align: center;
}

.navbar.center .navbar-inner .nav {
  display: inline-block;
  float: none;
}

.bg-image {
  transition: width 0.25s ease-in;
}
</style>