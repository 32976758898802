<template>
  <b-container style="height: 500px" >
<!--    <circle-text text="Kontakt"/>-->
    <b-card>
      <b-card-body class="text-center">
        <b-card-img :src="require('@/assets/Susan bewegt untereinander Pantone 424 Linie transparent.svg')"
                    style="max-height: 185px; max-width: 512px"
        />
        <b-card-text style="text-align: center">
          <hr />
          <a href="mailto:kontakt@susanbewegt.de?subject=Kontaktanfrage&body=Hallo%20Susan%2C%0D%0A%0D%0Aich%20habe%20Interesse%20an%20einer%20Zusammenarbeit.">
            kontakt@susanbewegt.de
          </a>
        </b-card-text>
      </b-card-body>
    </b-card>
  </b-container>
</template>

<script>
// import CircleText from "@/components/CircleText";
export default {
  name: "Kontakt",
  // components: {CircleText}
}
</script>

<style scoped>
.card {
  position: absolute!important;
  bottom: 50%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 50%;
  transform: translate(50%, 50%);
}
a {
  font-size: 2rem;
  color: #edb059;
}a:hover {
  color: #edb059;
}
</style>