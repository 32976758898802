<template>
  <div>
    <!--  Move  -->
    <section>
      <b-card bg-variant="secondary" text-variant="white" class="text-center no-round-border"
              style="height: 50vh;">
        <b-card-body class="vh-center" style="width: 100%">
          <b-card-text style="text-align: center">
            <span class="moving" style="line-height: 50px; letter-spacing: 5px; font-size: 1.2rem">
                Für Alle die <bewegt :color="'white'"/> werden wollen.
            </span>
            <b-container class="text-center mt-4">
              <b-btn class="mr-5 big-btn" @click="scrollTo('leistung')">{{ $t('services.services') }}</b-btn>
              <b-btn class="big-btn" @click="scrollTo('preis')">{{ $t('services.prices') }}</b-btn>
            </b-container>
          </b-card-text>
        </b-card-body>
      </b-card>
    </section>

    <!-- Features -->
    <section ref="leistung">
      <CircleText :text="$t('services.services')"/>
      <b-container>
        <b-row>
          <b-col lg="6">
            <b-card>
              <b-card-body>
                <b-card-img
                    :src="require('@/assets/Susan-Treppner-2020-Cross-Training-Foto-Christoph-Worsch-148-980x653.jpg')"
                    class="mb-2"
                />
                <b-card-title class="myFont text-center">{{ $t('home.personaltraining') }}</b-card-title>
                <b-card-text v-html="$t('services.personaltraining')"></b-card-text>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col lg="6">
            <b-card>
              <b-card-body>
                <b-card-img
                    :src="require('@/assets/pexels-karolina-grabowska-4498476-scaled.jpg')"
                    class="mb-2"
                />
                <b-card-title class="myFont text-center">{{ $t('home.onlinetraining') }}</b-card-title>
                <b-card-text v-html="$t('services.onlinetraining')"></b-card-text>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="6">
            <b-card>
              <b-card-body>
                <b-card-img
                    :src="require('@/assets/Susan-Treppner-2020-Cross-Training-Foto-Christoph-Worsch-226-scaled.jpg')"
                    class="mb-2"
                />
                <b-card-title class="myFont text-center">{{ $t('home.vereinstraining') }}</b-card-title>
                <b-card-text v-html="$t('services.vereinstraining')"></b-card-text>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col lg="6">
            <b-card>
              <b-card-body>
                <b-card-img :src="require('@/assets/Susan-Treppner-2020-Cross-Training-Foto-Christoph-Worsch-152-980x652.jpg')"
                            class="mb-2"
                />
                <b-card-title class="myFont text-center">{{ $t('home.kleingruppentraining') }}</b-card-title>
                <b-card-text v-html="$t('services.kleingruppentraining')">
                </b-card-text>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <!-- Prices -->
    <section ref="preis">
      <CircleText :text="$t('services.prices')"/>
      <b-container class="text-center">
        <b-card>
          <b-card-body>
            <b-card-text class="text-center mt-4" style="font-size: 1.2rem;">
              {{ $t('services.pricefactores') }}
            </b-card-text>
          </b-card-body>
        </b-card>
        <b-row>
          <b-col lg="4" v-motion-pop-visible :delay="100">
            <b-card v-motion-pop-visible :delay="100">
              <b-card-body>
                <b-card-img :src="require('@/assets/kompass_orange-150x150.png')"
                            style="width: 150px; height: 150px;"
                            alt="Kompass"/>
                <b-card-title class="myFont text-center">{{ $t('services.factors.ort.trainingsort') }}</b-card-title>
                <b-card-text>
                  <ul class="myListStyle">
                    <li>{{ $t('services.factors.ort.factors.outdoors') }}</li>
                    <li>{{ $t('services.factors.ort.factors.home') }}</li>
                    <li>{{ $t('services.factors.ort.factors.fitness') }}</li>
                  </ul>
                </b-card-text>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col lg="4" v-motion-pop-visible :delay="100">
            <b-card>
              <b-card-body>
                <b-card-img :src="require('@/assets/massband_orange-150x150.png')"
                            style="width: 150px; height: 150px;"
                />
                <b-card-title class="myFont text-center">{{ $t('services.factors.umfang.trainingsumfang') }}</b-card-title>
                <b-card-text>
                  <ul class="myListStyle">
                    <li>{{ $t('services.factors.umfang.factors.length') }}</li>
                    <li>{{ $t('services.factors.umfang.factors.einheiten') }}</li>
                  </ul>
                </b-card-text>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col lg="4" v-motion-pop-visible :delay="100">
            <b-card>
              <b-card-body>
                <b-card-img :src="require('@/assets/wanduhr_orange-150x150.png')"
                            style="width: 150px; height: 150px;"
                />
                <b-card-title class="myFont text-center">{{ $t('services.factors.dauer.trainingsdauer') }}</b-card-title>
                <b-card-text>
                  <ul class="myListStyle">
                    <li>{{ $t('services.factors.dauer.factors.dauer') }}</li>
                  </ul>
                </b-card-text>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <!--  Quote  -->
    <section class="transparent" style="height: 600px">
      <BackgroundImage :img="image">
        <b-container style="height: 600px">
          <quote :text="$t('services.beingpartofyourlife')"
                 class="vh-center"/>
        </b-container>
      </BackgroundImage>
    </section>

    <!--  Kontakt  -->
    <section>
      <Kontakt/>
    </section>
  </div>
</template>

<script>

import CircleText from "@/components/CircleText";
import Quote from "@/components/Quote";
import Kontakt from "@/components/Kontakt";
import Bewegt from "@/components/Bewegt";
import BackgroundImage from "@/components/BackgroundImage";

export default {
  name: 'Foryou',
  components: {BackgroundImage, Kontakt, Quote, CircleText, Bewegt},
  data() {
    return {
      image: require('@/assets/bb2.jpg'),
    }
  },
  methods: {
    scrollTo(to) {
      let el = this.$refs[to];
      if (el) {
        let offset = -90;
        let pos = el.getBoundingClientRect().top;

        window.scrollTo({
          top: offset + pos,
          behavior: 'smooth'
        });
      }
    }
  }
}
</script>

<style scoped>

ul.myListStyle {
  text-align: center;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

ul.myListStyle > li {
  padding: .5rem;
  margin: 0;
}

ul.myListStyle > li::after {
  content: '';
  border-bottom: inset 1px #000;
  width: 120px;
  display: block;
  margin: 20px auto auto;
}

ul.myListStyle > li:last-child::after {
  border: none;
}

.no-round-border {
  border-radius: 0;
}

.big-btn {
  color: #FFF;
  font-size: 1.3rem;
  padding: 0.6rem;
  border: 2px solid #FFF;
}

.card-title {
  margin-top: .6rem;
}
</style>