<template>
  <footer class="text-muted">
    <b-container>
      <b-nav>
        <b-nav-item v-for="(item, i) in items" :key="i" :to="item.path">{{item.name}}</b-nav-item>
        <b-nav-item href="https://www.instagram.com/susan_treppner/">
          <b-icon-instagram/>
        </b-nav-item>
        <b-nav-item :to=" '/' + $i18n.locale + '/datenschutz'">
          {{ $t('navbar.datenschutz') }}
        </b-nav-item>
        <b-nav-text  class="ml-auto">All rights reserved - Susan Bewegt ®</b-nav-text>
      </b-nav>
    </b-container>
  </footer>
</template>

<script>
export default {
  name: "FooterBar",
  data() {
    return {
      items: [],
    }
  },
}
</script>

<style scoped>
footer {
  padding: 1rem 0;
  background-color: #3f3f3f;
}

footer a {
  color: #edb059;
}
</style>