export default {
    navbar: {
        home: "Home",
        'about me': "About me",
        services: "Services",
        datenschutz: 'Privacy & Impressum',
    },
    home: {
        'about me': "About me",
        hi: 'Hi!',
        greet: 'Nice to see you here! Welcome to my world - Susan',
        whoami: 'My name is Susan, athlete, sports scientist and fitness coach. ' +
            'Sports and fitness accompanied me throughout my life and are my true calling. ' +
            'I love to inspire people with my energy and would like to be your companion on your path \n ' +
            'to a new healthy way of life by creating a training plan that fits your lifestyle.',
        idliketohearfromyou: 'I\'m looking forward to your inquiry!',
        more: 'More',
        twainquote: 'The secret of getting ahead is getting started.',
        foryou: 'For You',
        personaltraining: 'Personal Training',
        kleingruppentraining: 'Break-out Groups',
        onlinetraining: 'Online Training',
        vereinstraining: 'Club Training',
    },
    about: {
        greet: 'Hi',
        name: 'my name is Susan Treppner ' +
            'and I am a sports scientist and fitness coach in and around Jena.',
        bio: 'I discovered my love for sports early on in my life. ' +
            'My journey started at the age of six in acrobatics, ' +
            'later on I also played volleyball and practiced martial arts. ' +
            'I soon realised that I\'d like to turn my passion for sports into my profession ' +
            'and thus decided to study sports science.',
        crossfit: 'During my studies I found my great passion: cross training. cross training combines all sports into a single one. ' +
            'Exercising, running, lifting weights, or swimming - cross training unites them all. ' +
            'I have always been looking for challenges and competition and so I began to train and compete in cross training ' +
            'and in 2020 I came in 5th place of all german women\'s cross training athletes. ' +
            'Even in international competitions in Belgium, Netherlands, or Austria I always achieved at least ' +
            'a top 10 placement. ' +
            'Besides competing in sports I have been coaching others in functional training for over six years now. ' +
            'In one of these positions I was privileged to train the first women\'s basketball team at USV Jena. ',
        lifestyle: 'I am able to inspire my clients with the holistic nature of the training and my enthusiasm for movement. ' +
            'Being your personal trainer is not just another session for me - I am your companion to a new healthy and balanced way of life.',
    },
    services: {
        bewegen: 'Für alle die <bewegt :color="\'white\'"/> werden wollen.',
        services: 'Services',
        prices: 'Prices',
        personaltraining: '<p>My 1:1 personal training support for you is just as individual as you are.</p>' +
            '<p>No matter what health or athletic goal you are pursuing, I will help you achieve your own personal goal. ' + 'Together we will create workout plans that suit your busy life.' +
            'No matter how young or old you feel or are I\'ll help you achieve your personal goals. ' +
            '<p>You are no longer alone! From now on I am your companion on your journey to a fitter and more active lifestyle.</p>',

        onlinetraining:
            ' <p>No matter where you are in the world, we will work together on your individual goals! ' +
            'During our first anamnesis interview we get to know each other, determine your training possibilities and equipment as well as ' +
            'your goals and develop a personalized training plan</p>' +
            '<p>No matter where you live, whether on a business trip or on vacation - your training plan will always be updated to your current needs ' +
            ' and adapted to every situation. This enables you to optimally integrate your training plan into your daily routine.</p>' +
            '<p>Through regularly scheduled meetings like video conferences, phone calls or messages, ' +
            'I am able to monitor your individual training progress and can optimise your sessions to make them even more fun and effective. ' +
            'I\'d be happy to be a part of your healthier way of life through my online coaching.</p>',
        vereinstraining: '<p>The secret to success in sports - whether it\'s competetive or recreational - ' +
            'is a holistic training plan. ' +
            'Athletic training offers a well ballanced compensatory and advanced training to better ' +
            'reach your goals as a team. ' +
            '<p>I offer indivudually created training units to compensate for your team\'s shortcomings ' +
            'and stimulate your team\'s explosivity, stability, movement and strength. ' +
            'I am happy to visit you in your premisis for your training, or we can move the sessions to a ' +
            'fitness center of your choice.</p>' +
            'I am happy to help you prepare for your next season or event.',
        kleingruppentraining: '<p>You\'d rather like to train together with your partner or your friends? ' +
            'Than break-out groups are the right choice for you!</p>' +
            '<p>In a group between two and five people you can motivate each other to your personal bests.</p>' +
            '<p>These groups can either be open or closed. If you\'re unable to  motivate your friends in your ' +
            'personal life you can become part of an existing open group. ' +
            'You can look forward to diverse training sessions comprised of functional training with endurance and strength ' +
            'training sessions.</p>',
        pricefactores: 'Your price is made up of the factores listed below and is just as individual as you are.',
        factors: {
            ort: {
                trainingsort: 'Location',
                factors: {
                    outdoors: 'Outdoors',
                    home: 'At home',
                    fitness: 'Nearest fitness facility',
                }
            },
            umfang: {
                trainingsumfang: 'Scope',
                factors: {
                    length: 'How long will our journey be',
                    einheiten: 'How many sessions will we have',
                }
            },
            dauer: {
                trainingsdauer: 'Duration',
                factors: {
                    dauer: 'How long will our sessions be'
                }

            }
        },
        beingpartofyourlife: 'I\'m looking forward to your inquiry and being part of your journey to a healthy and balanced lifestyle.',

    },
    pagenotfound: {
        message: 'The requested page could not be found.'
    }

}
