<template>
  <div class="about">
    <!--  Images  -->
    <section>
      <b-row align-h="center" style="max-width: 100%; margin-left: 0;margin-right: 0">
        <b-col md="4" v-for="(img, i) in images" :key="i" style="padding-right: 0!important;padding-left: 0!important">
          <b-img class="img-center" height="auto" width="auto" :src="img"></b-img>
        </b-col>
      </b-row>
    </section>

    <!--  About me  -->
    <section>
      <b-container>
        <b-card no-body>
          <b-row no-gutters>
            <b-col md="4">
              <b-card-img style="padding-top:25px"
                          :src="require('@/assets/Susan-Treppner-2020-Cross-Training-Foto-Christoph-Worsch-172-scaled.jpg')"
                          alt="Image" class="rounded-0 float-right"/>
            </b-col>
            <b-col md="8">
              <b-card-body>
                <b-card-title class="myFont">{{ $t('about.greet') }},</b-card-title>
                <b-card-text style="line-height: 2rem">
                  <p>{{ $t('about.name') }}</p>
                  <p>{{ $t('about.bio') }}</p>
                  <p>{{ $t('about.crossfit') }}</p>
                  <p>{{ $t('about.lifestyle') }}</p>
                </b-card-text>
              </b-card-body>
            </b-col>
          </b-row>
        </b-card>
      </b-container>
    </section>

    <!--  Kontakt  -->
    <section>
      <Kontakt/>
    </section>
  </div>
</template>

<script>
import Kontakt from "@/components/Kontakt";

export default {
  components: {Kontakt},
  data() {
    return {
      slide: 0,
      sliding: null,
      images: [],
    }
  },
  created() {
    this.images = require.context('@/assets/about/', true, /\.(jpeg|jpg|png|svg)$/)
        .keys().map(x => x.substring(2, x.length))
        .map(x => require('@/assets/about/' + x));
  },
}
</script>

<style>
.img-center {
  margin: 0 auto;
  max-width: 100%;
}
</style>
