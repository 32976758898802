import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import router from './router'
import VideoBg from 'vue-videobg'
import VueCompositionAPI from '@vue/composition-api'
import { MotionPlugin } from '@vueuse/motion'
import i18n from './i18n'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueCompositionAPI)

Vue.use(MotionPlugin);
Vue.component('video-bg', VideoBg)

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
